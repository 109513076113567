import { shootingPhaseConfig } from '@/app/config/shootingPhaseConfig'
import { rifleManager } from '@/app/entities/rifle/RifleManager'
import { ShootingPhaseManager } from '@/app/phases/ShootingPhase/ShootingPhase'
import { startPhaseStateManager } from '@/app/phases/StartPhase/StartPhaseStateManager'
import { shootingDirectionManager } from '@/app/shootingDirectionManager'
import { shootingTargetsManager } from '@/app/shootingTargetsManager'
import {
  AudioNames,
  Tasks
} from '@/app/types'
import store from '@/store'
import {
  audioManager,
  fpsManager,
  modes,
  timeManager,
  TimesTypes,
  trainingManager
} from '@powerplay/core-minigames'
import { trainingTasks } from './TrainingTasks'

/**
 * trieda na spravu treningu strelby
 */
export class ShootingTraining {

  /** Ci sa spusta prvykrat trening - prva sekcia */
  private firstTrainingSection = true

  /** Ci sa spusta druhykrat trening - druha sekcia */
  private secondTrainingSection = false

  /** Premenna na koniec treningu */
  private trainingFinished = false

  /** Pomocny na frame */
  private framesInPhase = 0

  /** Aby sa resetlo iba raz */
  private trainingReset = false

  /** Docasna pomocka potom ide prec */
  private highScoreLogic = true

  /** shooting faza */
  private shootingPhase!: ShootingPhaseManager

  /**  objekt metod v prvej sekcii */
  private firstSectionLogic: Record<number, () => void> = {}

  /**  objekt metod v druhej sekcii */
  private secondSectionLogic: Record<number, () => void> = {}

  /**  objekt metod vo finish sekcii */
  private finishSectionLogic: Record<number, () => void> = {}

  /**
   * @param shootingPhase - referencia na shooting fazu
   */
  public constructor(shootingPhase: ShootingPhaseManager) {

    this.shootingPhase = shootingPhase

    if (!modes.isTrainingMode()) return

    store.commit('UiState/SET_STATE', {
      showTimeKeeper: modes.isTrainingMode(),
      showSplitTimes: false,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })
    startPhaseStateManager.enableInputs()
    store.commit('GamePhaseState/SET_SHOW_UI', true)

    this.defineSectionLogic()

  }

  /**
   * zadefinujeme logiku sekcii
   */
  public defineSectionLogic(): void {

    // prva sekcia
    this.firstSectionLogic = {
      1: () => {

        // nejake nastavovacky na zaciatku
        store.commit('InputsState/SET_DISABLED', true)
        store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', true)
        store.commit('CountdownState/SET_STATE', { headerText: 'shootingProne' })

        console.log(3)
        this.setCountdownState(true, '3', 1)

      },
      [fpsManager.fpsLimit + 1]: () => {

        console.log(2)
        this.setCountdownState(true, '2', 1)

      },
      [fpsManager.fpsLimit * 2 + 1]: () => {

        console.log(1)
        this.setCountdownState(true, '1', 1)

      },
      [fpsManager.fpsLimit * 3 + 1]: () => {

        store.commit('ActionButtonState/SET_FIRED_WEAPON', false)
        console.log('START')

        rifleManager.showRifle()
        audioManager.play(AudioNames.rifleEquip)

        this.setCountdownState(true, 'START', 1, false, true, false, 0.2)
        timeManager.setActive(TimesTypes.game, true)
        store.commit('InputsState/SET_DISABLED', false)
        store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', false)
        this.firstTrainingSection = false
        this.framesInPhase = 0

      }
    }

    // druha sekcia
    this.secondSectionLogic = {
      1: () => {

        // nejake nastavovacky na zaciatku
        store.commit('InputsState/SET_DISABLED', true)
        store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', true)
        store.commit('CountdownState/SET_STATE', { headerText: 'shootingStanding' })
        trainingTasks.saveTaskValue(Tasks.timeFirstShots, trainingTasks.getTimeFirstShots())

      },
      [fpsManager.fpsLimit * 2]: () => {

        this.createBlackOverlay()
        this.trainingReset = true

      },
      [fpsManager.fpsLimit * 3]: () => {

        timeManager.reset()
        this.resetUiTraining()
        this.removeBlackOverlay()

      },
      [fpsManager.fpsLimit * 4]: () => {

        console.log(3)
        this.setCountdownState(true, '3', 1)

      },
      [fpsManager.fpsLimit * 5]: () => {

        console.log(2)
        this.setCountdownState(true, '2', 1)

      },
      [fpsManager.fpsLimit * 6]: () => {

        console.log(1)
        this.setCountdownState(true, '1', 1)

      },
      [fpsManager.fpsLimit * 7]: () => {

        console.log('START')
        rifleManager.showRifle()
        audioManager.play(AudioNames.rifleEquip)
        this.setCountdownState(true, 'START', 1, false, true, false, 0.2)

        timeManager.setActive(TimesTypes.game, true)
        this.secondTrainingSection = false
        store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', false)

        this.framesInPhase = 0
        store.commit('InputsState/SET_DISABLED', false)

      }
    }

    // finish sekcia
    this.finishSectionLogic = {
      1: () => {

        // nejake nastavovacky na zaciatku
        store.commit('InputsState/SET_DISABLED', true)
        store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', true)

      },
      [fpsManager.fpsLimit]: () => {

        trainingTasks.saveTaskValue(
          Tasks.timeSecondShots,
          trainingTasks.getTimeSecondShots()
        )
        // ulozime si posledne 2 ulohy v treningu
        trainingTasks.saveLastTasksValues()
        store.commit('TrainingState/SET_HIGH_SCORE', {
          newHighScore: Math.ceil(trainingManager.getNewPotentialHighScore()),
          showNewHighScore: trainingManager.isNewHighScore()
        })

      },
      [fpsManager.fpsLimit * 3]: () => {

        this.highScoreLogic = false
        this.trainingFinished = false
        this.framesInPhase = 0
        this.shootingPhase.finishPhase()

      }
    }

  }

  /**
   * Logika cisto na trening
   * @returns True, ak je trening aktivny
   */
  public trainingModeLogic(): boolean {

    if (!modes.isTrainingMode()) return false

    if (this.firstTrainingSection) this.firstTrainingSectionLogic()

    if (this.secondTrainingSection) this.secondTrainingSectionLogic()

    if (this.trainingFinished) this.finishedTrainingSectionLogic()

    return (
      this.firstTrainingSection || this.secondTrainingSection || this.trainingFinished
    )

  }

  /**
   * logika zmrazenia po vystrele
   *
   * @param actualAttempt - momentalny vystrel
   */
  public afterShootFreeze(actualAttempt: number): void {

    if (!modes.isTrainingMode()) return

    if (actualAttempt > shootingPhaseConfig.attemptCount && !this.trainingReset) {

      this.secondTrainingSection = true

    }
    if (actualAttempt > shootingPhaseConfig.attemptCountTraining) {

      this.trainingFinished = true

    }

  }

  /**
   * logika strely
   */
  public shootAction(actualAttempt: number): void {

    if (!modes.isTrainingMode()) return

    if (actualAttempt > shootingPhaseConfig.attemptCount && !this.trainingReset) {

      trainingTasks.timeInitialShots = timeManager.getGameTimeWithPenaltyInSeconds(
        undefined,
        undefined,
        1
      )
      timeManager.setActive(TimesTypes.game, false)

    }
    if (actualAttempt > shootingPhaseConfig.attemptCountTraining) {

      trainingTasks.timeSecondaryShots = timeManager.getGameTimeWithPenaltyInSeconds(
        undefined,
        undefined,
        1
      )
      timeManager.setActive(TimesTypes.game, false)

    }

  }

  /**
   * Logika pre prvu trenovaciu fazu
   */
  private firstTrainingSectionLogic(): void {

    this.framesInPhase++

    if (this.firstSectionLogic[this.framesInPhase]) this.firstSectionLogic[this.framesInPhase]()

  }

  /**
   * Logika pre druhu trenovaciu fazu
   */
  public secondTrainingSectionLogic(): void {

    this.framesInPhase++

    if (this.secondSectionLogic[this.framesInPhase]) {

      this.secondSectionLogic[this.framesInPhase]()

    }

  }

  /**
   * Logika na finalnu sekciu
   */
  private finishedTrainingSectionLogic(): void {

    this.framesInPhase++

    if (this.finishSectionLogic[this.framesInPhase]) {

      this.finishSectionLogic[this.framesInPhase]()

    }

  }

  /**
   * nastavime countdownState
   */
  public setCountdownState(
    isEnabled: boolean,
    text = '',
    duration = 1,
    showBig = true,
    showSmall = true,
    showHeader = true,
    leftStart = -0.2
  ): void {

    store.commit('CountdownState/SET_STATE', {
      isEnabled: isEnabled,
      text: text,
      duration: duration,
      showBig: showBig,
      showSmall: showSmall,
      showHeader: showHeader,
      leftStart: leftStart
    })

  }

  /**
   * Specialna metodka na spravu cielov
   */
  private resetUiTraining(): void {

    shootingTargetsManager.resetTargets()
    shootingTargetsManager.setNextTargetsType()
    shootingTargetsManager.resetTargets()
    shootingDirectionManager.reset()
    shootingDirectionManager.createTargetPoint()
    rifleManager.prepareShooting()
    rifleManager.hideRifle()
    this.shootingPhase.wrapper.destroy()
    this.shootingPhase.setCameraWrapper()
    this.shootingPhase.setCameraRendering()
    this.shootingPhase.wrapper.lookAt(shootingDirectionManager.targetPoint.position)

    store.commit('BulletsTargetsBoxState/RESET_FOR_TRAINING')

  }

  /**
   * Create black overlay
   */
  private createBlackOverlay(): void {

    store.commit('BulletsTargetsBoxState/SET_BLACK_SCREEN', true)

  }

  /**
   * Remove black overlay
   */
  private removeBlackOverlay(): void {

    store.commit('BulletsTargetsBoxState/SET_BLACK_SCREEN', false)

  }

  /**
   * ukoncime trening
   */
  public killTraining(): void {

    if (this.trainingFinished) return

    this.firstTrainingSection = false
    this.secondTrainingSection = false
    this.trainingFinished = true
    this.framesInPhase = 0

  }

}
