<template>
  <div
    v-if="showTable"
    class="positioner"
  >
    <power-table
      :row-data="dataTable"
      :header-props="headerProps()"
      :active="active"
      :is-training="isTraining"
      :is-start-list="isStartList"
      :biatlon-type="true"
      :show-discipline-icon="showDisciplineIcon"
      class="transform-origin-top-center"
      @mousedown="oneClick"
      @touchstart="oneClick"
    />
  </div>
</template>

<script lang="ts">
import {
  requestManager,
  modes,
  trainingManager,
  playersManager,
  timeManager
} from '@powerplay/core-minigames'
import { PowerTable } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { pathAssets } from '@/globals/globalvariables'

export default defineComponent({
  name: 'DataTable',
  components: {
    PowerTable
  },
  data() {

    return {
      active: false,
      firstClick: false
      /*
       * headerProps: {
       *  // time: '3:12:05',
       *  discipline: {
       *      name: 'Downhill',
       *      image: `${pathAssets}/ui/table/ICO-DISCIPLINE.png`
       *  },
       *  // competitonType: 'Test hill'
       *  resultType: this.$t('')
       *  // add: {
       *  //     image: `${pathAssets}/ui/table/Logo-Default.png`,
       *  //     alt: 'Add-logo'
       *  // }
       * }
       */
    }

  },
  computed: {
    ...mapGetters({
      showTable: 'TableState/getShowTable',
      isStartList: 'TableState/getIsStartList',
      activeState: 'TableState/getActiveState',
      dataTable: 'TableState/getDataTable'
    }),
    isTraining() {

      return modes.isTrainingMode()

    },
    showDisciplineIcon() {

      return modes.isBossCompetition()

    }
  },
  watch: {
    activeState: {
      handler(value: boolean) {

        setTimeout(() => {


          this.active = value

        }, 10)


      }
    }
  },
  methods: {
    oneClick(event: Event) {

      if (this.activeState) event.stopPropagation()

    },
    headerProps() {

      let time = ''
      if (this.isTraining) {

        time = trainingManager.bestScore.toString()

      } else {

        time = timeManager.getTimeInFormatFromSeconds(playersManager.getPlayer().personalBest, 1)

      }

      return {
        discipline: {
          name: this.$t(`disciplineName${requestManager.disciplineID}`),
          image: `${pathAssets}/ui/table/ICO-DISCIPLINE.png`
        },
        time,
        competitonType: this.$t(this.$store.getters['TableState/getCompetitionText']),
        resultType: this.$t(this.$store.getters['TableState/getResultText']),
        headerTableText: {
          // col2: 'asdf'
        }
      }

    }
  }

})
</script>

<style scoped lang="less">
.positioner {
  position: absolute;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);

  .transform-origin-top-center {
      transform-origin: top center;
  }
}
</style>
