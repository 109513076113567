<template>
  <section
    class="tutorial"
    :class="isMobile() ? 'mobile-overlay' : ''"
  >
    <tutorial-tasks
      :tasks="tasks"
    />
    <tutorial-overlay
      v-if="
        tutorialState.anne.showAnne || tutorialState.tutorialMessage.showMessage
      "
      style="pointer-events: auto"
      @click.prevent=""
      @mousedown.prevent=""
      @mouseup.prevent=""
      @touchstart.prevent=""
      @touchend.prevent=""
    />
    <tutorial-anne
      v-if="tutorialState.anne.showAnne"
      :is-right="tutorialState.anne.isRight"
    />
    <tutorial-under-menu v-if="tutorialState.showUnderMenu" />
    <tutorial-message-box
      v-if="tutorialState.tutorialMessage.showMessage"
      :key="typeWriteState"
      :type-write="typeWriteState"
      :class="{ offsetBot: tutorialState.tutorialMessage.offset }"
      :header-text="$t('anne')"
      :text="$t(tutorialState.tutorialMessage.message)"
      :color="tutorialState.tutorialMessage.color"
      :button-yellow-text="tutorialState.tutorialMessage.yellowText"
      :force-hide-white-text="tutorialState.tutorialMessage.yellowTextSpecial"
      @show-full-text="setTypewriteToInactive"
    />
    <tutorial-hand-settings
      v-if="tutorialState.mobile.show"
      style="pointer-events: auto"
      :img-url="'https://appspowerplaymanager.vshcdn.net/images/winter-sports/minigame/' +
        'biathlon/ui/tutorial/MOBIL_BM.png'"
      @mousedown.stop=""
      @mouseup.stop=""
      @touchstart.stop=""
      @touchend.stop=""
      @click-continue="continueTask"
    >
      <div class="mobiler">
        <div class="buttons">
          <div
            class="button-group"
            :class="{ reverse: !gameSettingsState.isLeft }"
          >
            <img
              width="150"
              height="200"
              :src="`${pathAssets}/ui/tutorial/button_biatlon_action.png`"
              alt="button1"
            >
            <img
              width="150"
              height="150"
              :src="`${pathAssets}/ui/tutorial/button_biatlon_movement.png`"
              alt="button2"
            >
          </div>
        </div>
      </div>
    </tutorial-hand-settings>
    <tutorial-button-start v-if="tutorialState.showButtonStart && isMobile()" />
    <tutorial-buttons />
    <tutorial-bars v-if="!isMobile()" />
  </section>
</template>

<script lang="ts">
import {
  WindowAspect,
  TutorialAnne,
  TutorialMessageBox,
  TutorialTasks,
  TutorialOverlay,
  TutorialHandSettings
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import {
  settings,
  tutorialManager
} from '@powerplay/core-minigames'
import type { TutorialObjective } from '@powerplay/core-minigames'
import TutorialButtonStart from '@/components/Tutorial/TutorialButtonStart.vue'
import TutorialButtons from '@/components/Tutorial/TutorialButtons.vue'
import TutorialBars from '@/components/Tutorial/TutorialBars.vue'
import TutorialUnderMenu from '@/components/Tutorial/TutorialUnderMenu.vue'
import { pathAssets } from '@/globals/globalvariables'

export default defineComponent({
  name: 'TutorialLogicComponent',
  components: {
    TutorialAnne,
    TutorialMessageBox,
    TutorialTasks,
    TutorialOverlay,
    TutorialHandSettings,
    TutorialButtonStart,
    TutorialButtons,
    TutorialUnderMenu,
    TutorialBars
  },
  mixins: [WindowAspect],
  data() {

    return {
      tasks: [],
      pathAssets
    }

  },
  computed: {
    ...mapGetters({
      // TODO: toto podla use case
      gameSettingsState: 'GameSettingsState/getGameSettingsState',
      tutorialState: 'TutorialState/getTutorialState',
      tutorialObjectives: 'TutorialState/getTutorialObjectives',
      typeWriteState: 'TutorialCoreState/getTypeWriter'
    }),
    basicStyle() {

      return {
        'align-items': 'flex-end',
        width: '290px',
        height: '200px',
        padding: '20px'
      }

    }
  },
  watch: {
    tutorialState: {
      immediate: true,
      deep: true,
      handler() {

        this.updateTasks()

      }
    }
  },
  methods: {
    setTypewriteToInactive() {

      this.$store.commit('TutorialCoreState/SET_TYPE_WRITER', false)
      tutorialManager.setTypeWriting(false)

    },
    continueTask() {

      this.setThings()
      settings.saveValues()
      window.dispatchEvent(new Event('mousedown'))

    },
    setThings(): void {

      settings.update('quality', this.gameSettingsState.graphicsSettings)
      settings.update('sounds', this.gameSettingsState.volume ? 1 : 0)
      settings.update(
        'qualityAuto',
        this.gameSettingsState.graphicsAuto ? 1 : 0
      )
      settings.update('isLeft', this.gameSettingsState.isLeft ? 1 : 0)

    },
    updateTasks() {

      this.tasks = this.tutorialObjectives.map((objective: TutorialObjective) => {

        let color = 'blue'
        if (objective.passed) color = 'green'
        if (objective.failed) color = 'red'
        return {
          color,
          text: this.$t(objective.name)
        }

      })

    }
  }
})
</script>

<style lang="less">
.tutorial {

  &.mobile-overlay {
    .tutorial-anne, .tutorial-mobil, .tutorial-hand-bottom {
        position: fixed;
    }
  }

  .offsetBot {
    bottom: 40%;
  }

  .tutorial-tasks {
    position: absolute;
    left: 1%;
    top: 17%;
  }
}
.mobiler {
    height: 100%;

    .buttons {
        position: relative;
        width: 100%;
        height: 100%;

        .button-group {
            position: absolute;
            bottom: 130px;
            left: 130px;
            right: 130px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.reverse {
                flex-direction: row-reverse;
            }
        }
    }
}
</style>
