import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface MovementButtonsState {
  isActive: boolean
}

const initialState = () => ({
  isActive: false
})

const movementButtonsState: Module<MovementButtonsState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getMovementState: (moduleState: MovementButtonsState) => moduleState,
    getMovementButtonsActive: (moduleState: MovementButtonsState) => moduleState.isActive
  },

  mutations: {
    RESET: (moduleState: MovementButtonsState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: MovementButtonsState, newState: MovementButtonsState) => {

      if (moduleState.isActive !== undefined) moduleState.isActive = newState.isActive

    },
    SET_ACTIVE: (moduleState: MovementButtonsState, active: boolean) => {

      moduleState.isActive = active

    }
  }
}

export default movementButtonsState
