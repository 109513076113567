<template>
  <div
    class="small-action-buttons-sub-positions"
    :class="isMobile() ? '' : 'is-not-mobile'"
    :style="transformCoef"
  >
    <div :class="isMobile() ? 'bar-right' : 'bar'">
      <section
        v-if="!isMobile() && showSprint"
        class="tutorial-keyboard-header"
      >
        <shrinking-text-box
          :text="$t('webInfoSprint')"
          :font-size="28"
          :width="150"
          :height="34"
        />
      </section>
      <small-action-button
        v-show="showSprint"
        type="biatlon-speed"
        :pie-percent="sprintBar"
        :style="basicStyle"
        :disabled="isDisabledSprint()"
        :color="sprintColor"
        :letter="isMobile() ? '' : 'q'"
        :is-scaled="false"
        @mousedown.stop="performAction2"
        @mouseup.stop="endAction2"
        @touchstart.stop="performAction2"
        @touchend.stop="endAction2"
      />
    </div>
    <div
      :class="isMobile() ? 'bar-left' : 'bar'"
      :style="barLeftStyle"
    >
      <section
        v-if="!isMobile() && showDownhill"
        class="tutorial-keyboard-header"
      >
        <shrinking-text-box
          :text="$t('webInfoTuck')"
          :font-size="28"
          :width="150"
          :height="34"
        />
      </section>
      <small-action-button
        v-show="showDownhill"
        :disabled="isDisabledTuck()"
        type="biatlon-tuck"
        :pie-percent="isDisabledTuck() ? 0 : 100"
        :style="basicStyle"
        :color="downhillColor"
        :letter="isMobile() ? '' : 'e'"
        :is-scaled="false"
        @mousedown.stop="performAction"
        @mouseup.stop="endAction"
        @touchstart.stop="performAction"
        @touchend.stop="endAction"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  WindowAspect,
  SmallActionButton,
  ShrinkingTextBox
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import {
  inputsManager,
  modes
} from '@powerplay/core-minigames'
import store from '@/store'
import { player } from '@/app/entities/player'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'
import { TutorialObjectiveIds } from '@/app/types'

export default defineComponent({
  name: 'SmallActionButtons',
  components: {
    SmallActionButton,
    ShrinkingTextBox
  },
  mixins: [WindowAspect],
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      sprintBar: 'GamePhaseState/getSprintBar',
      isAllowedToTuck: 'GamePhaseState/getIsAllowedToTuck',
      isAllowedToSprint: 'GamePhaseState/getIsAllowedToSprint',
      sprintColor: 'GamePhaseState/getSprintButtonColor',
      downhillColor: 'GamePhaseState/getDownhillButtonColor',
      tutorialButtons: 'TutorialState/getTutorialButtons',
      disabledSmall: 'GamePhaseState/getDisabledSmallAction'
    }),
    basicStyle() {

      if (!this.isMobile()) return {}

      return {
        'align-items': 'flex-end',
        padding: ' 0 0 10px'
      }

    },
    showDownhill() {

      if (!modes.isTutorial()) return true
      return this.tutorialButtons.showDownhillReal

    },
    showSprint() {

      if (!modes.isTutorial()) return true
      return this.tutorialButtons.showSprintReal

    },
    transformCoef() {

      if (this.isMobile()) return {}
      return { transform: `scale(${this.scaleCoef})`,
        transformOrigin: 'top right' }

    },
    barLeftStyle() {

      if (this.isMobile()) return {}
      return { paddingLeft: '70px' }

    }
  },
  methods: {
    performAction() {

      player.velocityManager.tuckPressed = true
      this.$store.commit('TuckState/SET_STATE', { isTuck: true })

    },
    endAction() {

      this.$store.commit('TuckState/SET_STATE', { isTuck: false })
      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    performAction2() {

      this.$store.commit('SprintState/SET_STATE', { isSprinting: true })
      if (
        modes.isTutorial() &&
        (!tutorialObjectives.getObjectiveById(TutorialObjectiveIds.downhill)?.passed ?? false)
      ) {

        tutorialFlow.handleSprint()
        player.isCrouching = false
        player.isSprinting = false

      }

    },
    endAction2() {

      // v tutoriali sa endAction2 na webe davalo a sposobovalo to problemy
      if (store.getters['SprintState/isFreezedInTutorial']) return

      this.$store.commit('SprintState/SET_STATE', { isSprinting: false })
      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    isDisabledTuck() {

      return !this.isAllowedToTuck || this.disabled || this.disabledSmall

    },
    isDisabledSprint() {

      return !this.isAllowedToSprint || this.disabled || this.disabledSmall

    }

  }
})
</script>
<style lang="less">
.small-action-buttons-sub-positions {
    position: absolute;
    top: -60px;
    right: 40px;
    width: 100%;
    height: 200px;
    display: flex;

    &.is-not-mobile {
        top: 20px;
        left: unset;
        display: flex;
        justify-content: space-around;
    }
        .small-action-button-container {
            width: 100%;
            height: 100%;
            position: relative;
        }
    .bar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        .small-action-button-container {
            position: relative;
            pointer-events: auto;
        }
    }

    .bar-right {
        width: 100%;
        height: 100%;
        padding: 0 20px;

        .small-action-button-wrapper {
            justify-content: flex-end;
            align-items: flex-end;
            width: 100%;
            height: 100%;
        }
    }
    .bar-left {
        width: 100%;
        height: 100%;
        padding: 0 20px;

        .small-action-button-wrapper {
            justify-content: flex-start;
            align-items: flex-end;
            width: 100%;
            height: 100%;
        }
    }
    .tutorial-keyboard-header {
        width: 200px;
        height: 36px;
        padding: 0 20px;
        margin-bottom: 20px;

        border: 2px solid;
        border-image-slice: 1;
        border-width: 2px;
        border-image-source: linear-gradient(
            to right, transparent 0%, #528ebf 25%,  #528ebf 75%, transparent 100%
        );
        border-right: none;
        border-left: none;
        background-image: linear-gradient(to right, transparent, #18334e, #215d84, transparent);

        text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.71);
        font-family: Roboto;
        font-size: 28px;
        font-weight: bold;
        font-style: italic;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
