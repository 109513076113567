<template>
  <div
    :class="isMobile() ? 'positioner' : 'nomobile-positioner'"
    :style="basicStyle"
  >
    <start-bar
      :fill-size="forcedSize > 0 ? forcedSize : Number(timer)"
      :glow="glow"
      :is-scaled="isScaled"
    />
  </div>
</template>

<script lang="ts">
import {
  WindowAspect,
  StartBar
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

interface DataInterface {
  timer: number | string
}

interface StartPhaseStateType {
  soundTimer: number,
  showName: boolean,
}

export default defineComponent({
  name: 'StartEfficiencyBar',
  components: {
    StartBar
  },
  mixins: [WindowAspect],
  props: {
    isLeft: {
      type: Boolean,
      default: false
    },
    forcedSize: {
      type: Number,
      default: -1
    },
    glow: {
      type: Boolean,
      deafault: false
    },
    isScaled: {
      type: Boolean,
      default: true
    }
  },
  data(): DataInterface {

    return {
      timer: 0
    }

  },
  computed: {
    ...mapGetters({
      startPhaseState: 'StartPhaseState/getStartPhaseState'
    }),
    basicStyle() {

      if (!this.isLeft) {

        return {
          'justify-content': 'flex-start'
        }

      }

      return {}

    }
  },
  watch: {
    startPhaseState: {
      immediate: true,
      deep: true,
      handler(value: StartPhaseStateType) {

        const soundTimer = Number(value?.soundTimer.toFixed(2))

        if (soundTimer !== this.timer) this.timer = soundTimer

      }
    }
  }
})
</script>
<style scoped lang="less">
.positioner {
    position: absolute;
    top: 0;
    left: 0;
    width: 85%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    pointer-events: none;
}
.nomobile-positioner {
    pointer-events: none;
    position: absolute;
    left: 5%;
    bottom: 5%;
    .power-start-bar {
        transform-origin: center bottom;
    }

}
</style>
