import store from '@/store'
import {
  CANNON,
  modes,
  THREE
} from '@powerplay/core-minigames'
import {
  gameConfig,
  unitCyclesConfig,
  velocityConfig
} from '../../config'
import {
  TutorialObjectiveIds,
  type VectorXZ
} from '../../types'
import { player } from './Player'
import { PlayerSprintBarManager } from './PlayerSprintBarManager'
import { PlayerInputsUnitCycleManager } from './PlayerInputsUnitCycleManager'
import { inputsManager } from '../../InputsManager'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import { speedBarMaxValueConfig } from '@/app/config/speedBarMaxValueConfig'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'

/**
 * Trieda pre spravu rychlosti hraca
 */
export class PlayerVelocityManager {

  /** Sila ktorou upravujeme rychlost */
  private speedPower = velocityConfig.speedBar.minValue

  /** Trok na frame bez eventu */
  public frameWithoutEvent = 5

  /** Nova rychlost */
  private velocity = new CANNON.Vec3()

  /** Objekt jednotkovej kruznice pre playerove inputy */
  private playerInputsUnitCycle = new PlayerInputsUnitCycleManager()

  /** Posledna hracova rotacia na osi y */
  private lastPlayerRotationY = 0

  /** Pomocny objekt na vypocty */
  private tempObject = new THREE.Object3D()

  /** Pomocny vektor */
  private tempVector = new THREE.Vector3()

  /** Aktualna max rychlost lyziara */
  private maxSpeed = 0

  /** Objekt sprint baru */
  public playerSprintBar = new PlayerSprintBarManager()

  /** Posledny vypocitany gradient */
  public lastGradient = 0

  /** Ci bol stlaceny tuck - kvoli tutorialu */
  public tuckPressed = false

  /** Aktualna hodnota pre pocitanie gradientu kazdych x frameov */
  private actualGradientCounterFrames = 0

  /** Frame counter pre frame counting  */
  private frameCounter = 0

  /** sprintBonus pre hud */
  private sprintBonus = 0

  /** velocity minuly frame */
  private prevVelocity = new CANNON.Vec3()

  /**
   * Vratenie speed bar stavu
   * @returns hodnota speed baru
   */
  public getSpeedPowerState(): number {

    return this.speedPower

  }

  /**
   * Ziskanie rychlosti
   * @returns Rychlost
   */
  public getVelocity(): CANNON.Vec3 {

    return this.velocity

  }

  /**
   * Ziskanie rotacie na osi y z rychlosti
   * @param velocity - Vektor rychlosti
   * @returns Y rotacia
   */
  public getRotationYFromVelocity(velocity: CANNON.Vec3): number {

    this.tempVector.set(
      velocity.x,
      0,
      velocity.z
    )

    this.tempObject.position.set(0, 0, 0)
    this.tempObject.lookAt(this.tempVector)

    /**
     * DOLEZITE - lookAt dava iba rotaciu do Math.PI
     *
     * Ked davame nastavovanie rotacie podla lookAt, tak zaciname pohladom na zaporne Z, kde by
     * bola rotacia 0, potom po smere hodinovych ruciciek a po 45 stupnoch by to islo takto:
     * 0 .. PI/4 .. PI/2 .. PI/4 .. 0 .. -PI/4 .. -PI/2 .. -PI/4 .. 0
     * preto musime upravit danu rotaciu az pri vypocte, lebo keby sme ju upravili na objekte,
     * tak by sa zmenila znova na mensie
     */
    if (velocity.z > 0) {

      const sign = velocity.x >= 0 ? 1 : -1
      this.tempObject.rotation.y = (sign * Math.PI) - this.tempObject.rotation.y

    }

    return this.tempObject.rotation.y

  }

  /**
   * Vypocitanie interpolacie medzi dvomi rotaciami podla percenta
   * @param rotationYNew - Nova rotacia
   * @param rotationYLast - Posledna rotacia
   * @param percent - Percento (1 - pri new, 0 - pri last)
   * @returns Interpolovana rotacia
   */
  private getInterpolationOfNewAndLastRotationY(
    rotationYNew: number,
    rotationYLast: number,
    percent: number
  ): number {

    // ked je nova rotacia mensia, tak je iny vzorec ako opacne
    if (rotationYNew < rotationYLast) {

      return rotationYLast - (Math.abs(rotationYLast - rotationYNew) * percent)

    }

    return rotationYLast + (Math.abs(rotationYNew - rotationYLast) * percent)

  }

  /**
   * Vypocitanie sily, ktora bude pohanat lyziara dopredu
   * @param velocity - Rychlost
   * @returns Vektor na osiach X a Z
   */
  private getForceForward(velocity: CANNON.Vec3): VectorXZ {

    let forwardForce = velocityConfig.forwardForceConst
    if (player.isSprinting) forwardForce = velocityConfig.forwardForceSprint
    if (player.isCrouching) {

      let backwardForceUphill = 0

      const trackGradient = this.getTrackActualGradient(true)
      if (trackGradient > 0) {

        backwardForceUphill = trackGradient * velocityConfig.backwardForceUphillCoef

      }
      forwardForce = velocityConfig.forwardForceCrouch - backwardForceUphill

    }
    if (player.isSkating || player.isStarting) {

      forwardForce = velocityConfig.forwardForceSkatingStart

    }
    if (speedManager.actualSpeed > this.maxSpeed) {

      forwardForce = -velocityConfig.backwardForce

    }

    const velocitySum: number = Math.abs(velocity.x) + Math.abs(velocity.z)

    /*
     * vypocet je jednoduchy, pomerovo si urcime, kolko z koeficientu dame na danu os
     * TODO: RETHINK THIS!!
     */
    return {
      x: forwardForce * (velocity.x / velocitySum),
      z: forwardForce * (velocity.z / velocitySum)
    }

  }

  /**
   * Ziskanie aktualizovanej rotacie na zaklade hracovych inputov
   * @param velocity - Rychlost
   * @param percentInputs - percento hracovych inputov
   * @returns Rotacia
   */
  private getUpdatedRotationYByPlayerInputs(
    velocity: CANNON.Vec3,
    percentInputs: number
  ): number {

    // zistime spravnu rotaciu, ktora bude podla konfigu vela alebo malo ovplyvnovana fyzikou
    const interpolatedRotation: number = this.getInterpolationOfNewAndLastRotationY(
      this.getRotationYFromVelocity(velocity),
      this.lastPlayerRotationY,
      gameConfig.percentPhysicsRotationChange
    )

    // prirastok urcime podla toho, ako vela mame inputy nastavene
    const rotationAdd: number = percentInputs *
            unitCyclesConfig.playerInputs.coefDirectionChange

    return interpolatedRotation + rotationAdd

  }

  /**
   * Vypocitanie zmenenej hodnoty rychlosti po pridani force forward a spomaleni
   * @param velocity - rychlost
   * @returns Rychlost
   */
  private getChangedVelocity(velocity: CANNON.Vec3): CANNON.Vec3 {

    // forward force - pohyb dopredu
    const forceForward: VectorXZ = this.getForceForward(velocity)

    return new CANNON.Vec3(
      (velocity.x + forceForward.x),
      velocity.y,
      (velocity.z + forceForward.z)
    )

  }

  /**
   * Ziskanie prepony rychlosti na osiach X a Z
   * @param velocityX - rychlost na X
   * @param velocityZ - rychlost na Z
   * @returns Prepona
   */
  private getVelocityHypotenuseXZ(velocityX: number, velocityZ: number): number {

    return Math.sqrt((velocityX ** 2) + (velocityZ ** 2))

  }

  /**
   * Redistribuovanie rychlosti na XZ
   * @param hypotenuseVelocityXZ - prepona rychlosti na XZ
   * @param rotationY - rotacia na osi Y
   * @returns Nova rychlost na XZ
   */
  private redistributeVelocityXZ(hypotenuseVelocityXZ: number, rotationY: number): VectorXZ {

    // pri rotaciach vacsich ako PI / 2 musime pocitat inak, lebo pocitame opacny trojuholnik
    if (rotationY > Math.PI / 2) {

      const newRotationChanged = rotationY - (Math.PI / 2)

      return {
        x: hypotenuseVelocityXZ * Math.cos(newRotationChanged),
        z: hypotenuseVelocityXZ * Math.sin(newRotationChanged) * -1
      }

    }

    return {
      x: hypotenuseVelocityXZ * Math.sin(rotationY),
      z: hypotenuseVelocityXZ * Math.cos(rotationY)
    }

  }

  /**
   * Pridanie rychlost
   * @returns void
   */
  private addPower(): void {

    const { stepAdd } = velocityConfig.speedBar

    const maxSpeedValue = player.getSpeedBarMaxValue()

    this.speedPower += stepAdd
    if (this.speedPower >= maxSpeedValue) this.speedPower = maxSpeedValue

    store.commit('GamePhaseState/SET_SPEED', this.speedPower)

  }

  /**
   * Ubranie rychlosti
   * @returns void
   */
  private removePower(): void {

    const { stepRemove } = velocityConfig.speedBar

    const maxSpeedValue = player.getSpeedBarMaxValue()
    const minSpeedValue = maxSpeedValue + speedBarMaxValueConfig.minValueCalcVal

    this.speedPower -= stepRemove
    if (this.speedPower <= minSpeedValue) this.speedPower = minSpeedValue

    store.commit('GamePhaseState/SET_SPEED', this.speedPower)

  }

  /**
   * Nastavenie specialnej sily pre prichod na strelnicu
   */
  public setShootingIntroPower(): void {

    this.speedPower = 60
    store.commit('GamePhaseState/SET_SPEED', this.speedPower)

  }

  /**
   * Nastavenie specialnej sily pre odchod zo strelnice
   */
  public setShootingOutroPower(): void {

    this.speedPower = player.getSpeedBarMaxValue() - 10
    store.commit('GamePhaseState/SET_SPEED', this.speedPower)

  }

  /**
   * Nastavenie pociatocnej hodnoty v spedbare podla pociatocnej kvality
   * @param startQuality - Pociatocna kvalita
   */
  public setStartPower(startQuality: number): void {

    this.speedPower = Math.floor(velocityConfig.speedBar.minValue + (startQuality / 2.5))
    console.log(`SET START POWER SPEED BAR - ${this.speedPower} (quality ${startQuality})`)
    store.commit('GamePhaseState/SET_SPEED', this.speedPower)

  }

  /**
   * Nastavenie novej velocity podla roznych veci
   */
  public getNewVelocity(velocity: CANNON.Vec3): CANNON.Vec3 {

    // zatacanie vlavo / vpravo - nastavime parametre na jednotkovej kruznici pre hracov pohyb
    this.playerInputsUnitCycle.update()
    const playerInputsUnitCyclePercentage = this.playerInputsUnitCycle.calculatePercentage()

    const velocityChanged: CANNON.Vec3 = this.getChangedVelocity(velocity)

    // vypocitame si rychlost na 2 osiach, aby sme vedeli spravit distribuciu podla posunu
    const velocityHypotenuseXZ: number = this.getVelocityHypotenuseXZ(
      velocityChanged.x,
      velocityChanged.z
    )

    // vypocitame novu rotaciu na zaklade hracovych inputov
    const newRotation: number = this.getUpdatedRotationYByPlayerInputs(
      velocity,
      playerInputsUnitCyclePercentage
    )

    // redistribuujeme rychlost na XZ podla noveho uhla
    const newVelocityXZ: VectorXZ = this.redistributeVelocityXZ(
      velocityHypotenuseXZ,
      newRotation
    )

    const trackGradient = this.getTrackActualGradient(true)
    const speed = Math.sqrt(this.velocity.x ** 2 + this.velocity.y ** 2 + this.velocity.z ** 2)
    const prevSpeed = Math.sqrt(this.prevVelocity.x ** 2 + this.prevVelocity.y ** 2 + this.prevVelocity.z ** 2)
    if (
      gameConfig.antiSlowEnabled &&
      !player.isStarting &&
      player.isCrouching &&
      trackGradient < 0 &&
      Math.abs(speed - prevSpeed) > 0.2 &&
      !player.touchingFence
    ) {

      console.log(`Zabranuje sa extremnu vykyvu, zmena rychlosti bola: %c${
        prevSpeed - speed
      }`, 'color: red')
      const prevVelocityXZ: VectorXZ = this.redistributeVelocityXZ(
        Math.sqrt((this.prevVelocity.x ** 2) + (this.prevVelocity.z ** 2)),
        newRotation
      )
      this.velocity.set(prevVelocityXZ.x, this.prevVelocity.y, prevVelocityXZ.z * -1)


    } else {

      this.velocity.set(
        newVelocityXZ.x,
        velocityChanged.y,
        newVelocityXZ.z * -1
      )

    }

    this.prevVelocity.set(this.velocity.x, this.velocity.y, this.velocity.z)
    return this.velocity

  }

  /**
   * Nastavenie poslednej rotacie z aktualnej rychlosti na vypocty v dalsom cykle
   * @param velocityOriginal - rychlost
   * @returns Specialna rotacia
   */
  public setLastRotationFromVelocity(velocityOriginal: CANNON.Vec3): number {

    let velocity = velocityOriginal.clone()

    // na zaciatku musime dat manualne pozeranie sa dopredu, lebo ziadna velocity este nie je
    if (velocity.x === 0 && velocity.y === 0 && velocity.z === 0) {

      velocity = gameConfig.startVelocityDirection

    }

    this.lastPlayerRotationY = this.getRotationYFromVelocity(velocity)

    // UI update
    store.commit('DirectionsState/SET_STATE', {
      player: THREE.MathUtils.radToDeg(this.lastPlayerRotationY)
    })

    return this.lastPlayerRotationY

  }

  /** Metoda zistujuca ci tychlost je nad povoleny limint */
  private isCrouchingAvailable(): boolean {

    return speedManager.actualSpeed >= gameConfig.smallestSpeedToCrouch

  }

  /** Zrusenie crouchu ak nie je povolene z dovodu pomalosti */
  private handleCrouch() {

    if (!this.isCrouchingAvailable()) player.isCrouching = false

  }

  /**
   * Kontrola inputov a spravenie veci, co sa maju pri nich vykonat
   */
  public handleInputs(): void {

    if (inputsManager.moveDirectionForward) this.addPower()
    if (inputsManager.moveDirectionBack) this.removePower()

    /** Crouching stav */
    if (this.manageDownhillState()) return

    /** Springing stav */
    if (this.manageSprintingState()) return

    this.frameWithoutEvent++

  }



  /**
   * Menezovanie stavu - downhill
   * @returns True, ak sa ma predcasne skoncit
   */
  private manageDownhillState(): boolean {

    const tutButtons = store.getters['TutorialState/getTutorialButtons']

    if (
      (store.getters['TuckState/isTuck'] || (inputsManager.actionPressed3)) &&
            (player.isDownhillAllowed() || player.isCrouching || tutButtons.showDownhill)
    ) {

      if (modes.isTutorial() && inputsManager.actionPressed3) {

        tutorialFlow.tutorialOnPressedDownhill()

      }

      const condition = !modes.isTutorial() ||
        (tutorialObjectives.getObjectiveById(TutorialObjectiveIds.downhill)?.passed ?? true)

      if (this.frameWithoutEvent > 5 && condition) {

        if (!player.isDownhillAllowed()) player.allowDownhill()

        player.isSprinting = false
        player.isCrouching = !player.isCrouching
        this.frameWithoutEvent = 0

      }

    }

    return false

  }

  /**
   * Menezovanie stavu - sprint
   * @returns True, ak sa ma predcasne skoncit
   */
  private manageSprintingState(): boolean {

    const tutButtons = store.getters['TutorialState/getTutorialButtons']

    if (
      (inputsManager.actionPressed2 || store.getters['SprintState/isSprint']) &&
            this.playerSprintBar.isSprintAvailable() || tutButtons.showSprint
    ) {

      if (modes.isTutorial() && inputsManager.actionPressed2 && this.frameWithoutEvent > 5) {

        tutorialFlow.tutorialOnPressedSprint()

      }

      const condition = !modes.isTutorial() ||
        (tutorialObjectives.getObjectiveById(TutorialObjectiveIds.downhill)?.passed ?? true)
      if (this.frameWithoutEvent > 5 && condition) {

        player.isCrouching = false
        player.isSprinting = !player.isSprinting
        this.frameWithoutEvent = 0

      }

    }

    return false

  }

  /**
   * Kontrola sprint baru a vykonanie veci na nom
   */
  private handleSprint(): void {

    this.playerSprintBar.update(this.speedPower)

  }

  /**
   * Pohyb lyziara
   */
  private move(): void {

    player.physicsBody.velocity = this.getNewVelocity(player.physicsBody.velocity)

  }

  /**
   * Aktualizovanie maximalnej rychlosti
   */
  private updateMaxSpeed(): void {

    // ak je gradient mensi ako -3%, tak disablujeme sprint bar
    const trackGradient = this.getTrackActualGradient(true)
    const sprintAllowed = trackGradient >= velocityConfig.gradientForDisabledSprint
    store.commit('GamePhaseState/SET_IS_ALLOWED_TO_SPRINT', sprintAllowed)

    if (player.isCrouching) {

      this.maxSpeed = 9999999999
      return

    }

    const {
      gradientCoef, speedConstForMaxSpeed, minMaxSpeedCoef, sprintCoef, sprintGradientCoef,
      minSprintBonus
    } = velocityConfig

    // ked nie je sprint dovoleny a je zapnuty, tak ho na drzovku vypiname
    if (!sprintAllowed && player.isSprinting) {

      store.commit('SprintState/SET_STATE', { isSprinting: false })
      player.isSprinting = false

    }

    const gradient = trackGradient
    const speedBar = player.isSprinting ?
      player.maxSpeedBarManager.getSpeedBarMaxValue() :
      this.getSpeedbarValue()
    const speedConst = speedConstForMaxSpeed

    this.maxSpeed = ((speedBar / 100) * speedConst) - gradient * gradientCoef

    /*
     * console.log(
     *     `max speed => ${this.maxSpeed} = `,
     *     `(${attrStrength} * ${speedBar} * ${speedConst}) - ${gradient}`,
     *     `; actual speed ${speedManager.actualSpeed}`
     * )
     */

    if (this.maxSpeed < minMaxSpeedCoef) this.maxSpeed = minMaxSpeedCoef

    if (player.isSprinting) {

      let sprintBonus = sprintCoef
      if (gradient < 0) sprintBonus += (gradient * sprintGradientCoef)
      if (sprintBonus < minSprintBonus) sprintBonus = minSprintBonus
      this.sprintBonus = sprintBonus
      this.maxSpeed += sprintBonus

    }

  }

  /**
   * Zistenie a vratenie gradientovej casti rise, pomocou ktorej sa pocita sklon v %
   * @param actualPositionY - aktualna pozicia na Y
   * @param lastPositionY - posledna pozicia na Y
   * @returns Hodnota rise
   */
  private getGradientRise(actualPositionY: number, lastPositionY: number): number {

    return actualPositionY - lastPositionY

  }

  /**
   * Ziskanie a vratenie gradientovej casti run, pomocou ktorej sa pocita sklon v %
   * @param actualPosition - aktualna pozicia
   * @param lastPosition - posledna pozicia
   * @param rise - Hodnota rise
   * @returns Hodnota run
   */
  private getGradientRun(
    actualPosition: THREE.Vector3,
    lastPosition: THREE.Vector3,
    rise: number
  ): number {

    const distance = lastPosition.distanceTo(actualPosition)
    return Math.sqrt(distance ** 2 - rise ** 2)

  }

  /**
   * Vratenie aktualneho gradientu kopca (sklon v %)
   * @param onlyCalculatedResult - vrati iba posledny result bez pocitania
   * @returns Aktualny gradient
   */
  public getTrackActualGradient(onlyCalculatedResult = false): number {

    if (onlyCalculatedResult) return this.lastGradient

    // az kazdy x-ty frame budeme menit hodnotu gradientu
    if (this.actualGradientCounterFrames % velocityConfig.changeGradientXFrames === 0) {

      const lastPosition = player.lastPlayerPosition
      const actualPosition = player.getPosition()

      const rise = this.getGradientRise(actualPosition.y, lastPosition.y)
      const run = this.getGradientRun(actualPosition, lastPosition, rise)

      this.lastGradient = 0
      if (run !== 0) this.lastGradient = rise / run * 100

      player.lastPlayerPosition.copy(actualPosition)

    }

    this.actualGradientCounterFrames++

    return this.lastGradient

  }

  /**
   * Vratenie specialnej hodnoty vypocitanej zo speedbaru na mensich hodnotach
   * @returns Hodnota zo speedbaru
   */
  private getSpeedbarValue(): number {

    return this.speedPower <= 90 ? (80 + ((this.speedPower - 50) / 4)) : this.speedPower

  }

  /**
   * Checkuje ohnutie trate a automaticky stiahne speed bar hodnotu
   */
  private checkAutoSpeedBarValue() {

    const {
      gradientToChange, gradientCoefFrames, lowerLimit, minValueCalcVal, speedDeltaDown
    } = speedBarMaxValueConfig

    const maxSpeedValue = player.getSpeedBarMaxValue()
    if (this.speedPower >= maxSpeedValue) this.speedPower = maxSpeedValue

    const actualGradient = this.getTrackActualGradient()
    if (actualGradient > gradientToChange && !player.isSprinting) {

      this.frameCounter++

      // playerSpeedbar nesmie klesnut pod minimalnu hodnotu toto cislo sa meni
      let frameDecider = gradientCoefFrames - (actualGradient - gradientToChange)
      if (frameDecider < lowerLimit) frameDecider = lowerLimit

      if (this.frameCounter % Math.floor(frameDecider) === 0) {

        this.speedPower -= speedDeltaDown

        const minValue = player.getSpeedBarMaxValue() + minValueCalcVal
        if (this.speedPower <= minValue) this.speedPower = minValue

        store.commit('GamePhaseState/SET_SPEED', this.speedPower)

      }

    } else {

      this.frameCounter = 0

    }

  }

  /**
   * Aktualizovanie rychlosti
   */
  public update(): void {

    this.checkAutoSpeedBarValue()
    const handleSpeedAndMove =
            player.isSkating ||
            player.isStarting ||
            player.activeUpdatingMovementAnimations

    if (handleSpeedAndMove) {

      speedManager.update()
      this.updateMaxSpeed()

    }

    // rychlost upravujeme iba vtedy ma aktivne pohybove animacie
    if (player.activeUpdatingMovementAnimations) {

      this.handleInputs()
      this.handleSprint()
      this.handleCrouch()

    }

    if (handleSpeedAndMove) this.move()

    // UI update
    store.commit('MainState/SET_STATE', {
      maxSpeed: this.maxSpeed,
      velocityX: player.physicsBody.velocity.x,
      velocityY: player.physicsBody.velocity.y,
      velocityZ: player.physicsBody.velocity.z,
      gradient: this.lastGradient,
      sprintBonus: this.sprintBonus
    })

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.velocity = new CANNON.Vec3()
    this.playerInputsUnitCycle.reset()
    this.lastPlayerRotationY = 0

  }

}
