import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface CountdownState {
  isEnabled: boolean
  text: string
  duration: number
  headerText: string,
  showBig: boolean,
  showSmall: boolean,
  leftStart: number,
  showHeader: boolean
}

const initialState = () => ({
  isEnabled: false,
  text: '',
  duration: 1,
  headerText: '',
  showBig: true,
  showSmall: true,
  leftStart: -0.2,
  showHeader: true
})

const countdownState: Module<CountdownState, RootState> = {
  state: initialState(),
  namespaced: true,
  getters: {
    getCountdownState: (moduleState: CountdownState) => moduleState
  },
  mutations: {
    RESET: (moduleState: CountdownState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: CountdownState, newState: CountdownState) => {

      if (newState.isEnabled !== undefined) moduleState.isEnabled = newState.isEnabled
      if (newState.duration) moduleState.duration = newState.duration
      if (newState.text) moduleState.text = newState.text
      if (newState.headerText) moduleState.headerText = newState.headerText
      if (newState.showBig !== undefined) moduleState.showBig = newState.showBig
      if (newState.showSmall !== undefined) moduleState.showSmall = newState.showSmall
      if (newState.leftStart) moduleState.leftStart = newState.leftStart
      if (newState.showHeader !== undefined) moduleState.showHeader = newState.showHeader

    },
    SET_HEADER: (moduleState: CountdownState, header: string) => {

      moduleState.headerText = header

    }
  }
}

export default countdownState
