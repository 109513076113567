import {
  gsap,
  game,
  CameraStates,
  corePhasesManager,
  MobileDetector,
  modes,
  cameraManager
} from '@powerplay/core-minigames'
import {
  type DisciplinePhaseManager,
  type DisplayMessage,
  TutorialObjectiveIds
} from '../../types'

import store from '@/store'
import { player } from '../../entities/player'
import { inputsManager } from '../../InputsManager'
import {
  audioGameConfig,
  gameConfig
} from '../../config'
import { startSoundSystem } from './StartSoundSystem'
import { startPhaseStateManager } from './StartPhaseStateManager'
import { trackpoints } from '@/app/entities/trackpoints/Trackpoints'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'

/**
 * Trieda pre startovaciu fazu
 */
export class StartPhaseManager implements DisciplinePhaseManager {

  /** ci sa deje nieco skipnutelne */
  private skippable = true

  /** ci uz je mozne odstartovat */
  private startable = false

  /** ci uz zobrazit ui player-info-avatar */
  private showName = false

  /** ci bolo skipnute */
  private skipped = false

  /** ci faza skoncila */
  public ended = false

  /** Specialne zobrazenie baru v tutoriali */
  private showBarInTutorial = false

  /** hodnota na ktorej stlacil */
  public clickedPower = 0

  /** Pocet frameov od zaciatku fazy */
  private framesInPhase = 0

  /** tween na skrytie odrazovej hlasky */
  public startingMessageTween !: gsap.core.Tween

  /** tween na zacatie disciplinoveho intra */
  private launchSystemTween!: gsap.core.Tween

  /** tween na spustenie pipania */
  private startRunSoundTween!: gsap.core.Tween

  /** timeline na spustenie pipania */
  private startRunSoundTimeline!: gsap.core.Timeline

  /** tween pipania */
  private soundCounterTween!: gsap.core.Timeline

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** ako dlho bude zobrazena hlaska na odraze */
  private STARTING_MESSAGE_DURATION = 3

  /** Premenna pre kameru */
  private cameraInPostIntroState = false

  /** Konstruktor */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase(): void {

    this.storeState()
    startPhaseStateManager.disableInputs()
    startPhaseStateManager.enableStartInputs()

  }

  /**
   * Zacatie fazy
   */
  public startPhase(): void {

    if (this.ended) this.reset()

    console.warn('starting start phase')
    // Zobrazit meno hraca v UI
    this.showName = !modes.isTutorial()
    this.setCameraForDisciplineIntro()
    startSoundSystem.startSound()

  }

  /**
   * Nastavenie kamery pre intro
   */
  private setCameraForDisciplineIntro(): void {

    if (gameConfig.cameraConfig.enabled) {

      player.setGameCameraSettings()

    }
    if (modes.isTutorial()) {

      this.afterCameraDisciplineIntro()
      tutorialFlow.init()
      tutorialUIChange.init()
      return

    }
    cameraManager.setState(CameraStates.disciplineIntro)
    cameraManager.playTween(false, this.afterCameraDisciplineIntro)

  }

  /** pomocna metoda pre animacie */
  public getCameraInPostIntroState(): boolean {

    return this.cameraInPostIntroState

  }

  /**
   * Spravenie veci po konci disciplinoveho intra
   */
  private afterCameraDisciplineIntro = (): void => {

    this.cameraInPostIntroState = true
    cameraManager.setState(CameraStates.discipline)

    startPhaseStateManager.postIntroUiState()

    if (modes.isTutorial()) {

      // this.launchSystem()
      return

    }

    this.launchSystemTween = gsap.to({}, {
      onComplete: this.launchSystem,
      callbackScope: this,
      duration: 0.5
    })

  }

  /**
   * Zobrazenie start baru v tutoriali
   */
  public showBarTutorial = (): void => {

    this.showBarInTutorial = true
    store.commit('TutorialState/SET_SHOW_BAR_START', true)
    this.storeState()

  }

  /**
   * Spustenie pipania
   */
  public launchSystem = (): void => {

    this.skippable = false

    cameraManager.setState(CameraStates.discipline)

    const duration = this.skipped ? 1 : 0

    // Zrusit zobrazenie mena hraca v UI
    this.showName = false

    // davam maly delay kvoli tomu, ze predtym mohol byt skip
    this.startRunSoundTween = gsap.to({}, {
      onComplete: () => {

        this.framesInPhase = 0
        this.showBarInTutorial = false
        store.commit('TutorialState/SET_SHOW_BAR_START', false)
        this.startable = true
        store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', false)
        startPhaseStateManager.enableInputs()
        startSoundSystem.runSoundTween()

      },
      duration
    })

  }

  /**
   * Update kazdy frame
   */
  public update(): void {

    this.framesInPhase++

    if (startSoundSystem.getSoundCount() > 10 && !modes.isTutorial()) {

      this.finishPhase()
      return

    }

    if (MobileDetector.isMobile()) {

      if (store.getters['ActionButtonState/getStart'] && this.startable) {

        console.log('bol stlaceny button start')

        this.clickedPower = startSoundSystem.getSoundTimer()

        tutorialObjectives.passObjective(TutorialObjectiveIds.start as string)
        this.finishPhase()
        this.ended = true

      }

    } else {

      if (inputsManager.actionPressed && this.startable && this.framesInPhase > 10) {

        console.log('action pressed')

        this.clickedPower = startSoundSystem.getSoundTimer()

        tutorialObjectives.passObjective(TutorialObjectiveIds.start as string)
        this.finishPhase()
        this.ended = true

      }

    }

    if (inputsManager.actionPressed && this.skippable && this.framesInPhase > 10) {

      console.log('skippped')
      this.skippable = false
      this.skipped = true
      cameraManager.skipTween()
      startSoundSystem.getFirstSoundTween()?.kill()
      this.framesInPhase = 0

    }

    this.storeState()

  }

  /**
   * kill all tweens
   */
  private killAllTweens(): void {

    if (this.launchSystemTween) this.launchSystemTween.kill()
    if (this.startRunSoundTween) this.startRunSoundTween.kill()
    if (this.soundCounterTween) this.soundCounterTween.kill()
    if (this.startRunSoundTimeline) this.startRunSoundTimeline.kill()

  }

  /**
   * Ukoncene fazy
   */
  public finishPhase(): void {

    trackpoints.setActive(true)

    game.togglePhysics(true)

    if (!this.ended) {

      console.warn('start phase ended')
      this.showTakeoffMessage()
      player.velocityManager.setStartPower(this.clickedPower)
      this.decreaseAudienceNoise()

      this.ended = true
      this.killAllTweens()
      this.callbackEnd()

      this.storeState()

      startPhaseStateManager.finishPhaseUiState()
      startPhaseStateManager.disableInputs()

    }

  }

  /**
   * zadefinuje tween na znizenie hlasitosti divakov
   */
  private decreaseAudienceNoise(): void {

    gsap.to(disciplinePhasesManager.audienceSoundVolume, {
      value: audioGameConfig.minAudienceSound,
      duration: audioGameConfig.minAudienceDuration
    })

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * UI update
   */
  private storeState(): void {

    store.commit('StartPhaseState/SET_STATE', {
      clickedPower: this.clickedPower,
      soundTimer: startSoundSystem.getSoundTimer(),
      showName: this.showName,
      showPhase: !this.ended,
      showBar: (this.startable || this.showBarInTutorial) && !this.ended,
      attempt: corePhasesManager.disciplineActualAttempt
    })

  }

  /**
   * zobrazime startovu spravu
   */
  private showTakeoffMessage(): void {

    const message = this.getStartingMessage()
    startPhaseStateManager.showTakeOffMessageInUI(message)

    this.startingMessageTween = gsap.to({}, {
      duration: this.STARTING_MESSAGE_DURATION,
      onComplete: () => {

        startPhaseStateManager.disableTakeOffMessageInUI(message)

      }
    })

  }

  /**
   * ziskame startovu spravu
   */
  private getStartingMessage(): DisplayMessage {

    const message = { message: 'slowStart',
      color: 2 }

    if (this.clickedPower >= 99) {

      message.message = 'perfectStart'
      message.color = 0

    } else if (this.clickedPower >= 90) {

      message.message = 'excellentStart'
      message.color = 0

    } else if (this.clickedPower >= 70) {

      message.message = 'goodStart'
      message.color = 1

    }

    return message

  }

  /**
   * reset fazy
   */
  private reset(): void {

    store.commit('InputsState/SET_DISABLED', true)
    startSoundSystem.reset()
    this.skippable = true
    this.startable = false
    this.showName = false
    this.skipped = false
    this.ended = false
    this.clickedPower = 0
    this.framesInPhase = 0
    this.storeState()

  }

}
