<template>
  <section
    class="positioner"
    style="height:100%"
  >
    <section v-if="buttonsVisible && gamePhaseState.showUi">
      <section
        v-if="!gameSettingsState.isLeft"
        class="flex justify-between"
      >
        <movement-control
          v-show="showJoystick"
          :style="[
            {transform: `scale(${isShooting ? 1 : scaleCoef})`, width: '100%'},
            shootPosition
          ]"
          :disabled="isDisabled"
          class="bottom-left pointer-events-auto"
        />
        <section v-show="!showJoystick" />
        <section
          :style="{position: 'absolute', right: '0', bottom: '0'}"
        >
          <div
            class="bottom-right"
            :style="{transform: `scale(${scaleCoef})`}"
          >
            <action-button
              v-if="!movementButtonsActive && (isShooting || isStart)"
              class="pointer-events-auto"
            />
            <movement-buttons-component
              v-else
              :disabled="isDisabled"
              class="pointer-events-auto"
            />
            <small-action-buttons
              v-if="
                gamePhaseState.showBar && gamePhaseState.showSmallActionButtons
              "
              :disabled="isDisabled"
              class="buttons-position pointer-events-auto"
            />
            <start-efficiency-bar
              v-if="startPhaseState.showBar"
              :is-scaled="false"
            />
            <speed-bar
              v-if="
                gamePhaseState.showBar && gamePhaseState.showSmallActionButtons
              "
              :is-scaled="false"
            />
          </div>
        </section>
      </section>
      <section
        v-else
        class="flex justify-between"
      >
        <section
          :style="{position: 'absolute', left: '0', bottom: '0'}"
        >
          <div
            class="bottom-left"
            :style="{transform: `scale(${scaleCoef})`}"
          >
            <action-button
              v-if="!movementButtonsActive && (isShooting || isStart)"
              class="pointer-events-auto"
            />
            <movement-buttons-component
              v-else
              :disabled="isDisabled"
            />
            <small-action-buttons
              v-if="
                gamePhaseState.showBar && gamePhaseState.showSmallActionButtons
              "
              :disabled="isDisabled"
              class="buttons-position pointer-events-auto"
            />
            <start-efficiency-bar
              v-if="startPhaseState.showBar"
              :is-left="gameSettingsState.isLeft"
              :is-scaled="false"
            />
            <speed-bar
              v-if="
                gamePhaseState.showBar && gamePhaseState.showSmallActionButtons
              "
              :is-scaled="false"
              :is-left="gameSettingsState.isLeft"
            />
          </div>
        </section>
        <movement-control
          v-if="showJoystick"
          :style="[
            {transform: `scale(${isShooting ? 1 : scaleCoef})`, width: '100%'},
            shootPosition
          ]"
          class="bottom-right pointer-events-auto"
          :disabled="isDisabled"
        />
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import MovementControl from './MovementControl.vue'
import ActionButton from './ActionButton.vue'
import { mapGetters } from 'vuex'
import StartEfficiencyBar from './StartEfficiencyBar.vue'
import SmallActionButtons from './SmallActionButtons.vue'
import SpeedBar from './SpeedBar.vue'
import MovementButtonsComponent from './MovementButtonsComponent.vue'
import { WindowAspect } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MobileInputs',
  components: {
    MovementControl,
    StartEfficiencyBar,
    ActionButton,
    SmallActionButtons,
    SpeedBar,
    MovementButtonsComponent
  },
  mixins: [WindowAspect],
  computed: {
    ...mapGetters({
      isDisabled: 'InputsState/getDisabled',
      buttonsVisible: 'InputsState/getIsVisible',
      showJoystick: 'ActionButtonState/getShowJoystick',
      startPhaseState: 'StartPhaseState/getStartPhaseState',
      gamePhaseState: 'GamePhaseState/getGamePhaseState',
      movementButtonsActive: 'MovementButtonsState/getMovementButtonsActive',
      actionDisabled: 'InputsState/getIsActionDisabled',
      gameSettingsState: 'GameSettingsState/getGameSettingsState',
      isShooting: 'ActionButtonState/getIsShooting',
      isStart: 'ActionButtonState/isStart'
    }),
    shootPosition() {

      if (!this.isShooting) {

        return {
          position: 'absolute',
          height: '100%'
        }

      }
      let left = `calc(50% - (572px * ${this.scaleCoef} / 2))`
      let right = ''
      if (!this.gameSettingsState.isLeft) {

        left = ''
        right = `calc(50% - (572px * ${this.scaleCoef} / 2))`

      }

      return {
        position: 'absolute',
        left,
        right,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: `calc(50% - (572px * ${this.scaleCoef} / 2))`,
        height: '100%'
      }

    }
  }
})
</script>

<style lang="less" scoped>
.positioner {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.pointer-events-auto {
  pointer-events: auto;
}

.buttons-position {
  position: absolute;
  top: -60px;
  left: 0;
  width: 100%;
  height: 200px;
}
.bottom-right {
  transform-origin: bottom right;
  display: flex;
  justify-content: flex-end;
}
.bottom-left {
  transform-origin: bottom left;
  display: flex;
  justify-content: flex-start;
}

</style>
