<template>
  <instructions-component
    class="pointer-events-auto midder"
    :header="$t('instructions')"
    :button-text="$t('continue')"
    :cards="getInstructions"
    show-button
    @button-click="closeInstructions"
  />
</template>

<script lang="ts">
import { Instructions } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import {
  CorePhases,
  corePhasesManager,
  modes,
  tutorialManager
} from '@powerplay/core-minigames'
import { pathAssets } from '@/globals/globalvariables'

export default defineComponent({
  name: 'InstructionComponent',
  components: {
    InstructionsComponent: Instructions
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close-instructions'],
  computed: {
    ...mapGetters({
      showButton: 'InstructionsState/getShowButton'
    }),
    getInstructions() {

      this.$store.commit('BlurState/SET_IS_ACTIVE', true)

      if (modes.isTrainingMode()) {

        return [
          {
            imgSrc: `${pathAssets}/ui/instructions/training-1.png?3`,
            header: this.$t('instructionsDisciplineTraining2Cell1Title'),
            text: [this.$t('instructionsDisciplineTraining2Cell1Text1')]
          },
          {
            imgSrc: `${pathAssets}/ui/instructions/training-2.png?3`,
            header: this.$t('instructionsDisciplineTraining2Cell2Title'),
            text: [this.$t('instructionsDisciplineTraining2Cell2Text1')]
          }
        ]

      }

      const mobilePart = this.isMobile ? 'Mobile' : ''

      return [
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-1.png?3`,
          header: this.$t('instructionsDiscipline2Cell1Title'),
          text: [this.$t('instructionsDiscipline2Cell1Text1')]
        },
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-2.png?3`,
          header: this.$t('instructionsDiscipline2Cell2Title'),
          text: [this.$t('instructionsDiscipline2Cell2Text1')]
        },
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-3.png?3`,
          header: this.$t('instructionsDiscipline2Cell3Title'),
          text: [this.$t(`instructionsDiscipline2Cell3Text1${mobilePart}`)]
        },
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-4.png?3`,
          header: this.$t('instructionsDiscipline2Cell4Title'),
          text: [this.$t('instructionsDiscipline2Cell4Text1')]
        },
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-5.png?3`,
          header: this.$t('instructionsDiscipline2Cell5Title'),
          text: [this.$t('instructionsDiscipline2Cell5Text1')]
        },
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-6.png?3`,
          header: this.$t('instructionsDiscipline2Cell6Title'),
          text: [
            this.$t('instructionsDiscipline2Cell6Text1'),
            this.$t('instructionsDiscipline2Cell6Text2')
          ]
        },
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-7.png?3`,
          header: this.$t('instructionsDiscipline2Cell7Title'),
          text: [
            this.$t(`instructionsDiscipline2Cell7Text1${mobilePart}`),
            this.$t('instructionsDiscipline2Cell7Text2')
          ]
        }
      ]

    }
  },
  methods: {
    closeInstructions(): void {

      // pokial mame prvotne instrukcie, tak ich zavrieme
      if (corePhasesManager.isActivePhaseByType(CorePhases.instructions)) {

        corePhasesManager.getActualPhase()?.finishPhase()

      }
      this.$emit('close-instructions')
      tutorialManager.blockInputsManually()

    }
  }
})
</script>

<style lang="less" scoped>
</style>
