import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import i18n from './i18n'
import '@powerplay/core-minigames-ui/dist/style.css'
import {
  initializeCoreAndError,
  setStartTime
} from './helpers/initializeCoreAndError'
import { setRatio } from './plugins/setRatio'
import * as Sentry from '@sentry/vue'

// eslint-disable-next-line
// @ts-ignore
document?.getElementById('live-layout')?.setAttribute('data-version', __APP_VERSION__)

setStartTime()
export const app = createApp(App)

initializeCoreAndError(app)

if (import.meta.env.VITE_APP_NODE_ENV === 'production') {

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
    ],

    /*
     * Set tracesSampleRate to 1.0 to capture 100%
     * of transactions for performance monitoring.
     * We recommend adjusting this value in production
     */
    tracesSampleRate: 0,

    /*
     * Capture Replay for 10% of all sessions,
     * plus for 100% of sessions with an error
     */
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  })

}

app.use(store).use(i18n).use(setRatio).mount('#game-wrapper')
export * from './GameBiathlon'
