import { player } from '@/app/entities/player'
import { ShootingPhaseManager } from '@/app/phases/ShootingPhase/ShootingPhase'
import { StartPhaseManager } from '@/app/phases/StartPhase/StartPhase'
import store from '@/store'
import {
  tutorialManager,
  TutorialSectionType,
  game,
  modes,
  TutorialMessageColors,
  requestManager,
  gameStats,
  MobileDetector,
  inputsManager
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../../phases/DisciplinePhasesManager'
import {
  DisciplinePhases,
  SectionNames,
  TutorialEventType,
  TutorialObjectiveIds
} from '../../types'
import { tutorialObjectives } from './TutorialObjectives'
import { tutorialUIChange } from './TutorialUIChange'

/**
 *  Tutorial tasky ktore maju aj logiku v sebe na ovladanie tej ktorej udalosti
 */
export class TutorialFlow {

  private activeEventType = TutorialEventType.awaitingEvent

  /** kolko krat sme strielali */
  public shootingAttemptCount = 0

  public endAfterShooting = false

  private firstSprintEnded = false

  /** Ci je blokovana konecna akcia */
  private blockedEndAction = false

  /** Ci bol typewriting */
  private wasTypewriting = false

  /** Ci bol kliknuty sprint */
  private pressedSprint = false

  /** Ci bol kliknuty downhill */
  private pressedDownhill = false

  public setObjectivesInit(): void {

    const objectives = [
      {
        id: TutorialObjectiveIds.start as string,
        passed: false,
        failed: false,
        name: 'tutorialTask2-1'
      },
      {
        id: TutorialObjectiveIds.sprint as string,
        passed: false,
        failed: false,
        name: 'tutorialTask2-2'
      },
      {
        id: TutorialObjectiveIds.downhill as string,
        passed: false,
        failed: false,
        name: 'tutorialTask2-3'
      },
      {
        id: TutorialObjectiveIds.shooting as string,
        passed: false,
        failed: false,
        name: 'tutorialTask2-4'
      }
    ]
    tutorialObjectives.setObjectives(objectives)

  }

  /**
   * Inicializacia
   */
  public init(): void {

    const tutorialSections = [
      {
        name: SectionNames.handCheck,
        id: 0,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          store.commit('BlurState/SET_IS_ACTIVE', false)
          this.setObjectivesInit()

        }
      },
      {
        name: SectionNames.startSection,
        id: 1,
        type: TutorialSectionType.storyInput
      },
      {
        name: SectionNames.startSectionSecond,
        id: 2,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          this.resetTypeWrite()
          const startPhaseManager = disciplinePhasesManager
            .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
          startPhaseManager.showBarTutorial()

        },
        sectionLogicFinish: () => {

          store.commit('TutorialState/SET_SHOW_BUTTON_START', false)
          this.resetTypeWrite()
          const startPhaseManager = disciplinePhasesManager
            .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
          startPhaseManager.launchSystem()

        }
      },
      {
        name: SectionNames.startSectionThird,
        id: 3,
        type: TutorialSectionType.gameButton,
        sectionLogicIntro: () => {

          this.resetTypeWrite()

        }
      },
      {
        name: SectionNames.startSectionFourth,
        id: 4,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          store.commit('TutorialState/SET_BUTTONS', {
            showDownhill: false,
            showDownhillReal: false,
            showSprint: false,
            showSprintReal: false,
            showMovementButtons: true,
            showJoystic: false
          })

        },
        sectionLogicFinish: () => {

          store.commit('TutorialState/SET_BUTTONS', {
            showDownhill: false,
            showDownhillReal: false,
            showSprint: false,
            showSprintReal: false,
            showMovementButtons: false,
            showJoystic: false
          })

        }
      },
      {
        name: SectionNames.startSectionFifth,
        id: 5,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionSixth,
        id: 6,
        type: TutorialSectionType.gameButton,
        sectionLogicIntro: () => {

          game.pauseGame()
          const newBarValue = player.velocityManager.playerSprintBar.MAX_VALUE / 4 * 3
          player.velocityManager.playerSprintBar.setBarValue(newBarValue)
          store.commit('TutorialState/SET_BUTTONS', {
            showDownhill: false,
            showDownhillReal: false,
            showSprint: true,
            showSprintReal: true,
            showMovementButtons: false,
            showJoystic: false
          })

        },
        sectionLogicFinish: () => {

          store.commit('TutorialState/SET_BUTTONS', {
            showDownhill: false,
            showDownhillReal: false,
            showSprint: false,
            showSprintReal: true,
            showMovementButtons: false,
            showJoystic: false
          })

        }
      },
      {
        name: SectionNames.startSectionSeventh,
        id: 7,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionEight,
        id: 8,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionNinth,
        id: 9,
        type: TutorialSectionType.gameButton,
        sectionLogicIntro: () => {

          store.commit('SprintState/SET_STATE', { isSprinting: false })
          game.pauseGame()
          store.commit('TutorialState/SET_BUTTONS', {
            showDownhill: true,
            showDownhillReal: true,
            showSprint: false,
            showSprintReal: true,
            showMovementButtons: false,
            showJoystic: false
          })

        },
        sectionLogicFinish: () => {

          store.commit('TutorialState/SET_BUTTONS', {
            showDownhill: false,
            showDownhillReal: true,
            showSprint: false,
            showSprintReal: true,
            showMovementButtons: false,
            showJoystic: false
          })

        }
      },
      {
        name: SectionNames.startSectionTenth,
        id: 10,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionEleventh,
        id: 11,
        type: TutorialSectionType.storyInput
      },
      {
        name: SectionNames.startSectionTwelfth,
        id: 12,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          store.commit('TutorialState/SET_SHOW_UNDER_MENU', true)

        },
        sectionLogicFinish: () => {

          store.commit('TutorialState/SET_SHOW_UNDER_MENU', false)

        }
      },
      {
        name: SectionNames.startSectionThirteenth,
        id: 13,
        type: TutorialSectionType.gameEvent,
        sectionLogicFinish: () => {

          store.commit('TutorialState/SET_BUTTONS', {
            showDownhill: false,
            showDownhillReal: true,
            showSprint: false,
            showSprintReal: true,
            showMovementButtons: false,
            showJoystick: false
          })

        }
      },
      {
        name: SectionNames.startSectionFourteenth,
        id: 14,
        type: TutorialSectionType.gameEvent
      }
    ]

    tutorialManager.setTutorialSections(tutorialSections)

    const firstTutorialStrings = ['chooseFirstDisciplineReturnFromMinigame', 'chooseFirstDisciplineContinue']
    if (MobileDetector.isMobile() && firstTutorialStrings.includes(requestManager.TUTORIAL_ID ?? '')) return
    tutorialManager.setActualSectionId(1)

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    // this.checkInput()
    tutorialUIChange.update()
    tutorialObjectives.update()

  }

  /**
   * Kontrola inputov
   */
  public checkInput(): void {

    if (TutorialSectionType.gameEvent === tutorialManager.getActualSectionType()) {

      this.eventActionPressed()

    }

  }

  public eventActionTrigger(eventType: TutorialEventType): void {

    if (!modes.isTutorial()) return
    this.activeEventType = eventType

    if (eventType === TutorialEventType.startOfFirstHill) {

      // game.resumeGame()
      tutorialUIChange.setMessage(false, '')
      tutorialUIChange.setAnne(false)
      this.activeEventType = TutorialEventType.awaitingEvent
      tutorialManager.nextSection()

    }
    if (eventType === TutorialEventType.endOfFirstSprint) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText2-6')

      tutorialUIChange.setAnne(true)

    }
    if (eventType === TutorialEventType.startOfFirstDownhill) {

      if (game.paused) game.resumeGame()
      tutorialUIChange.setMessage(false, '')

      tutorialUIChange.setAnne(true)
      this.activeEventType = TutorialEventType.awaitingEvent
      tutorialManager.nextSection()

    }
    if (eventType === TutorialEventType.endOfFirstDownhill) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText2-8')
      tutorialUIChange.setAnne(true)

    }
    if (eventType === TutorialEventType.shootingStart) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText2-1${MobileDetector.isMobile() ? 2 : 1}`)
      tutorialUIChange.setAnne(false)

      store.commit('TutorialState/SET_BUTTONS', {
        showDownhill: false,
        showDownhillReal: true,
        showSprint: false,
        showSprintReal: true,
        showMovementButtons: false,
        showJoystick: true
      })

      this.shootingAttemptCount++

    }
    if (eventType === TutorialEventType.shootingEnd) {

      game.pauseGame()

      this.resetTypeWrite()
      const shootingPhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.shooting) as ShootingPhaseManager
      if (shootingPhase.getMissedShots() > shootingPhase.getShotShots() - 1) {

        this.shootingAttemptCount++
        if (this.shootingAttemptCount > 3) {

          store.commit('TutorialState/SET_SETTINGS', true)

        }
        tutorialUIChange.setMessage(true, 'tutorialText2-13', TutorialMessageColors.red)

      } else {

        tutorialUIChange.setMessage(true, 'tutorialText2-14', TutorialMessageColors.green)

      }
      tutorialUIChange.setAnne(true)

    }

  }

  /** Reset typewrite */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(true)
    store.commit('TutorialCoreState/SET_TYPE_WRITER', true)

  }

  public eventActionPressed(): void {

    if (game.paused) game.resumeGame()
    tutorialUIChange.setMessage(false, '')
    tutorialUIChange.setAnne(false)

    if (
      [
        TutorialEventType.startOfFirstHill,
        TutorialEventType.startOfFirstDownhill,
        TutorialEventType.shootingStart,
        TutorialEventType.endOfFirstSprint,
        TutorialEventType.endOfFirstDownhill
      ].includes(this.activeEventType)
    ) {

      tutorialManager.nextSection()

    }

    if (TutorialEventType.endOfFirstSprint === this.activeEventType) {

      // teleport na downhill
      player.teleportToLastDownhillInTutorial()

    }

    if (this.activeEventType === TutorialEventType.shootingEnd) {

      if (this.endAfterShooting) {

        if (gameStats.wasExitedGame()) {

          requestManager.redirect()
          return

        }

        game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)

        store.commit('LoadingCircleState/SET_STATE', {
          isActive: true
        })

      }
      this.activeEventType = TutorialEventType.awaitingEvent
      return

    }
    this.activeEventType = TutorialEventType.awaitingEvent

  }

  public handleSprint(): void {

    if (!modes.isTutorial() || this.firstSprintEnded) return

    if (game.paused) game.resumeGame()
    this.firstSprintEnded = true
    this.eventActionTrigger(TutorialEventType.endOfFirstSprint)

  }

  /**
   * Tutorialove kliknutie na downhill
   */
  public tutorialOnPressedDownhill(): void {

    if (this.pressedDownhill) {

      store.commit('TuckState/SET_STATE', { isTuck: false })
      return

    }

    if (store.getters['TutorialCoreState/getTypeWriter'] || this.blockedEndAction) {

      this.wasTypewriting = true
      return

    }

    if (game.paused) game.resumeGame()

    store.commit('TuckState/SET_STATE', { isTuck: true })

    player.isSprinting = false
    player.isCrouching = true

    tutorialObjectives.passObjective(TutorialObjectiveIds.downhill as string)
    tutorialManager.nextSection()
    tutorialManager.blockInputsManually()

    this.pressedDownhill = true
    player.velocityManager.frameWithoutEvent = 0

  }

  /**
   * Tutorialove odkliknutie z downhillu
   */
  public tutorialOnUnpressedDownhill(): void {

    if (this.wasTypewriting && !this.blockedEndAction) {

      this.blockedEndAction = true

      store.commit('TutorialCoreState/SET_TYPE_WRITER', false)
      tutorialManager.setTypeWriting(false)

      setTimeout(() => {

        this.blockedEndAction = false
        this.wasTypewriting = false

      }, 150)
      return

    }

    if (this.blockedEndAction) return
    store.commit('TuckState/SET_STATE', { isTuck: false })
    // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
    inputsManager.handleMouseUp()

  }

  /**
   * Tutorialove kliknutie na sprint
   */
  public tutorialOnPressedSprint(): void {

    if (this.pressedSprint) {

      if (!tutorialObjectives.getObjectiveById(TutorialObjectiveIds.downhill)?.passed ?? true) {

        tutorialFlow.handleSprint()
        player.isCrouching = false
        player.isSprinting = false

      }
      return

    }

    if (store.getters['TutorialCoreState/getTypeWriter'] || this.blockedEndAction) {

      this.wasTypewriting = true
      return

    }

    store.commit('SprintState/SET_STATE', {
      isSprinting: true,
      tutorialFreeze: !MobileDetector.isMobile()
    })
    if (game.paused) game.resumeGame()

    player.isSprinting = true
    player.isCrouching = false

    tutorialObjectives.passObjective(TutorialObjectiveIds.sprint as string)
    tutorialManager.nextSection()
    tutorialManager.blockInputsManually()

    this.pressedSprint = true
    player.velocityManager.frameWithoutEvent = 0

  }

  /**
   * Tutorialove odkliknutie zo sprintu
   */
  public tutorialOnUnpressedSprint(): void {

    if (this.wasTypewriting && !this.blockedEndAction) {

      this.blockedEndAction = true

      store.commit('TutorialCoreState/SET_TYPE_WRITER', false)
      tutorialManager.setTypeWriting(false)

      setTimeout(() => {

        this.blockedEndAction = false
        this.wasTypewriting = false

      }, 150)

      return

    }

    if (this.blockedEndAction) return

    store.commit('SprintState/SET_STATE', {
      isSprinting: false,
      tutorialFreeze: false
    })
    // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
    inputsManager.handleMouseUp()

  }

}

export const tutorialFlow = new TutorialFlow()
