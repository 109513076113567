import { SmallActionButtonColors } from '@/app/types'
import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface GamePhaseState {
  showBar: boolean,
  speedPower: number,
  sprintBarState: number,
  showSmallActionButtons: boolean,
  disabledSmallAction: boolean,
  showUi: boolean,
  isAllowedToTuck: boolean,
  isAllowedToSprint: boolean,
  sprintButtonColor: SmallActionButtonColors,
  downhillButtonColor: SmallActionButtonColors,
  maxSpeed: number
}

const initialState = () => ({
  showBar: false,
  speedPower: 1,
  sprintBarState: 0,
  showSmallActionButtons: true,
  disabledSmallAction: false,
  showUi: true,
  isAllowedToTuck: true,
  isAllowedToSprint: true,
  sprintButtonColor: SmallActionButtonColors.yellow,
  downhillButtonColor: SmallActionButtonColors.yellow,
  maxSpeed: 100
})

const gamePhaseState: Module<GamePhaseState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getGamePhaseState: (moduleState: GamePhaseState) => moduleState,
    getSpeed: (moduleState: GamePhaseState) => moduleState.speedPower,
    getSprintBar: (moduleState: GamePhaseState) => moduleState.sprintBarState,
    getSmallActionButtons: (moduleState: GamePhaseState) => moduleState.showSmallActionButtons,
    getIsAllowedToTuck: (moduleState: GamePhaseState) => moduleState.isAllowedToTuck,
    getIsAllowedToSprint: (moduleState: GamePhaseState) => moduleState.isAllowedToSprint,
    getSprintButtonColor: (moduleState: GamePhaseState) => moduleState.sprintButtonColor,
    getDownhillButtonColor: (moduleState: GamePhaseState) => moduleState.downhillButtonColor,
    getMaxSpeed: (moduleState: GamePhaseState) => moduleState.maxSpeed,
    getDisabledSmallAction: (moduleState: GamePhaseState) => moduleState.disabledSmallAction
  },

  mutations: {
    RESET: (moduleState: GamePhaseState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: GamePhaseState, newState: GamePhaseState) => {

      if (moduleState.showBar !== undefined) moduleState.showBar = newState.showBar
      if (moduleState.speedPower !== undefined) moduleState.speedPower = newState.speedPower
      if (moduleState.sprintBarState !== undefined) {

        moduleState.sprintBarState = newState.sprintBarState

      }

    },
    SET_IS_ALLOWED_TO_TUCK: (moduleState: GamePhaseState, isAllowed: boolean) => {

      moduleState.isAllowedToTuck = isAllowed

    },
    SET_IS_ALLOWED_TO_SPRINT: (moduleState: GamePhaseState, isAllowed: boolean) => {

      moduleState.isAllowedToSprint = isAllowed

    },
    SET_MAX_SPEED: (moduleState: GamePhaseState, speed: number) => {

      moduleState.maxSpeed = speed

    },
    SET_SHOW_UI: (moduleState: GamePhaseState, showUi: boolean) => {

      moduleState.showUi = showUi

    },
    SET_SMALL_ACTION: (moduleState: GamePhaseState, showButtons: boolean) => {

      moduleState.showSmallActionButtons = showButtons

    },
    SET_SHOW: (moduleState: GamePhaseState, isShown: boolean) => {

      moduleState.showBar = isShown

    },
    SET_SPEED: (moduleState: GamePhaseState, power: number) => {

      moduleState.speedPower = power

    },
    SET_SPEED_BAR: (moduleState: GamePhaseState, sprintState: number) => {

      moduleState.sprintBarState = sprintState

    },
    SET_SPRINT_BUTTON_COLOR: (moduleState: GamePhaseState, color: SmallActionButtonColors) => {

      moduleState.sprintButtonColor = color

    },
    SET_DOWNHILL_BUTTON_COLOR: (
      moduleState: GamePhaseState,
      color: SmallActionButtonColors
    ) => {

      moduleState.downhillButtonColor = color

    },
    SET_DISABLED_SMALL_BUTTONS: (moduleState: GamePhaseState, disabled: boolean) => {

      moduleState.disabledSmallAction = disabled

    }

  }
}

export default gamePhaseState
