import {
  player,
  playerAnimationManager
} from '../../entities/player'
import {
  AudioNames,
  AudioGroups,
  MovementInFinishPhases,
  type DisciplinePhaseManager,
  DisciplinePhases,
  PlayerAnimationsNames
} from '../../types'
import store from '@/store'
import { finishPhaseConfig } from '../../config'
import {
  timeManager,
  playersManager,
  THREE,
  fpsManager,
  cameraManager,
  game,
  CameraStates,
  modes,
  corePhasesManager,
  gsap,
  audioManager
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import { endManager } from '@/app/EndManager'
import type { ShootingPhaseManager } from '../ShootingPhase/ShootingPhase'
import { trackpoints } from '@/app/entities/trackpoints/Trackpoints'
import { inputsManager } from '@/app/InputsManager'
import { audioHelper } from '@/app/audioHelper/AudioHelper'

/**
 * Trieda fazy pre dojazd v cieli (resp naburanie)
 */
export class FinishPhaseManager implements DisciplinePhaseManager {

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** tween na ukoncenie fazy po animacii */
  private finishPhaseTween !: gsap.core.Tween

  /** ci faza skoncila */
  private ended = false

  /** kolko framov preslo od zaciatku fazy */
  private framesInPhase = 0

  /** kolko framov preslo od zaciatku podfazy movementu */
  private framesInLocalPhase = 0

  /** audio divakov podla emocie */
  private audienceAudio !: AudioNames

  /** Aktualna rychlost pohybu */
  private actualSpeed = 0

  /** Aktualny step v linearnej faze pohybu */
  private linearDecreaseStep = 0

  /** Aktualna faza pohybu v cieli */
  private movementInFinishPhase = MovementInFinishPhases.before

  /** pole objektov faz pohybu v cieli */
  private movementPhasesUpdates: (() => unknown)[] = []

  /** tween na zmenu UI stavu */
  private changeUiStateTween!: gsap.core.Tween

  /** ci je mozne skipnut */
  private SKIPPABLE = true

  /** kolko po starte mame zobrazit finish top box */
  private SHOW_FINISH_TOP_BOX_SECONDS = 2

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // nastavime fazy pohybu
    this.movementPhasesUpdates[MovementInFinishPhases.setThings] = this.moveInFinishSetThings
    this.movementPhasesUpdates[MovementInFinishPhases.nonLinearDecrease] =
            this.moveInFinishNonLinear
    this.movementPhasesUpdates[MovementInFinishPhases.linearDecrease] = this.moveInFinishLinear
    this.movementPhasesUpdates[MovementInFinishPhases.stay] = this.moveInFinishStay

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    if (modes.isTutorial()) return

    fpsManager.pauseCounting()

    this.reset()
    this.preparePhase()

    trackpoints.setActive(false)
    game.togglePhysics(false)

    const shootingPhase = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.shooting) as ShootingPhaseManager

    const playerTime = timeManager.getGameTimeWithPenaltyInSeconds(true, 1, 1)

    playersManager.setPlayerResults(playerTime, [shootingPhase.getMissedShots()])

    console.log(
      'Finalny cas',
      playerTime,
      `, bez randomu ${timeManager.getGameTimeWithPenaltyInSeconds(undefined, undefined, 1)}`
    )

    playersManager.setStandings(1)
    console.log('STANDINGS', playersManager.getStandings())
    store.commit('TableState/SET_DATA', playersManager.getStandings())

    console.warn('finish phase started')
    store.commit('InputsState/SET_VISIBLE', false)
    store.commit('HeartRateState/SET_VISIBLE', false)
    store.commit('GamePhaseState/SET_SMALL_ACTION', false)

    player.finishAction()

    const velocity = player.velocityManager.getVelocity()
    this.actualSpeed = Math.sqrt((velocity.x ** 2) + (velocity.y ** 2) + (velocity.z ** 2)) /
            fpsManager.fpsLimit
    console.log(`Finish speed ${this.actualSpeed}`)

    this.movementInFinishPhase = MovementInFinishPhases.nonLinearDecrease

    cameraManager.setState(CameraStates.disciplineOutro)
    cameraManager.playTween()

    store.commit('UiState/SET_STATE', {
      showTimeKeeper: false,
      showSplitTimes: true,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

    this.changeUiStateTween = gsap.to({}, {
      duration: this.SHOW_FINISH_TOP_BOX_SECONDS,
      onComplete: () => {

        store.commit('UiState/SET_STATE', {
          showTimeKeeper: false,
          showSplitTimes: true,
          showFinishTopBox: (!modes.isTutorial() && !modes.isTrainingMode()),
          showTrainingLayout: modes.isTrainingMode(),
          isTraining: modes.isTrainingMode()
        })
        this.setFinishTopBoxData(playerTime)

      }
    })

    // data pre tabulku
    this.setDataForPositionsTable()
    store.commit(
      'GameplayTableState/SET_TABLES_VISIBILITY',
      {
        showTables: true,
        showLeftTable: true,
        showRightTable: true
      }
    )

    this.playCommentatorAudio()

  }

  /**
   * nastavime data pre top box
   * @param timeSeconds - Cas hraca v sekundach
   */
  private setFinishTopBoxData(timeSeconds: number): void {

    if (modes.isDailyLeague() && !playersManager.isPlayerImproved()) return

    const personalBest = playersManager.getPlayer().personalBest
    const timeFormat = timeManager.getTimeInFormatFromSeconds(timeSeconds, 1)
    const position = playersManager.getPlayerActualPosition()

    const showFirstBox = position < 4
    const showSecondBox = timeSeconds <= personalBest

    store.commit('FinishTopBoxState/SET_STATE', {
      showFirstBox: showFirstBox,
      showSecondBox: showSecondBox,
      firstPlace: position === 1,
      personalBest: timeSeconds === personalBest,
      newPersonalBest: timeSeconds < personalBest,
      time: timeFormat,
      position: position
    })

  }

  /**
   * pustime komentatora
   */
  private playCommentatorAudio(): void {

    const pos = playersManager.getPlayerActualPosition()

    let audio = AudioNames.commentFinish4

    if (pos === 1) {

      audio = AudioNames.commentFinish1

    } else if (pos <= 3) {

      audio = AudioNames.commentFinish2

    } else if (pos <= (modes.isDailyLeague() || modes.isBossCompetition() ? 10 : 5)) {

      audio = AudioNames.commentFinish3

    }

    audioManager.stopAudioByGroup(AudioGroups.commentators)
    audioManager.play(audio)

  }

  /**
   * zapneme audience audio
   */
  private startAudienceAudio(): void {

    audioManager.stopAudioByName(AudioNames.audienceNoise)

    this.audienceAudio = AudioNames.audienceSad

    if (playerAnimationManager.actualAnimation === PlayerAnimationsNames.happy) {

      this.audienceAudio = AudioNames.audienceYay

    }

    audioManager.play(this.audienceAudio)
    audioManager.changeAudioVolume(this.audienceAudio, 1/* this.audienceVolume */)

  }

  /**
   * naplnime tabulku pozicii okolo hraca a cas hraca
   */
  public setDataForPositionsTable(): void {

    const data = playersManager.getSimilarPositionPlayersForTable(1)
    const tableData = []
    let playerResultString = ''

    for (let i = 0; i < data.length; i++) {

      if (data[i] === undefined) continue

      tableData.push({
        position: data[i].position,
        country: data[i].country,
        countryString: data[i].countryString,
        player: {
          name: data[i].name,
          isPlayer: data[i].playable
        },
        time: data[i].result,
        timeDiff: data[i].result,
        isBonus: false
      })

      if (data[i].playable) {

        playerResultString = data[i].result || ''

      }

    }
    store.commit(
      'GameplayTableState/SET_TABLE_DATA',
      tableData
    )

    const playerData = playersManager.getPlayer()

    store.commit('GameplayTableState/SET_PLAYER_DATA', {
      position: playersManager.getPlayerActualPosition(),
      country: playerData.country,
      countryString: playerData.countryString,
      player: {
        name: playerData.name,
        isPlayer: true
      },
      time: timeManager.getTimeInFormatFromSeconds(
        playerData.resultsArr?.[corePhasesManager.disciplineActualAttempt - 1].main || 0,
        1
      ),
      timeDiff: playerResultString,
      isBonus: !playerResultString.includes('+')
    })

  }

  /**
   * Hybanie sa v cieli - faza nastavenie veci
   */
  private moveInFinishSetThings = (): void => {

    const playerPosition = player.getPosition()

    player.playerObject.lookAt(new THREE.Vector3(
      playerPosition.x,
      playerPosition.y,
      playerPosition.z + 2
    ))

    this.movementInFinishPhase = MovementInFinishPhases.nonLinearDecrease

  }

  /**
   * Hybanie sa v cieli - faza nelinearny pohyb
   */
  private moveInFinishNonLinear = (): void => {

    this.framesInLocalPhase++

    const { coef, everyXFrames, frames } = finishPhaseConfig.nonLinearDecreasingSpeed

    // kazdych x frameov menime rychlost
    if (this.framesInLocalPhase % everyXFrames === 1) this.actualSpeed *= coef

    // check konca
    if (this.framesInLocalPhase === frames) {

      this.movementInFinishPhase = MovementInFinishPhases.linearDecrease
      this.framesInLocalPhase = 0
      this.linearDecreaseStep = this.actualSpeed /
                finishPhaseConfig.linearDecreasingSpeed.frames

    }

  }

  /**
   * Hybanie sa v cieli - faza linearny pohyb
   */
  private moveInFinishLinear = (): void => {

    this.framesInLocalPhase++

    this.actualSpeed -= this.linearDecreaseStep
    if (this.actualSpeed < 0) this.actualSpeed = 0

    // check konca
    if (this.framesInLocalPhase === finishPhaseConfig.linearDecreasingSpeed.frames) {

      this.movementInFinishPhase = MovementInFinishPhases.stay
      this.framesInLocalPhase = 0

    }

  }

  /**
   * Hybanie sa v cieli - faza statie
   */
  private moveInFinishStay = (): void => {

    this.framesInLocalPhase++

    if (this.framesInLocalPhase === finishPhaseConfig.stayAtEndFrames) {

      this.setFinishPhaseTween()

    }

  }

  /**
   * Pohyb v cieli
   */
  private moveInFinish(): void {

    this.framesInPhase++

    // po x frameoch nastavujeme animaciu konca
    if (this.framesInPhase === finishPhaseConfig.startAnimationEndAfterFrames) {

      player.isEnd = true
      // finishPhase.setFinishPhaseTween()
      audioHelper.playMovementAudio(AudioNames.skiingBreak)

    }

    // update aktualnej fazy
    this.movementPhasesUpdates[this.movementInFinishPhase]?.()

    const playerPosition = player.getPosition()

    // realny pohyb podla aktualnej rychlosti
    player.physicsBody.position.set(
      playerPosition.x,
      player.physicsBody.position.y,
      playerPosition.z + this.actualSpeed
    )

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.moveInFinish()
    this.skipPhase()

    if (playerAnimationManager.isEndEmotionSet && !this.audienceAudio) {

      this.startAudienceAudio()

    }

  }

  /**
   * skipnutie fazy
   */
  public skipPhase(): void {

    if (!this.SKIPPABLE || !inputsManager.actionPressed) return

    this.finishPhase()

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    if (this.ended) return
    this.ended = true

    if (this.finishPhaseTween) this.finishPhaseTween.kill()
    if (this.changeUiStateTween) this.changeUiStateTween.kill()

    store.commit(
      'GameplayTableState/SET_TABLES_VISIBILITY',
      {
        showTables: false
      }
    )

    store.commit('UiState/SET_STATE', {
      showTimeKeeper: false,
      showSplitTimes: false,
      showFinishTopBox: (!modes.isTutorial() && !modes.isTrainingMode()),
      showTrainingLayout: false,
      isTraining: modes.isTrainingMode()
    })

    fpsManager.pauseCounting()
    endManager.sendLogEnd()
    endManager.sendSaveResult()

    console.warn('finish phase ended')
    this.callbackEnd()

  }

  /**
   * sets tween to finish phase
   */
  public setFinishPhaseTween(): void {

    this.finishPhase()

  }

  /**
   * reset fazy
   */
  public reset(): void {

    this.ended = false
    this.movementInFinishPhase = MovementInFinishPhases.before
    if (this.changeUiStateTween) this.changeUiStateTween.kill()

  }

}
