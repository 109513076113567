import type { Module } from 'vuex'
import type { RootState } from '../index'

import type { SplitTimeData } from '@powerplay/core-minigames'

export interface SplitTimeState {
  splitTimes: string[],
  actualTime: string,
  splitTimeData: SplitTimeData[],
  rerenderKey: boolean,
  showPlayerTime: boolean
}

const initialState = () => ({
  splitTimes: [],
  actualTime: '',
  splitTimeData: [],
  rerenderKey: false,
  showPlayerTime: false
})

const splitTimeState: Module<SplitTimeState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getSplitTimesState: (moduleState: SplitTimeState) => moduleState,
    getSplitTimes: (moduleState: SplitTimeState) => moduleState.splitTimes,
    getActualTimeDebug: (moduleState: SplitTimeState) => moduleState.actualTime,
    getSplitTimeData: (moduleState: SplitTimeState) => moduleState.splitTimeData,
    getRerenderKey: (moduleState: SplitTimeState) => moduleState.rerenderKey,
    getShowPlayerTime: (moduleState: SplitTimeState) => moduleState.showPlayerTime
  },

  mutations: {
    RESET: (moduleState: SplitTimeState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: SplitTimeState, newState: SplitTimeState) => {

      moduleState.splitTimes = newState.splitTimes

    },
    ADD_SPLIT_TIME: (moduleState: SplitTimeState, splitTime: string) => {

      moduleState.splitTimes.push(splitTime)

    },
    ADD_ACTUAL_TIME_DEBUG: (moduleState: SplitTimeState, actualTime: string) => {

      moduleState.actualTime = actualTime

    },
    SET_SPLIT_TIME_DATA: (moduleState: SplitTimeState, splitTimeData: SplitTimeData[]) => {

      moduleState.splitTimeData = splitTimeData

    },
    SET_RERENDER_KEY: (moduleState: SplitTimeState, rerenderKey: boolean) => {

      moduleState.rerenderKey = rerenderKey

    },
    SET_SHOW_PLAYER_TIME: (moduleState: SplitTimeState, show: boolean) => {

      moduleState.showPlayerTime = show

    }
  }
}

export default splitTimeState
