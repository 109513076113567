import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface TimerState {
  penaltySeconds: number,
  timeWithPenalty: string,
  time: string,
}

const initialState = () => ({
  penaltySeconds: 0,
  timeWithPenalty: '0.0',
  time: '0.0'
})

const timeState: Module<TimerState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getTimeState: (moduleState: TimerState) => moduleState,
    getPenaltySeconds: (moduleState: TimerState) => moduleState.penaltySeconds,
    getTimeWithPenalty: (moduleState: TimerState) => moduleState.timeWithPenalty,
    getTime: (moduleState: TimerState) => moduleState.time
  },

  mutations: {
    RESET: (moduleState: TimerState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: TimerState, newState: TimerState) => {

      moduleState.penaltySeconds = newState.penaltySeconds
      moduleState.timeWithPenalty = newState.timeWithPenalty
      moduleState.time = newState.time

    }
  }
}

export default timeState
