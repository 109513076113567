import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface HeartRateState {
  showRate: boolean,
  heartRate: number
}

const initialState = () => ({
  showRate: false,
  heartRate: 90
})

const heartRateState: Module<HeartRateState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getHeartRateState: (moduleState: HeartRateState) => moduleState,
    isShown: (moduleState: HeartRateState) => moduleState.showRate,
    getHeartRate: (moduleState: HeartRateState) => moduleState.heartRate
  },

  mutations: {
    RESET: (moduleState: HeartRateState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: HeartRateState, newState: HeartRateState) => {

      if (moduleState.showRate !== undefined) moduleState.showRate = newState.showRate
      if (moduleState.heartRate !== undefined) moduleState.heartRate = newState.heartRate

    },
    SET_RATE: (moduleState: HeartRateState, rate: number) => {

      moduleState.heartRate = rate

    },
    SET_VISIBLE: (moduleState: HeartRateState, visible: boolean) => {

      moduleState.showRate = visible

    }
  }
}

export default heartRateState
