import { trackpointEntityConfig } from '@/app/config'
import {
  AudioNames,
  type Trackpoint
} from '@/app/types'
import store from '@/store'
import {
  CANNON,
  game,
  THREE,
  gsap,
  audioManager
} from '@powerplay/core-minigames'

/**
 * Trieda na trackpointy
 */
export class Trackpoints {

  /** array s trackpointami */
  public trackpoints: Trackpoint[] = []

  /** aktivne trackpointy */
  public activeTrackpointBack: Trackpoint
  public activeTrackpointMiddle: Trackpoint
  public activeTrackpointFront: Trackpoint

  /** casovac wrong way */
  public wrongWayTimer: gsap.core.Tween | undefined

  /** ci zistujeme */
  public active = false

  /** debug premenne */
  public debugRaycastFront: THREE.Raycaster | undefined
  public debugRaycastMiddle: THREE.Raycaster | undefined
  public debugRaycastBack: THREE.Raycaster | undefined
  public arrowFront = new THREE.ArrowHelper()
  public arrowMiddle = new THREE.ArrowHelper()
  public arrowBack = new THREE.ArrowHelper()

  /**
   * konstruktor
   */
  public constructor() {

    this.activeTrackpointBack = {
      id: 0,
      position: new CANNON.Vec3()
    }
    this.activeTrackpointMiddle = {
      id: 1,
      position: new CANNON.Vec3()
    }
    this.activeTrackpointFront = {
      id: 2,
      position: new CANNON.Vec3()
    }

  }

  /**
   * init
   */
  public init(): void {

    this.loadTrackpoints()
    this.activeTrackpointBack = this.trackpoints[0]
    this.activeTrackpointMiddle = this.trackpoints[1]
    this.activeTrackpointFront = this.trackpoints[2]
    // this.nextDebugRaycasts()

  }

  /**
   * Nastavenie noveho stredneho trackpointu (aj s back a front)
   * @param index - Index trackpointu
   */
  public setNewMiddleTrackpoint(index: number): void {

    this.activeTrackpointMiddle = this.trackpoints[index]
    this.activeTrackpointFront = this.trackpoints[index + 1]
    this.activeTrackpointBack = this.trackpoints[index - 1]

  }

  /**
   * nacitanie trackpointov
   */
  private loadTrackpoints(): void {

    this.trackpoints = trackpointEntityConfig.trackpoints

  }

  /**
   * posunutie aktivnych trackpointov dopredu
   */
  private nextTrackpoint(): void {

    const nextId = this.activeTrackpointFront.id === this.trackpoints.length - 1 ?
      0 :
      this.activeTrackpointFront.id + 1
    this.activeTrackpointBack = this.trackpoints[this.activeTrackpointMiddle.id]
    this.activeTrackpointMiddle = this.trackpoints[this.activeTrackpointFront.id]
    this.activeTrackpointFront = this.trackpoints[nextId]
    // this.nextDebugRaycasts()

  }

  /**
   * posunutie aktivnych trackpointov dozadu
   */
  private previousTrackpoint(): void {

    const previousId = this.activeTrackpointBack.id === 0 ?
      this.trackpoints[this.trackpoints.length - 1].id :
      this.activeTrackpointBack.id - 1

    this.activeTrackpointFront = this.trackpoints[this.activeTrackpointMiddle.id]
    this.activeTrackpointMiddle = this.trackpoints[this.activeTrackpointBack.id]
    this.activeTrackpointBack = this.trackpoints[previousId]
    // this.nextDebugRaycasts()

  }

  /**
   * update
   */
  public update(newPosition: CANNON.Vec3): void {

    this.trackpointLogic(newPosition)
    if (!this.active) {

      store.commit('DirectionsState/SET_WRONG_WAY', false)

    }

  }

  /**
   * hlavna metoda, sleduje, ako hrac ide po mape
   */
  private trackpointLogic(newPosition: CANNON.Vec3): void {

    const distanceFrontNew = this.activeTrackpointFront.position.distanceTo(newPosition)
    const distanceMiddleNew = this.activeTrackpointMiddle.position.distanceTo(newPosition)
    const distanceBackNew = this.activeTrackpointBack.position.distanceTo(newPosition)

    if (
      distanceFrontNew < distanceMiddleNew
    ) {

      this.nextTrackpoint()
      if (this.wrongWayTimer && this.active) {

        this.wrongWayTimer.kill()
        this.wrongWayTimer = undefined

      }
      store.commit('DirectionsState/SET_WRONG_WAY', false)
      audioManager.stopAudioByName(AudioNames.audienceBad)

    } else if (
      distanceBackNew < distanceMiddleNew
    ) {

      if (!this.wrongWayTimer && this.active) {

        /*
         * this.wrongWayTimer = gsap.to({}, {
         *     duration: 10,
         *     onComplete: () => {
         */

        /*
         *         console.warn('koniec')
         *         game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)
         */

        /*
         *     }
         * })
         */
        audioManager.play(AudioNames.audienceBad)

      }
      store.commit('DirectionsState/SET_WRONG_WAY', true)
      this.previousTrackpoint()

    }

  }

  /**
   * debug metoda na vizualizaciu
   */
  private nextDebugRaycasts(): void {

    this.debugRaycastFront = new THREE.Raycaster(
      new THREE.Vector3(
        this.activeTrackpointFront.position.x,
        this.activeTrackpointFront.position.y,
        this.activeTrackpointFront.position.z
      ),
      new THREE.Vector3(0, 1, 0)
    )
    this.debugRaycastBack = new THREE.Raycaster(
      new THREE.Vector3(
        this.activeTrackpointBack.position.x,
        this.activeTrackpointBack.position.y,
        this.activeTrackpointBack.position.z
      ),
      new THREE.Vector3(0, 1, 0)
    )
    this.debugRaycastMiddle = new THREE.Raycaster(
      new THREE.Vector3(
        this.activeTrackpointMiddle.position.x,
        this.activeTrackpointMiddle.position.y,
        this.activeTrackpointMiddle.position.z
      ),
      new THREE.Vector3(0, 1, 0)
    )

    if (this.arrowFront) game.scene.remove(this.arrowFront)
    this.arrowFront = new THREE.ArrowHelper(
      this.debugRaycastFront.ray.direction,
      this.debugRaycastFront.ray.origin,
      100,
      Math.random() * 0xffffff
    )
    game.scene.add(this.arrowFront)

    if (this.arrowBack) game.scene.remove(this.arrowBack)
    this.arrowBack = new THREE.ArrowHelper(
      this.debugRaycastBack.ray.direction,
      this.debugRaycastBack.ray.origin,
      100,
      Math.random() * 0xffffff
    )

    game.scene.add(this.arrowBack)
    if (this.arrowMiddle) game.scene.remove(this.arrowMiddle)
    this.arrowMiddle = new THREE.ArrowHelper(
      this.debugRaycastMiddle.ray.direction,
      this.debugRaycastMiddle.ray.origin,
      100,
      Math.random() * 0xffffff
    )
    game.scene.add(this.arrowMiddle)

  }

  /** active setter */
  public setActive(value: boolean): void {

    this.active = value

  }

}

export const trackpoints = new Trackpoints()
