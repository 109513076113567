<template>
  <mobile-button
    :type="type"
    :is-scaled="false"
    :disabled="getActionDisabled"
    @mousedown="performAction"
    @mouseup="endAction"
    @touchstart="performAction"
    @touchend="endAction"
  />
</template>

<script lang="ts">
import { MobileButton } from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'
import { inputsManager } from '@powerplay/core-minigames'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ActionButton',
  components: {
    MobileButton
  },
  computed: {
    ...mapGetters({
      isStart: 'ActionButtonState/isStart',
      isShooting: 'ActionButtonState/getIsShooting',
      getActionDisabled: 'ActionButtonState/getActionDisabled'
    }),
    type() {

      if (this.isStart) return 'start-biatlon'
      if (this.isShooting) return 'shoot'
      return ''

    }
  },
  methods: {
    performAction() {

      if (this.getActionDisabled) return

      if (this.type === 'tuck') {

        this.$store.commit('TuckState/SET_STATE', { isTuck: true })

      }

      if (this.type === 'start-biatlon') {

        this.$store.commit('ActionButtonState/SET_TOUCH_START', true)

      }

      if (this.type === 'shoot') {

        this.$store.commit('ActionButtonState/SET_FIRED_WEAPON', true)

      }

    },
    endAction() {

      if (this.type === 'tuck') {

        this.$store.commit('TuckState/SET_STATE', { isTuck: false })

      }

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})
</script>

<style>

</style>
