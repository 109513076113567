<template>
  <!-- <countdown-component
    v-show="countdownState.isEnabled"
    :to-animate="countdownState.text"
    :duration="countdownState.duration"
    :header-text="$t(countdownState.headerText)"
    :show-big="countdownState.showBig"
    :show-small="countdownState.showSmall"
    :show-header="countdownState.showHeader"
    :left-start="countdownState.leftStart"
  /> -->
  <div>
    <countdown-shiny
      :active="active1"
      type="text-3"
      :duration="countdownState.duration"
      style="top: -25%;"
    />
    <countdown-shiny
      :active="active2"
      type="text-2"
      :duration="countdownState.duration"
      style="top: -25%;"
    />
    <countdown-shiny
      :active="active3"
      type="text-1"
      :duration="countdownState.duration"
      style="top: -25%;"
    />
    <countdown-shiny
      :active="active4"
      type="text-start"
      :duration="countdownState.duration"
      style="top: -25%;"
      @callback="reset"
    />
  </div>
</template>

<script lang="ts">
import { CountdownShiny } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'CountdownComponentDicipline',
  components: {
    CountdownShiny
  },
  data() {

    return {
      active1: false,
      active2: false,
      active3: false,
      active4: false
    }

  },
  computed: {
    ...mapGetters({
      countdownState: 'CountdownState/getCountdownState'
    })
  },
  watch: {
    'countdownState.text': {

      handler(newValue) {

        if (newValue === '3') {

          this.active1 = true

        } else if (newValue === '2') {

          this.active2 = true

        } else if (newValue === '1') {

          this.active3 = true

        } else if (newValue === 'START') {

          this.active4 = true

        }

      }
    }
  },
  methods: {
    reset() {

      this.active1 = false
      this.active2 = false
      this.active3 = false
      this.active4 = false
      this.$store.commit('CountdownState/SET_STATE', { isEnabled: false })

    }
  }
})
</script>

<style scoped lang="less">

    .countdown-container {
        z-index: 1;
    }

</style>
