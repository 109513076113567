import { speedBarMaxValueConfig } from '@/app/config/speedBarMaxValueConfig'
import store from '@/store'
import { player } from '.'

/**
 * Trieda pre maximalnu hodnotu baru
 */
export class SpeedBarMaxValueManager {

  /** Taky dzidzik na heart rate */
  private maxValue = speedBarMaxValueConfig.defaultMaxSpeedBarValue

  /** FrameChange for max speed */
  private framer = 0

  /**
   * Vratenie max hodnoty baru
   * @returns Max hodnota baru
   */
  public getSpeedBarMaxValue(): number {

    return this.maxValue

  }

  /**
   * Nastavenie max hodnoty
   */
  private setMaxValue(): void {

    const condition = player.getHeartRate() > speedBarMaxValueConfig.limitForCalculations
    condition ? this.decreaseMaxValue() : this.increaseMaxValue()

    store.commit('GamePhaseState/SET_MAX_SPEED', this.maxValue)

  }

  /**
   * Znizenie max hodnoty
   */
  private decreaseMaxValue(): void {

    const {
      limitForDecreasingValue, limitForCalculations, decreaseCoef1, decreaseCoef2
    } = speedBarMaxValueConfig

    if (this.maxValue <= limitForDecreasingValue) return

    this.updateMaxValue(
      decreaseCoef1 - decreaseCoef2 * (player.getHeartRate() - limitForCalculations),
      -1
    )

  }

  /**
   * Zvysenie max hodnoty
   */
  private increaseMaxValue(): void {

    const { limitForIncreasingValue, increaseCoef } = speedBarMaxValueConfig

    if (this.maxValue >= limitForIncreasingValue) return

    this.updateMaxValue(player.getHeartRate() - increaseCoef, 1)

  }

  /**
   * Aktualizovanie max hodnoty
   * @param frameToCheck - Ktory frame treba aktualizovat
   * @param sign - Znamienko pre pripocitanie alebo odpocitanie
   */
  private updateMaxValue(frameToCheck: number, sign: number): void {

    const {
      SPEED_RATE_DELTA, limitForDecreasingValue, limitForIncreasingValue
    } = speedBarMaxValueConfig

    if (this.framer >= frameToCheck) {

      this.maxValue += (SPEED_RATE_DELTA * sign)

      // check min max
      if (this.maxValue <= limitForDecreasingValue) this.maxValue = limitForDecreasingValue
      if (this.maxValue >= limitForIncreasingValue) this.maxValue = limitForIncreasingValue

      this.framer = 0

    }

  }

  /**
   * Aktualizacia veci
   */
  public update(): void {

    this.setMaxValue()
    this.framer++

  }

}
