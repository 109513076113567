import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface StartPhaseState {
  clickedPower: number,
  soundTimer: number,
  showName: boolean,
  showPhase: boolean,
  showBar: boolean,
  attempt: number
}

const initialState = () => ({
  clickedPower: 0,
  soundTimer: 0,
  showName: false,
  showPhase: false,
  showBar: false,
  attempt: 0
})

const startPhaseState: Module<StartPhaseState, RootState> = {
  namespaced: true,
  state: initialState(),

  getters: {
    getStartPhaseState: (moduleState: StartPhaseState) => moduleState,
    getClickedPower: (moduleState: StartPhaseState) => moduleState.clickedPower
  },

  mutations: {
    RESET: (moduleState: StartPhaseState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: StartPhaseState, newState: StartPhaseState) => {

      moduleState.clickedPower = newState.clickedPower
      moduleState.soundTimer = newState.soundTimer
      moduleState.showName = newState.showName
      moduleState.showPhase = newState.showPhase
      moduleState.showBar = newState.showBar

      if (newState.attempt !== undefined) moduleState.attempt = newState.attempt

    },
    SET_SHOW_BAR: (moduleState: StartPhaseState, show: boolean) => {

      moduleState.showBar = show

    }
  }
}

export default startPhaseState
