<template>
  <section
    v-if="show || uiState.showTrainingLayout"
    class="heart-rate-position"
    :class="{'shoot': getShoot}"
  >
    <heart-monitor-box
      :heart-rate="String(getRate)"
      :glow="glow"
    />
  </section>
</template>

<script lang="ts">
import { HeartMonitorBox } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'HearthRateComponent',
  components: {
    HeartMonitorBox
  },
  props: {
    glow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      show: 'HeartRateState/isShown',
      getRate: 'HeartRateState/getHeartRate',
      getShoot: 'ActionButtonState/getIsShooting',
      uiState: 'UiState/getUiState'
    })
  }
})
</script>
