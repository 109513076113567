<template>
  <transition name="fade">
    <section
      v-if="showBlack"
      class="game-in-black"
      :class="isMobile() ? 'mobile-overlay' : ''"
    >
      <img
        :src="`${pathAssets}/ui/black_screen/WINTER-SPORTS-LOGO.png`"
        alt="logo"
      >
    </section>
  </transition>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import { pathAssets } from '@/globals/globalvariables'
import { defineComponent } from 'vue'
import { WindowAspect } from '@powerplay/core-minigames-ui'

export default defineComponent({
  name: 'BlackOverlay',
  mixins: [WindowAspect],
  data() {

    return {
      pathAssets
    }

  },
  computed: {
    ...mapGetters({
      showBlack: 'BulletsTargetsBoxState/getShowBlack'
    })
  }
})
</script>

<style lang="less" scoped>
section.game-in-black {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99999999;
    color: white;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;

    &.mobile-overlay {
        position: fixed;
    }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s
}

.fade-enter-from, .fade-leave-to {
  opacity: 0
}
</style>
