import type { RunningPhase } from '@/app/phases/RunningPhase/RunningPhase'
import {
  THREE,
  AnimationsManager,
  game,
  CANNON,
  gsap,
  cameraManager,
  type CannonNamedBody,
  errorManager,
  CameraStates,
  modes,
  fpsManager,
  gameStats,
  type ContactEvent
} from '@powerplay/core-minigames'
import {
  modelsConfig,
  gameConfig,
  animationsConfig
} from '../../config'
import { disciplinePhasesManager } from '../../phases/DisciplinePhasesManager'
import {
  ModelsNames,
  type PositionTuple,
  DisciplinePhases,
  type IntersectionInfo
} from '../../types'
import { hill } from '../hill/Hill'
import { PlayerVelocityManager } from './PlayerVelocityManager'
import { DownhillManager } from './DownhillManager'
import { HeartRateManager } from './HeartRateManager'
import { trackpoints } from '../trackpoints/Trackpoints'
import { playerAnimationManager } from '.'
import { UphillManager } from './UpHIllManager'
import { SpeedBarMaxValueManager } from './SpeedBarMaxValueManager'

/**
 * Trieda pre hraca
 */
export class Player {

  /** 3D objekt lyziara - cela scena */
  public playerObject = new THREE.Object3D()

  /** Manager pre animacie */
  public animationsManager!: AnimationsManager

  /** Fyzicke body pre objekt */
  public physicsBody!: CannonNamedBody

  /** Fyzicke body pre kolizie s inymi objektami */
  public collisionBody!: CannonNamedBody

  /** Instalerp na instantny lerp */
  private instalerp = false

  /** Raycaster pre natocenie lyziara */
  private raycaster = new THREE.Raycaster()

  /** Pomocny vektor */
  private tempVector = new THREE.Vector3()

  /** 3D objekt, v ktorom sa nachadza kamera a ktoremu sa meni pozicia lerpom postupne */
  private goalObject = new THREE.Object3D()

  /** Pomocny vektor na lerp na otacanie hraca voci svahu */
  private rotateLerp = new THREE.Vector3()

  /** Pomocny vektor na lerp na otacanie hraca voci svahu */
  private rotateLerpTo = new THREE.Vector3()

  /** Pomocny vektor na lerp na normalu svahu */
  private normalLerp = new THREE.Vector3()

  /** Pomocny vektor na lerp na normalu svahu */
  private normalLerpTo = new THREE.Vector3()

  /** Manazer rychlosti */
  public maxSpeedBarManager = new SpeedBarMaxValueManager()

  /** Posledna hodnota velocity na Y */
  private lastVelocityY = 0

  /** Manager pre tep srdca */
  public heartRateManager = new HeartRateManager()

  /** Manager povolenia ist do downhillu */
  private downhillStateManager = new DownhillManager()

  /** Manager povolenia ist do upHillu */
  private uphillStateManager = new UphillManager()

  /** Je pod mostom */
  private underTheBridge = false

  /** ci prebieha endphase */
  private endPhase = false

  /** hrac je skrceny */
  public isCrouching = false

  /** hrac je sprinting */
  public isSprinting = false

  /** hrac sa rozbieha */
  public isSkating = false

  /** hrac je starting */
  public isStarting = false

  /** hrac je na konci */
  public isEnd = false

  /** Ci bol prepad pod trat alebo nie */
  public outOfBounds = false

  /** Bezecka faza */
  private runningPhase!: RunningPhase

  /** Material na spravanie sa players */
  public playerPhysicsMaterial = new CANNON.Material('Player')

  /** Upravuje vysku hraca nad tratou */
  private readonly SKIER_POSITION_Y_ADJUST = -1.2

  /** Ci je aktivne aktualizovanie pohybovych animacii */
  public activeUpdatingMovementAnimations = false

  /** callback pri kolizii */
  private collisionEndCallback!: () => unknown

  /** Manager rychlosti */
  public velocityManager = new PlayerVelocityManager()

  /** Vzdialenost od priesecnika povrchu */
  private intersectionDistance = 0

  /** Normala priesecnika s povrchom */
  private intersectionNormal = new THREE.Vector3()

  /** Bod priesecnika */
  private intersectionPoint = new THREE.Vector3()

  /** Posledna pozicia hraca */
  public lastPlayerPosition = new THREE.Vector3()

  /** casovac vypadnutia z trate */
  private outOfBoundsTimer: gsap.core.Tween | undefined

  /** ci sa dotyka plota */
  public touchingFence = false

  /**
   * Vratenie infa o priesecniku hraca s hillom
   * @returns Info o priesecniku
   */
  public getIntersectionInfo(): IntersectionInfo {

    return {
      normal: this.intersectionNormal,
      distance: this.intersectionDistance,
      point: this.intersectionPoint
    }

  }

  /**
   * Vratenie max hodnoty speed baru
   * @returns - heart rate max
   */
  public getSpeedBarMaxValue(): number {

    return this.maxSpeedBarManager.getSpeedBarMaxValue()

  }

  /**
   * Vratenie rychlostneho gradientu kopca
   * @returns Gradeitn
   */
  public getVelocityGradient(): number {

    return this.velocityManager.lastGradient

  }

  /**
   * Zistenie, ci sa ide dole kopcom
   * @returns True, ak sa ide dole kopcom
   */
  public isDownhillEnabled(): boolean {

    return this.downhillStateManager.getDownhillAllowed()

  }

  /**
   * Zistenie, ci sa ide hore kopcom
   * @returns True, ak sa ide hore kopcom
   */
  public isUphillEnabled(): boolean {

    return this.uphillStateManager.getUphillAllowed()

  }

  /**
   * Zistenie rychlosti
   * @returns Rychlost
   */
  public getVelocity(): CANNON.Vec3 {

    return this.velocityManager.getVelocity()

  }

  /**
   * Vratenie speed bar stavu
   * @returns hodnota speed baru
   */
  public getSpeedPowerState(): number {

    return this.velocityManager.getSpeedPowerState()

  }

  /**
   * Ci mozeme ist do downhillu
   * @returns - ci moze downhill
   */
  public isDownhillAllowed(): boolean {

    return this.downhillStateManager.getDownhillAllowed()

  }

  /** manualne povolenie downhillu */
  public allowDownhill(): void {

    this.downhillStateManager.allowDownhill()

  }

  /**
   * Vytvorenie lyziara
   * @param position - Startovacia pozicia lyziara
   */
  public create(position = gameConfig.startPosition): void {

    console.log('vytvaram hraca...')

    const meshSkierName = modelsConfig[ModelsNames.skier]?.mainMeshNames?.[0]
    if (!meshSkierName) {

      throw new Error(errorManager.showBox('Mesh name for skier was not defined'))

    }

    this.playerObject = game.getObject3D(meshSkierName)

    game.scene.add(this.playerObject)

    // animacie
    this.animationsManager = new AnimationsManager(
      this.playerObject,
      animationsConfig,
      game.animations.get(ModelsNames.skier),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()

    // TODO: WHAT THE FUCK
    this.goalObject.position.set(position.x, position.y, position.z + 2)

    // threeJS Section
    this.playerObject.position.set(
      position.x,
      position.y + this.SKIER_POSITION_Y_ADJUST,
      position.z
    )
    this.playerObject.name = 'Player'

    // CannonJS Section
    this.createAndSetPhysicsBody(position)
    this.setupAttributesGD()

    // tiene
    game.shadowsManager.attachPlaneToObject(this.playerObject)

    this.runningPhase = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase

    console.log('hrac vytvoreny...')

  }

  /**
   * Vytvorenie a nastavenie fyzickych veci
   * @param position - Pozicia lyziara
   */
  private createAndSetPhysicsBody(position: CANNON.Vec3): void {

    this.createPhysicsBody(position)
    this.createCollisionBody()
    this.setupCollision()

  }

  /**
   * Vytvorenie fyziky iba playera
   */
  private createPhysicsBody(position: CANNON.Vec3): void {

    const shape = new CANNON.Box(new CANNON.Vec3(0.7, 0.3, 0.7))
    this.physicsBody = new CANNON.Body({
      mass: gameConfig.playerMass,
      material: this.playerPhysicsMaterial,
      shape
    }) as CannonNamedBody

    const sphereShape = new CANNON.Sphere(0.5)
    const coefPositionSphere = 0.55

    const sphereShapePositions: PositionTuple[] = [[1, 1], [1, -1], [-1, 1], [-1, -1]]

    sphereShapePositions.forEach((element: PositionTuple) => {

      this.physicsBody.addShape(
        sphereShape,
        new CANNON.Vec3(

          coefPositionSphere * element[0],
          -0.8,
          // 0,
          coefPositionSphere * element[1]

        )
      )

    })

    this.physicsBody.name = this.playerObject.name
    // TODO: Jebnut do intro state managera.
    this.physicsBody.type = CANNON.BODY_TYPES.STATIC
    this.physicsBody.position.set(
      position.x,
      position.y,
      position.z
    )
    this.physicsBody.angularFactor = new CANNON.Vec3(0, 0, 0)
    game.physics.addBody(this.physicsBody)
    console.log('TOTO JE FYZIKA', game.physics)

  }

  /**
   * Vytvorenie kolizneho bodycka
   */
  private createCollisionBody(): void {

    const shape = new CANNON.Sphere(1)
    this.collisionBody = new CANNON.Body({
      mass: 0,
      material: this.playerPhysicsMaterial,
      shape,
      isTrigger: true,
      collisionFilterGroup: 2,
      collisionFilterMask: 2
    }) as CannonNamedBody

    this.collisionBody.name = 'collisionPlayerBody'
    this.collisionBody.type = CANNON.BODY_TYPES.DYNAMIC
    // this.collisionBody.isTrigger = true
    this.collisionBody.position.set(
      this.physicsBody.position.x,
      this.physicsBody.position.y - 0.25,
      this.physicsBody.position.z
    )
    game.physics.addBody(this.collisionBody)

  }

  /**
   * Nastavenie spravania pri kolizii hraca s inymi objektami
   */
  private setupCollision = (): void => {


    game.physics.getPhysicsWorld.addEventListener('beginContact', (e: ContactEvent) => {

      if (!e.bodyA?.name || !e.bodyB?.name) return
      if (hill.PHYSICS_MESHES.includes(e.bodyA.name) || hill.PHYSICS_MESHES.includes(e.bodyA.name)) {

        this.touchingFence = true

      }

    })
    game.physics.getPhysicsWorld.addEventListener('endContact', (e: ContactEvent) => {

      if (!e.bodyA?.name || !e.bodyB?.name) return
      if (hill.PHYSICS_MESHES.includes(e.bodyA.name) || hill.PHYSICS_MESHES.includes(e.bodyA.name)) {

        this.touchingFence = false

      }

    })

  }

  /**
   * Aktualizovanie veci podla konfigu od GD
   */
  private setupAttributesGD(): void {

    const hillPhysicsMaterials = game.physics.getPhysicsWorld.bodies
      .filter((body: CannonNamedBody) => body.name?.includes('Physics_Track_'))

    if (hillPhysicsMaterials.length === 0) {

      throw new Error(errorManager.showBox('No hill material'))

    }

    hillPhysicsMaterials.forEach(body => {

      if (!body.material) {

        throw Error('No material on physics body')

      }
      const contactMaterial = new CANNON.ContactMaterial(
        body.material,
        this.playerPhysicsMaterial,
        {
          restitution: gameConfig.restitutionHillPlayer,
          friction: gameConfig.frictionHillPlayer,
          frictionEquationRelaxation: gameConfig.frictionEquationRelaxationHillPlayer,
          frictionEquationStiffness: gameConfig.frictionEquationStiffnessHillPlayer,
          contactEquationRelaxation: gameConfig.contactEquationRelaxationHillPlayer,
          contactEquationStiffness: gameConfig.contactEquationStiffnessHillPlayer
        }
      )
      game.physics.getPhysicsWorld.addContactMaterial(contactMaterial)

    })

    this.physicsBody.linearDamping = gameConfig.linearDamping

  }

  /**
   * Aktualizovanie pozicie lyziara
   */
  private updatePlayerPosition(): void {

    const { position } = this.physicsBody

    this.playerObject.position.set(
      position.x,
      position.y + this.SKIER_POSITION_Y_ADJUST,
      position.z
    )

    this.collisionBody.position.set(
      position.x,
      position.y - 0.25,
      position.z
    )

  }

  /**
   * Nastavenie ci je hrac pod mostom
   * @param isUnderTheBridge - True/false
   */
  public setIsUnderTheBridge(isUnderTheBridge: boolean): void {

    this.underTheBridge = isUnderTheBridge

  }

  /**
   * kontrola ci je hrac pod kopcom, ak nie je, vynulovat timer
   */
  private checkIsUnderHill(): void {

    if (this.intersectionDistance < -gameConfig.depthOutOfBounds) {

      this.isUnderHill()
      return

    }

    if (this.outOfBoundsTimer === undefined) return

    this.outOfBoundsTimer?.kill()
    this.outOfBoundsTimer = undefined

  }

  /**
   * funkcia na nastavenie timera a vypnutia hry po jeho naplneni
   */
  private isUnderHill(): void {

    if (
      this.outOfBoundsTimer ||
            disciplinePhasesManager.actualPhase !== DisciplinePhases.running
    ) return

    this.outOfBoundsTimer = gsap.to({}, {
      onComplete: () => {

        this.outOfBounds = true
        gameStats.setExitedGame(true)
        game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)
        console.warn('Out of bounds, game ended')

      },
      duration: gameConfig.outOfBoundsSeconds
    })

  }

  /**
   * Vypocitanie priesecnika s povrchom
   * @param hillMesh - Mesh kopca
   */
  private calculateIntersectionWithGround(hillMesh: THREE.Mesh): void {

    // reset hodnot
    this.intersectionDistance = 0
    this.intersectionNormal.set(0, 0, 0)
    this.intersectionPoint.set(0, 0, 0)

    // Vzdialenost davame 100m, aby sme urcite pretali zem
    const distance = 100

    this.tempVector.set(
      this.playerObject.position.x,
      this.playerObject.position.y + distance,
      this.playerObject.position.z
    )

    this.raycaster.set(this.tempVector, new THREE.Vector3(0, -1, 0))

    const intersects = this.raycaster.intersectObject(hillMesh)

    const intersectIndex = (this.underTheBridge && intersects?.[1]) ? 1 : 0

    if (
      intersects?.[0]?.distance === undefined &&
            !disciplinePhasesManager.oneOfPhaseIsActual([
              DisciplinePhases.shooting,
              DisciplinePhases.shootingIntro,
              DisciplinePhases.shootingOutro
            ])
    ) {

      this.isUnderHill()

    }

    if (intersects?.[intersectIndex]?.distance) {

      const intersectsDistance = intersects?.[intersectIndex]?.distance
      this.intersectionDistance = intersectsDistance - distance

      this.checkIsUnderHill()

    }

    // Ak existuje prvy priesecnik, tak mame normalu pre natacanie
    if (intersects?.[intersectIndex]?.face?.normal) {

      this.intersectionNormal = intersects?.[intersectIndex]?.face?.normal ??
                new THREE.Vector3()

    }

    // bod prieniku
    if (intersects?.[intersectIndex]?.point) {

      this.intersectionPoint.copy(intersects?.[intersectIndex]?.point)

    }

  }

  /**
   * Prilepenie hraca na zem v pripade potreby. V alteernativnej situacii ho posunieme vyssie.
   */
  public stickPlayerOnGround(): void {

    const offset = cameraManager.isThisCameraState(CameraStates.disciplineIntro) ?
      gameConfig.playerModelOffsetStart :
      gameConfig.playerModelOffset

    this.playerObject.position.y = this.intersectionPoint.y + offset

  }

  /**
   * Zmena gravitacie
   */
  private changeGravity(): void {

    const {
      maxDistanceForGravityCoefinAirNearTrack, gravitation, gravityCoefInAirNearTrack,
      minDistanceForGravityCoefinAirNearTrack
    } = gameConfig

    let offset = 0
    if (
      this.intersectionDistance > minDistanceForGravityCoefinAirNearTrack &&
            this.intersectionDistance < maxDistanceForGravityCoefinAirNearTrack
    ) {

      offset = gravityCoefInAirNearTrack

    }

    game.physics.getPhysicsWorld.gravity.y = gravitation.y + offset

  }

  /**
   * Zmena vektora kamery smerom hore na spravnu poziciu
   */
  private changeCameraVectorUp(): void {

    if (cameraManager.isThisCameraState(CameraStates.discipline)) {

      cameraManager.getMainCamera().up.set(
        this.normalLerp.x,
        this.normalLerp.y,
        this.normalLerp.z
      )

    }

  }

  /**
   * Narotovanie fyzikalneho objekta na playerovu poziciu
   */
  private rotatePhysicsObjectsToPlayer(): void {

    this.physicsBody.quaternion.set(
      this.playerObject.quaternion.x,
      this.playerObject.quaternion.y,
      this.playerObject.quaternion.z,
      this.playerObject.quaternion.w
    )
    this.collisionBody.quaternion.set(
      this.playerObject.quaternion.x,
      this.playerObject.quaternion.y,
      this.playerObject.quaternion.z,
      this.playerObject.quaternion.w
    )

  }

  /**
   * Otocenie hraca podla terenu a smeru lyziara
   */
  private rotatePlayerInVelocityDirection(): void {

    const instalerp = [DisciplinePhases.start, DisciplinePhases.preStart]
      .includes(disciplinePhasesManager.actualPhase) || this.instalerp

    // hned zresetujeme
    this.instalerp = false

    // nastavime si rotaciu hraca, aby sme podla toho mohli davat dobry smer hraca
    this.velocityManager.setLastRotationFromVelocity(this.physicsBody.velocity)

    // lerpujeme normaly kopca
    this.normalLerpTo.set(
      this.intersectionNormal.x,
      this.intersectionNormal.y,
      this.intersectionNormal.z
    )

    let lerpHillCoef = gameConfig.hillNormalLerpCoef
    if (instalerp) lerpHillCoef = 1

    this.normalLerp.lerp(this.normalLerpTo, lerpHillCoef)

    // musime nastavit up vektor, aby sa spravne rotovalo
    this.playerObject.up.set(
      this.normalLerp.x,
      this.normalLerp.y,
      this.normalLerp.z
    )

    this.changeCameraVectorUp()

    const velocity = this.velocityManager.getVelocity()
    const velocityAddZ = (velocity.x === 0 && velocity.y === 0 && velocity.z === 0) ? 1 : 0

    this.lastVelocityY = THREE.MathUtils.lerp(
      this.lastVelocityY,
      velocity.y,
      instalerp ? 1 : gameConfig.velocityYLerpCoef
    )

    // ku kopii velocity pridame poziciu hraca, aby sme mali spravny bod na lookAt
    this.rotateLerpTo.set(
      velocity.x,
      this.lastVelocityY,
      velocity.z + velocityAddZ
    ).add(this.playerObject.position)

    // spravime lerp podla nastaveneho kroku
    this.rotateLerp.lerp(this.rotateLerpTo, instalerp ? 1 : gameConfig.playerRotationLerpCoef)

    // na konci sa pozrieme na objekt pred nami, aby sme boli spravne narotovany podla velocity
    this.playerObject.lookAt(this.rotateLerp)

    /*
     * este upravime aj fyzikalne objekty, tu je otazka, ci to ma nejaky zmysel..?
     * odpoved: Ano ma.
     */
    this.rotatePhysicsObjectsToPlayer()

  }

  /**
   * Nastavenie instantneho lerpu
   */
  public makeInstantLerp(): void {

    this.instalerp = true

  }

  /**
   * Vratenie rotacie lyziara
   * @returns Quaternion lyziara
   */
  public getQuaternion(): THREE.Quaternion {

    return this.playerObject.quaternion

  }

  /**
   * Vratenie pozicie lyziara
   * @returns Pozicia lyziara
   */
  public getPosition(): THREE.Vector3 {

    return this.playerObject.position

  }

  /**
   * Vratenie heart ratei
   * @returns Heart rate
   */
  public getHeartRate(): number {

    return this.heartRateManager.getHeartRate()

  }

  /**
   * Aktualizovanie hraca po vykonani fyziky
   * @param hillMesh - Mesh kopca
   */
  public updateAfterPhysics(hillMesh: THREE.Mesh, isAllowedSkier: boolean): void {

    this.heartRateManager.update()
    if (!isAllowedSkier) return
    this.maxSpeedBarManager.update()
    playerAnimationManager.update()

    this.calculateIntersectionWithGround(hillMesh)

    /** Pause state riesenie */
    if (this.runningPhase.getPaused()) return

    this.velocityManager.update()
    this.updatePlayerPosition()
    if (!this.endPhase) {

      this.stickPlayerOnGround()
      this.changeGravity()

    }

    this.rotatePlayerInVelocityDirection()
    this.downhillStateManager.update()
    this.uphillStateManager.update()

    trackpoints.update(this.physicsBody.position)

  }

  /**
   * Aktualizovanie animacii hraca
   * @param delta - Delta
   */
  public updateAnimations(delta: number): void {

    this.animationsManager.update(delta)

  }

  /**
   * Setter
   * @param phasesManager - phasesManager
   */
  public setCollisionEndCallback(collisionEndCallback: () => unknown): Player {

    this.collisionEndCallback = collisionEndCallback
    return this

  }

  /**
   * Spustenie animacie odrazenia na zaciatku
   */
  public launchStartAnimation = (): void => {

    this.physicsBody.type = CANNON.BODY_TYPES.DYNAMIC

    this.isStarting = true

  }

  /**
   * Konecna akcia pre hraca
   */
  public finishAction(): void {

    // najskor musime ukoncit moznost pohybovych animacii
    this.activeUpdatingMovementAnimations = false
    this.endPhase = true

    // reset kamery
    cameraManager.getMainCamera().up.set(0, 1, 0)

  }

  /**
   * changes config of camera
   * @param idealOffset - ideal shift of camera from player
   * @param idealLookAt - ideal place for camera to look at
   * @param coefSize - how fast should camera move (0-1)
   * @param changeLerp - how fast changes should be applied (0-1)
   */
  public changeCameraSettings(
    idealOffset?: THREE.Vector3,
    idealLookAt?: THREE.Vector3,
    coefSize?: number,
    changeLerp?: number
  ): void {

    cameraManager.changeIdeals(
      idealOffset,
      idealLookAt,
      coefSize,
      changeLerp
    )

  }

  /**
   * changes camera render settings
   * @param near - how close to camera stuff should be rendered
   * @param far - how far from camera stuff should be rendered
   * @param fov - field of view of camera
   */
  public changeCameraRenderSettings(near?: number, far?: number, fov?: number): void {

    cameraManager.changeRenderSettings(near, far, fov)

  }

  /**
   * nastavime camera settings podla game configu
   *
   * @param lerpSize - volitelny iny lerp ako v game configu
   */
  public setGameCameraSettings(lerpSize = gameConfig.cameraConfig.changeLerp): void {

    this.changeCameraSettings(
      gameConfig.cameraConfig.idealOffset,
      gameConfig.cameraConfig.idealLookAt,
      gameConfig.cameraConfig.coefSize,
      lerpSize
    )

  }

  /**
   * Teleportovanie hraca na posledny zjazd v tutoriali
   */
  public teleportToLastDownhillInTutorial(): void {

    /*
     * ide o manualny teleport, tj najskor som si musel najst poziciu cez normalnu hru a potom
     * som si zapisal suradnice a velocity, aby to sedelo. Takisto aj trackpoints
     */

    this.physicsBody.type = CANNON.BODY_TYPES.STATIC
    this.velocityManager.reset()
    this.physicsBody.position.set(58.185, 12.64 - this.SKIER_POSITION_Y_ADJUST, 94.02)
    this.updatePlayerPosition()
    this.calculateIntersectionWithGround(hill.hillMesh)
    this.physicsBody.velocity.set(-8.93, -2.01, -3.05)
    this.velocityManager.setLastRotationFromVelocity(this.physicsBody.velocity)
    this.makeInstantLerp()
    this.rotatePlayerInVelocityDirection()
    this.makeInstantLerp()
    this.physicsBody.type = CANNON.BODY_TYPES.DYNAMIC

    trackpoints.setNewMiddleTrackpoint(60)

  }

  /**
   * reset hraca
   */
  public reset(): void {

    if (modes.isTrainingMode()) return
    this.activeUpdatingMovementAnimations = false
    this.velocityManager.reset()
    this.lastVelocityY = 0
    this.animationsManager.resetAll()
    this.physicsBody.velocity.set(0, 0, 0)
    this.physicsBody.type = CANNON.BODY_TYPES.STATIC
    this.physicsBody.position.set(
      gameConfig.startPosition.x,
      gameConfig.startPosition.y,
      gameConfig.startPosition.z
    )
    playerAnimationManager.reset()

  }

}

export const player = new Player()
