import {
  DisciplinePhases,
  type DisciplinePhaseManager,
  AudioNames,
  AudioGroups
} from '../../types'
import store from '@/store'
import {
  CANNON,
  game,
  THREE,
  audioManager
} from '@powerplay/core-minigames'
import { player } from '@/app/entities/player'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import type { RunningPhase } from '../RunningPhase/RunningPhase'
import { trackpoints } from '@/app/entities/trackpoints/Trackpoints'
import { triggers } from '@/app/entities/trigger/Trigger'
import type { ShootingPhaseManager } from '../ShootingPhase/ShootingPhase'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import { shootingOutroConfig } from '@/app/config'

/**
 * Trieda pre startovaciu fazu
 */
export class ShootingOutroPhase implements DisciplinePhaseManager {

  /** začiatočná pozícia */
  private startPosition!: THREE.Vector3

  // /** Defaultná stredná pozícia, bude prepísaná, ale nech máme fajn defaulty */
  // private middlePositions = {
  //     [ShootingTargetsTypes.prone]: new THREE.Vector3(-22.3, 2, 8.79),
  //     [ShootingTargetsTypes.standing]: new THREE.Vector3(-22.3, 2, 11.2)
  // }

  /** Defaultná konečná pozícia, bude prepísaná, ale nech máme fajn defaulty */
  private endPosition = new THREE.Vector3(-22, 2, 6)

  /** Zaciatok animacia */
  private startTime = 0

  /** Nasa pomocna kurva */
  private curve!: THREE.CurvePath<THREE.Vector3>

  /** ci faza skoncila */
  private ended = false

  /** Pocet frameov od zaciatku fazy */
  private framesInPhase = 0

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Vrati cas co bezi uz ovladanie intra
   * @returns - cas co to bezi frameovo
   */
  public getStartTime(): number {

    return this.startTime

  }

  /**
   * Vrati cas co ma bezat pohyb
   * @returns - vrati cas pohybu
   */
  public getMoveDuration(): number {

    return shootingOutroConfig.moveDuration

  }

  /**
   * Ukoncenie fazy
   */
  public finishPhase(): void {

    console.warn('Finishing shooting outro phase')
    if (!this.ended) {

      // nastavime kameru tak, ako je v discipline
      player.setGameCameraSettings()

      game.togglePhysics(true)
      this.ended = true
      player.physicsBody.velocity.set(-0.612297, 0, -6.36213)
      const runningPhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase
      runningPhase.unPausePhase()
      store.commit('InputsState/SET_DISABLED', false)
      store.commit('GamePhaseState/SET_DISABLED_SMALL_BUTTONS', false)

      trackpoints.setActive(true)
      this.callbackEnd()

    }

  }

  /**
   * Nastavenie tweenu pre finish phase
   */
  public setFinishPhaseTween(): void {

    // nic

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase(): void {

    player.physicsBody.type = CANNON.BODY_TYPES.KINEMATIC

    this.startPosition = new THREE.Vector3(
      player.physicsBody.position.x,
      player.physicsBody.position.y,
      player.physicsBody.position.z
    )

    player.velocityManager.setShootingOutroPower()

  }

  /**
   * Zacatie fazy
   */
  public startPhase(): void {

    console.warn('starting Shooting outro phase')
    store.commit('MovementState/SET_POSITION_X', 0)
    if (this.ended) this.reset()
    this.preparePhase()
    this.prepareMovement()
    triggers.splitTimeManager.setActualSplitTimeData()

    audioHelper.playMovementAudio(AudioNames.runFastpace)

    this.setStartAudio()

  }

  /**
   * zahrame audio na zaciatku
   */
  private setStartAudio(): void {

    const shootingPhase =
            disciplinePhasesManager.getDisciplinePhaseManager(DisciplinePhases.shooting) as
            ShootingPhaseManager

    const missed = shootingPhase.getMissedShots()

    let audioName = AudioNames.commentShootingEnd2Misses

    if (missed === 0) {

      audioName = AudioNames.commentShootingEndClean

    } else if (missed === 1) {

      audioName = AudioNames.commentShootingEnd1Miss

    }

    audioManager.stopAudioByGroup(AudioGroups.commentators)
    audioManager.play(audioName)

  }

  /**
   * Priprava pohybu
   */
  private prepareMovement() {

    const { bezierMiddle, bezierEnd, lineEnd } = shootingOutroConfig

    this.startPosition.y = 2
    const bezierMiddleVector = bezierMiddle.clone().add(this.startPosition.clone())
    const bezierEndVector = bezierEnd.clone().add(bezierMiddleVector.clone())
    this.endPosition = lineEnd.clone().add(bezierEndVector)

    const curve = new THREE.QuadraticBezierCurve3(
      this.startPosition,
      bezierMiddleVector,
      bezierEndVector
    )

    const line = new THREE.LineCurve3(bezierEndVector, this.endPosition)

    this.curve = new THREE.CurvePath()
    this.curve.add(curve)
    this.curve.add(line)

    player.playerObject.lookAt(this.endPosition)

    // Save the start time
    this.startTime = 0

    if (!shootingOutroConfig.debugShowCurve) return

    const points = this.curve.getPoints(50)
    const geometry = new THREE.BufferGeometry().setFromPoints(points)
    const material = new THREE.LineBasicMaterial({ color: 0xff0000 })

    // Create the final object to add to the scene
    const curveObject = new THREE.Line(geometry, material)

    game.scene.add(curveObject)

  }

  /**
   * Pohyb hraca
   */
  private movePlayer() {

    this.startTime++

    // Progress is a number where 0 is at start position and 1 is at end position
    const progress = this.startTime / shootingOutroConfig.moveDuration

    if (progress < 1) {

      const getPosition = this.curve.getPointAt(progress)
      const getPositionFurther = this.curve.getPointAt(progress + 0.00000001)

      player.playerObject.position.x = getPosition.x
      player.playerObject.position.z = getPosition.z
      player.playerObject.position.y = getPosition.y
      player.playerObject.lookAt(getPositionFurther)
      player.stickPlayerOnGround()

    } else {

      player.physicsBody.position.set(
        this.endPosition.x,
        this.endPosition.y,
        this.endPosition.z
      )

      this.finishPhase()

    }

  }

  /**
   * Update kazdy frame
   */
  public update(): void {

    this.framesInPhase++
    this.movePlayer()

  }

  /**
   * reset fazy
   */
  private reset(): void {

    this.ended = false
    this.framesInPhase = 0

  }

}
