<template>
  <time-keeper
    v-if="uiState.showTimeKeeper"
    class="time-keeper"
    :text1="timeState.timeWithPenalty"
    :text2="`+ ${timeState.penaltySeconds}s`"
    :show-text2="isPenalty"
    :is-scaled="false"
  />
</template>

<script lang="ts">
import { modes } from '@powerplay/core-minigames'
import { TimeKeeper } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'TimeKeeperComponent',
  components: {
    TimeKeeper
  },
  computed: {
    ...mapGetters({
      timeState: 'TimeState/getTimeState',
      uiState: 'UiState/getUiState'
    }),
    isPenalty(): boolean {

      if (modes.isTutorial()) return false
      return this.timeState.penaltySeconds > 0

    }
  }

})
</script>

<style lang="less">
.component-item {

    .time-keeper {
        position: initial;
        height: auto;

        .time-keeper-penalty-wrapper {
            width: 228px;
        }
    }
}
</style>
