import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface SpeedmeterState {
  visible: boolean,
  speed: number
}

const initialState = () => ({
  visible: false,
  speed: 0
})

const speedmeterState: Module<SpeedmeterState, RootState> = {
  state: initialState(),
  getters: {
    getSpeedmeterState: (moduleState: SpeedmeterState) => moduleState
  },
  mutations: {
    RESET: (moduleState: SpeedmeterState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: SpeedmeterState, newState: SpeedmeterState) => {

      if (newState.visible !== undefined) moduleState.visible = newState.visible
      if (newState.speed !== undefined) moduleState.speed = newState.speed

    },
    SET_VISIBILITY: (moduleState: SpeedmeterState, visible: boolean) => {

      moduleState.visible = visible

    }
  },
  namespaced: true
}

export default speedmeterState
