import {
  gameConfig,
  velocityConfig
} from '@/app/config'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'
import {
  SmallActionButtonColors,
  TutorialObjectiveIds
} from '@/app/types'
import store from '@/store'
import { modes } from '@powerplay/core-minigames'
import { player } from '.'

/**
 * Trieda pre spravu sprintu hraca
 */
export class PlayerSprintBarManager {

  /** Mnozstvo sprintu v sprintbare */
  private barValue = 0

  /** Mnozstvo frameov bez sprint fillu */
  private frameWithoutFill = 0

  /** Minimalna hodnota baru */
  public readonly MIN_VALUE = 0

  /** Maximalna hodnota baru */
  public readonly MAX_VALUE = 100

  /**
   * Obraz stateu storageu ktorym zabezpecime, ze nebudeme permanentne posielat zmenu stavou
   * do storage - farba obrazka
   */
  private actualColor = SmallActionButtonColors.yellow

  /** Zistenie ci sa smie sprintovat */
  public isSprintAvailable(): boolean {

    const gradient = player.velocityManager.getTrackActualGradient(true)
    const correctGradient = gradient >= velocityConfig.gradientForDisabledSprint
    const condition = !modes.isTutorial() ||
      (tutorialObjectives.getObjectiveById(TutorialObjectiveIds.sprint)?.passed ?? true)
    return this.barValue > 0 && condition && correctGradient

  }

  /**
   * Nastavenie farby pre button
   * @param color - Farba
   */
  private setButtonColor(color: SmallActionButtonColors): void {

    if (this.actualColor === color) return

    this.actualColor = color

    // zmenime farbu
    store.commit(
      'GamePhaseState/SET_SPRINT_BUTTON_COLOR',
      color
    )

  }

  /**
   * Metoda na upravu sprintBaru hore
   * @param speedBarValue - Hodnota speed baru
   */
  private refillSprint(speedBarValue: number): void {

    const speedBarIdeal = player.getSpeedBarMaxValue() - 10

    if (
      this.frameWithoutFill >= gameConfig.sprintBarFrameRate && speedBarValue <= speedBarIdeal
    ) {

      this.setButtonColor(SmallActionButtonColors.yellow)

      let sprintBarIncrease = (speedBarIdeal - speedBarValue) / 40 +
                gameConfig.sprintBarFillRate
      if (player.isCrouching) {

        sprintBarIncrease = gameConfig.sprintBarDownhillIncreaseRate

      }
      this.barValue += sprintBarIncrease
      this.frameWithoutFill = 0

      if (this.barValue > this.MAX_VALUE) this.barValue = this.MAX_VALUE

    } else if (speedBarValue > speedBarIdeal) {

      this.frameWithoutFill++
      if (player.isCrouching) {

        if (this.frameWithoutFill >= gameConfig.sprintBarFrameRate) {

          this.setButtonColor(SmallActionButtonColors.yellow)
          this.barValue += gameConfig.sprintBarDownhillIncreaseRate
          this.frameWithoutFill = 0
          if (this.barValue > this.MAX_VALUE) this.barValue = this.MAX_VALUE

        }
        return

      }
      this.setButtonColor(SmallActionButtonColors.red)

    } else {

      this.setButtonColor(SmallActionButtonColors.yellow)
      this.frameWithoutFill++

    }

  }

  /**
   * Komplexna metoda ktora riesi sprint bar ako aj povolenie sprintovat
   * @returns void
   */
  private handleSprint(): void {

    if (this.isSprintAvailable()) {

      const { sprintBarDecreaseRate, sprintBarFrameRate } = gameConfig

      if (this.frameWithoutFill >= sprintBarFrameRate) {

        this.setButtonColor(SmallActionButtonColors.green)

        this.barValue -= sprintBarDecreaseRate
        this.frameWithoutFill = 0

        if (this.barValue < this.MIN_VALUE) {

          this.barValue = this.MIN_VALUE
          tutorialFlow.handleSprint()

        }

      } else {

        this.frameWithoutFill++

      }

    } else {

      player.isSprinting = false

    }

  }

  /**
   * Aktualizacia sprint baru
   * @param speedBarValue - Hodnota speed baru
   */
  public update(speedBarValue: number): void {

    if (player.isSprinting) {

      this.handleSprint()

    } else {

      this.refillSprint(speedBarValue)

    }

    store.commit('GamePhaseState/SET_SPEED_BAR', this.barValue)

  }

  public setBarValue(value: number): this {

    this.barValue = value
    return this

  }

}
