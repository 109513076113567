import {
  AudioGroups,
  AudioNames,
  type DisciplinePhaseManager,
  ShootingTargetsTypes
} from '../../types'

import store from '@/store'
import {
  CANNON,
  game,
  THREE,
  fpsManager,
  gsap,
  audioManager
} from '@powerplay/core-minigames'
import { player } from '@/app/entities/player'
import {
  TriggerNames,
  triggers
} from '@/app/entities/trigger/Trigger'
import { trackpoints } from '@/app/entities/trackpoints/Trackpoints'
import { shootingTargetsManager } from '@/app/shootingTargetsManager'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import {
  audioGameConfig,
  shootingIntroConfig
} from '@/app/config'
import { audioHelper } from '@/app/audioHelper/AudioHelper'

/**
 * Trieda pre startovaciu fazu
 */
export class ShootingIntroPhase implements DisciplinePhaseManager {

  /** Defaultná začiatočná pozícia, bude prepísaná, ale nech máme fajn defaulty */
  private startPosition = new THREE.Vector3(-20, 1, 35)

  /** Defaultná stredná pozícia, bude prepísaná, ale nech máme fajn defaulty */
  private middlePosition = new THREE.Vector3(-20, 1, 11.2)

  /** Defaultná konečná pozícia, bude prepísaná, ale nech máme fajn defaulty */
  private endPositions = {
    [ShootingTargetsTypes.prone]: new THREE.Vector3(-27.6, 1, 8.79),
    [ShootingTargetsTypes.standing]: new THREE.Vector3(-27.6, 1, 11.2)
  }

  /** Cas animacie */
  private tweenTime = 5 * fpsManager.fpsLimit // seconds -> frames

  /** Zaciatok animacia */
  private startTime = 0

  /** Nasa pomocna kurva */
  private curve!: THREE.QuadraticBezierCurve3

  /** ci faza skoncila */
  private ended = false

  /** hodnota na ktorej stlacil */
  private initialPosition = new CANNON.Vec3(0, 0, 0)

  /** Pocet frameov od zaciatku fazy */
  private framesInPhase = 0

  /** Ci sa uz prehral zvuk zabrzdenia */
  private audioBreakPlayed = false

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** Konstruktor */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /** Ukoncenie fazy */
  public finishPhase(): void {

    console.warn('Finishing shooting intro phase')
    if (!this.ended) {

      this.ended = true
      triggers.removePhysicsBodyByName(TriggerNames.triggerIn)
      this.callbackEnd()

    }

  }

  /** nastavenie tweenu ukoncenia fazy */
  public setFinishPhaseTween(): void {
    // nic
  }

  /**
   * Vrati cas co bezi uz ovladanie intra
   * @returns - cas co to bezi frameovo
   */
  public getStartTime(): number {

    return this.startTime

  }

  /**
   * Vrati cas co ma bezat pohyb
   * @returns - vrati cas pohybu
   */
  public getMoveDuration(): number {

    return shootingIntroConfig.moveDuration

  }

  /**
   * Nastavenie inicial pozicie
   * @param value - Vektor pozicie
   */
  public setInitialPosition(value: THREE.Vector3): void {

    this.initialPosition.set(value.x, value.y, value.z)

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase(): void {

    store.commit('InputsState/SET_DISABLED', true)
    store.commit('MovementState/SET_HORIZONAL', false)
    store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', true)
    store.commit('GamePhaseState/SET_DISABLED_SMALL_BUTTONS', true)
    player.isCrouching = false
    player.isSprinting = false
    player.velocityManager.reset()
    player.velocityManager.setShootingIntroPower()

  }

  /**
   * Zacatie fazy
   */
  public startPhase(): void {


    game.togglePhysics(false)
    console.warn('starting Shooting intro phase')
    store.commit('MovementState/SET_POSITION_X', 0)
    if (this.ended) this.reset()
    this.preparePhase()
    this.prepareMovement()
    trackpoints.setActive(false)

    this.playShootingIntroCommentator()

    audioHelper.playMovementAudio(AudioNames.runSlowpace)
    // audioHelper.playMovementAudio(AudioNames.skiingBreak)
    this.increaseAudienceNoise()

    store.commit('InputsState/SET_VISIBLE', false)

  }

  /**
   * pustime komentatora
   */
  private playShootingIntroCommentator(): void {

    audioManager.stopAudioByGroup(AudioGroups.commentators)
    audioManager.play(AudioNames.commentShootingIntro)

  }

  /**
   * zadefinuje tween na znizenie hlasitosti divakov
   */
  private increaseAudienceNoise(): void {

    gsap.to(disciplinePhasesManager.audienceSoundVolume, {
      value: audioGameConfig.maxAudienceSound,
      duration: audioGameConfig.maxAudienceDuration
    })

  }

  /** Pripravenie pohybu a kurvy na pohyb */
  private prepareMovement() {

    this.startPosition.set(
      this.initialPosition.x,
      this.initialPosition.y,
      this.initialPosition.z
    )
    this.middlePosition.x = this.startPosition.x > -24 ?
      this.middlePosition.x - 3 :
      this.middlePosition.x

    const endPosition = this.endPositions[shootingTargetsManager.getActualShootingType()]

    this.middlePosition.x = this.startPosition.x
    this.middlePosition.y = this.startPosition.y
    endPosition.y = this.startPosition.y

    this.curve = new THREE.QuadraticBezierCurve3(
      this.startPosition,
      this.middlePosition,
      endPosition
    )

    // Save the start time
    this.startTime = 0

  }

  /** Samotna funkcia pohybu */
  private movePlayer() {

    this.startTime++

    // Progress is a number where 0 is at start position and 1 is at end position
    const progress = this.startTime / this.getMoveDuration()

    if (progress < 1) {

      const getPosition = this.curve.getPointAt(progress)
      const getPositionFurther = this.curve.getPointAt(progress + 0.00000001)
      player.playerObject.position.x = getPosition.x
      player.playerObject.position.z = getPosition.z
      player.playerObject.position.y = getPosition.y
      player.playerObject.lookAt(getPositionFurther)

      if (!this.audioBreakPlayed && progress > 0.7) {

        this.audioBreakPlayed = true
        audioHelper.playMovementAudio(AudioNames.skiingBreak)

      }

    } else {

      const endPosition = this.endPositions[shootingTargetsManager.getActualShootingType()]

      player.physicsBody.position.set(
        endPosition.x,
        endPosition.y,
        endPosition.z
      )

      player.physicsBody.velocity.set(0, 0, 0)
      this.finishPhase()

    }

  }

  /**
   * Update kazdy frame
   */
  public update(): void {

    this.framesInPhase++
    this.movePlayer()

  }

  /**
   * reset fazy
   */
  private reset(): void {

    store.commit('InputsState/SET_DISABLED', true)
    this.ended = false
    this.framesInPhase = 0

  }

}
