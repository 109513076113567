import type { DisplayMessage } from '@/app/types'
import store from '@/store'
import { modes } from '@powerplay/core-minigames'

export class StartPhaseStateManager {

  private store = store

  public disableInputs(): void {

    this.store.commit('InputsState/SET_DISABLED', true)
    this.store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', false)

  }

  public enableInputs(): void {

    this.store.commit('InputsState/SET_VISIBLE', true)
    this.store.commit('InputsState/SET_DISABLED', false)

  }

  public postIntroUiState(): void {

    this.store.commit('UiState/SET_STATE', {
      showTimeKeeper: false,
      showSplitTimes: false,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })
    this.store.commit('MovementState/SET_HORIZONAL', true)
    this.store.commit('InputsState/SET_DISABLED', false)

  }

  public finishPhaseUiState(): void {

    this.store.commit('UiState/SET_STATE', {
      showTimeKeeper: true,
      showSplitTimes: !modes.isTrainingMode(),
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })
    this.store.commit('HeartRateState/SET_VISIBLE', true)

  }

  public enableStartInputs(): void {

    this.store.commit('InputsState/SET_DISABLED', false)
    this.store.commit('ActionButtonState/SET_START_BUTTON', true)
    this.store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', false)

  }

  public showTakeOffMessageInUI(message: DisplayMessage): void {

    this.store.commit('StartMessageState/SET_STATE', {
      showMessage: true,
      messageText: message.message,
      messageColor: message.color
    })

  }

  public disableTakeOffMessageInUI(message: DisplayMessage): void {

    this.store.commit('StartMessageState/SET_STATE', {
      showMessage: false,
      messageText: message.message,
      messageColor: message.color
    })

  }

}

export const startPhaseStateManager = new StartPhaseStateManager()
