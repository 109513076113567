<template>
  <div
    v-if="!isMobile && gamePhaseState.showSmallActionButtons"
    class="nomobile-start-bar"
  >
    <small-action-buttons v-if="gamePhaseState.showBar && !getActionDisabled" />
    <speed-bar v-if="gamePhaseState.showBar && !getActionDisabled" />
    <start-efficiency-bar
      v-if="startPhaseState.showBar"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import StartEfficiencyBar from './StartEfficiencyBar.vue'
import SmallActionButtons from './SmallActionButtons.vue'
import SpeedBar from './SpeedBar.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EfficiencyComponent',
  components: {
    StartEfficiencyBar,
    SmallActionButtons,
    SpeedBar
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      startPhaseState: 'StartPhaseState/getStartPhaseState',
      getActionDisabled: 'ActionButtonState/getActionDisabled',
      gamePhaseState: 'GamePhaseState/getGamePhaseState'
    })
  }
})
</script>

<style lang='less' scoped>
.nomobile-start-bar {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 375px;
  height: 375px;
}
</style>
