<template>
  <div>
    <traffic-light-box
      v-if="!startMessageState.showMessage && wrongWay"
      class="traffic-light-box"
      :color="2"
      :text="$t('wrongWay')"
    />
    <traffic-light-box
      v-if="startMessageState.showMessage"
      class="traffic-light-box"
      :color="startMessageState.messageColor"
      :text="$t(startMessageState.messageText)"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import { TrafficLightBox } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TrafficComponent',
  components: {
    TrafficLightBox
  },
  computed: {
    ...mapGetters({
      startMessageState: 'StartMessageState/getStartMessageState',
      wrongWay: 'DirectionsState/getWrongWay'
    })
  }
})
</script>

<style lang="less" scoped>
.traffic-light-box{
    position: absolute;
    left: 50%;
    top: 5%;
}
</style>
