import store from '@/store'
import {
  corePhasesManager,
  modes,
  playersManager,
  requestManager,
  timeManager,
  type TrainingDataFromResultsRequest,
  trainingManager,
  gameStats
} from '@powerplay/core-minigames'
import { shootingPhaseConfig } from './config/shootingPhaseConfig'
import { player } from './entities/player'
import { triggers } from './entities/trigger/Trigger'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { tutorialObjectives } from './modes/tutorial/TutorialObjectives'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import type { ShootingPhaseManager } from './phases/ShootingPhase/ShootingPhase'
import type { StartPhaseManager } from './phases/StartPhase/StartPhase'
import {
  DisciplinePhases,
  type SaveResultsDataToSend
} from './types'

/**
 * Trieda pre koniec discipliny
 */
export class EndManager {

  /** ci uz bol result poslany alebo nie */
  private resultSent = false

  /**
   * Poslanie requestu pre konecne logovanie
   */
  public sendLogEnd(): void {

    // ak uz mame nastavene, tak uz viac nenastavujeme
    if (Object.keys(gameStats.getDisciplineDataToLog()).length > 0) return

    const startPhaseManager = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager

    const sendTimeZero = playersManager.dnf || modes.isTutorial() || modes.isTrainingMode()

    // zaznamename nejake info pre logy
    gameStats.setDisciplineDataToLog({
      time: sendTimeZero ? 0 : playersManager.players[0].resultsArr?.[
        corePhasesManager.disciplineActualAttempt - 1
      ].main || 0,
      penalty: timeManager.getPenaltyInfo().seconds,
      dnf: playersManager.dnf,
      outOfBounds: player.outOfBounds,
      split: playersManager.dnf ? [] : triggers.splitTimeManager.getAllSplitTimes(),
      qualities: {
        start: startPhaseManager.clickedPower / 100
      },
      playerPosition: playersManager.getPlayerActualPosition(),
      trainingTasks: modes.isTrainingMode() ?
        trainingManager.getTrainingTasks().map(task => task.value) :
        [],
      tutorialData: modes.isTutorial() ? this.getTutorialLogs() : []
    })

    console.log('LOG to send', gameStats.getDisciplineDataToLog())

  }

  /**
   * ziskame pole tutorialovych logov
   *
   * @returns - pole tutorialovych logov
   */
  private getTutorialLogs(): (number | boolean)[] {

    /**
     * repeatedShooting - Kolkokrat hrac opakoval strelbu [number]
     * targetHits - Kolko tercov trafil [number]
     * completed - Ci hrac uspesne dokoncil vsetky ulohy [boolean]
     * exited - Ci bol ukonceny tutorial [boolean]
     */

    const shootingPhase =
      disciplinePhasesManager.getDisciplinePhaseManager(DisciplinePhases.shooting) as ShootingPhaseManager

    return [
      tutorialFlow.shootingAttemptCount, // repeatedShooting
      shootingPhase.getHitTargets(), // targetHits
      tutorialObjectives.isAllObjectivesPassed(), // completed
      disciplinePhasesManager.prematureEnded // exited
    ]

  }

  /**
   * Vybratie dat a poslanie do funkcie z core-minigames
   */
  public sendSaveResult(): void {

    // ak uz bol result poslany, neposielame ho znova
    if (this.resultSent) return

    this.resultSent = true

    // TODO TEMP - zatial takto, ked bude hotovy tutorial, tak sa to bude posielat tam
    requestManager.sendTutorialRequest()
    if (modes.isTutorial()) return

    const missedTargetsArr = playersManager.players[0].resultsArr?.[
      corePhasesManager.disciplineActualAttempt - 1
    ]?.second as number[] ?? []

    const data: SaveResultsDataToSend = {
      time: playersManager.players[0].resultsArr?.[
        corePhasesManager.disciplineActualAttempt - 1
      ].main || 0,
      penalty_time: timeManager.getPenaltyInfo().seconds,
      positions: playersManager.getPlayersPositions(),
      dnf: playersManager.dnf,
      missedTargets: [missedTargetsArr[0]],
      // TODO: aj toto je zatial natvrdo, neskor doplnime o viac strelb
      shotdownTargets: shootingPhaseConfig.attemptCount - missedTargetsArr[0],
      split_times: playersManager.dnf ? [] : triggers.splitTimeManager.getAllSplitTimes()
    }

    if (modes.isTrainingMode()) {

      data.trainingResults = trainingManager.getTrainingTasks().map(task => task.value)

    }

    console.log('data to send', data)

    requestManager.sendSaveResultsRequest(
      (dataCallback: TrainingDataFromResultsRequest | unknown) => {

        console.log('saveRequest Successful')

        if (modes.isTrainingMode()) {

          store.commit('TrainingResultsState/SET_STATE_DATA', {
            data: dataCallback,
            bestScore: trainingManager.bestScore
          })

        }

      },
      JSON.stringify(data)
    )

  }

  /**
   * Reset result
   */
  public reset(): void {

    this.resultSent = false

  }

}

export const endManager = new EndManager()
