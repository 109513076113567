import { playerAnimationConfig } from '@/app/config'
import { inputsManager } from '@/app/InputsManager'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import type { ShootingIntroPhase } from '@/app/phases/ShootingIntroPhase/ShootingIntroPhase'
import type { StartPhaseManager } from '@/app/phases/StartPhase/StartPhase'
import { startPhaseStateManager } from '@/app/phases/StartPhase/StartPhaseStateManager'
import {
  AudioNames,
  DisciplinePhases,
  PlayerAnimationsNames
} from '@/app/types'
import store from '@/store'
import {
  CallbackAnimationTypes,
  corePhasesManager,
  playersManager,
  tutorialManager
} from '@powerplay/core-minigames'
import { player } from '.'
import { InternalCrossfadesManager } from './InternalCrossfadesManager'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import type { ShootingOutroPhase } from '@/app/phases/ShootingOutro/ShootingOutro'

/**
 * Dedikovany manazer animacii pre hraca.
 * Nie je to najkrajsi pattern, lebo priamo berie hracove publik atributy... ale sorry not sorry
 */
export class PlayerAnimationManager {

  /** Prave beziaca animacia */
  private animationRunning = PlayerAnimationsNames.v1Skating

  /** Ci je aktivny starting state */
  private startingStateActive = false

  /** Ci je aktivny starting state */
  private skatingStateActive = false

  /** Ci je aktivny end state */
  private endStateActive = false

  /** Aktualny cyklus skatingu */
  private actualSkatingLoop = 0

  /** aktualna animacia - TODO rozsir o vsetky animacie mimo konecnej */
  public actualAnimation?: PlayerAnimationsNames

  /** ci sme nastavili konecnu animaciu */
  public isEndEmotionSet = false

  /** Manazer internych crossfadov */
  private internalCrossfadesManager: InternalCrossfadesManager

  /**
   * Konstruktor
   */
  public constructor() {

    this.internalCrossfadesManager = new InternalCrossfadesManager()

  }

  /**
   * Nastavenie interneho crossfade
   * @param animation - Animacia
   * @param frames - Pocet frameov na tento proces
   * @param changeTime - Ci sa ma nastavovat cas podla starej animacie, v podstate keepTime parameter crossfadeTo
   */
  private setInternalCrossfade(animation: PlayerAnimationsNames, frames: number, changeTime = true): void {

    const possibleNewRunnningAnimation = this.internalCrossfadesManager.setCrossfade(
      animation,
      this.animationRunning,
      frames,
      changeTime
    )
    if (possibleNewRunnningAnimation) this.animationRunning = possibleNewRunnningAnimation

  }

  /**
   * Spustenie startovacej animacie
   */
  private startAnimation(): void {

    // player.animationsManager.crossfadeTo(PlayerAnimationsNames.start, 0.01, true, false)
    player.animationsManager.changeTo(PlayerAnimationsNames.start)

  }

  /**
   * Spustenie animacie neutral
   */
  private startNeutralAnimation(): void {

    player.animationsManager.changeTo(PlayerAnimationsNames.neutral)

  }

  /**
   * Spustenie animacie happy
   */
  private startHappyAnimation(): void {

    player.animationsManager.changeTo(PlayerAnimationsNames.happy)

  }

  /**
   * Start V3 skatingu
   */
  private startV3SkatingAnimation(): void {

    player.animationsManager.crossfadeTo(
      PlayerAnimationsNames.v3Skating,
      playerAnimationConfig.defaultCrossfadeTime,
      true,
      false
    )

  }

  /**
   * Start animacie downhill left
   */
  private startDownhillLeftAnimation(): void {

    this.setInternalCrossfade(PlayerAnimationsNames.downhillLeft, 3)

  }

  /**
   * Start animacie downhill right
   */
  private startDownhillRightAnimation(): void {

    this.setInternalCrossfade(PlayerAnimationsNames.downhillRight, 3)

  }

  /**
   * Spustenie animacie sprintu
   */
  private startSprintAnimation(): void {

    this.setInternalCrossfade(PlayerAnimationsNames.sprint, 3)

  }

  /**
   * Spustenie animacie crouchu
   */
  private startDownhillAnimation(): void {

    this.setInternalCrossfade(PlayerAnimationsNames.downhill, 15)

  }

  /**
   * Start animacie V1
   * @param sprint - ci hrac sprintuje
   */
  private startV1Animation(sprint = false): void {

    audioHelper.playMovementAudio(sprint ? AudioNames.runFastpace : AudioNames.runSlowpace)

    this.setInternalCrossfade(PlayerAnimationsNames.v1Skating, 3)

  }

  /**
   * Start animacie V2
   */
  private startV2Animation(): void {

    audioHelper.playMovementAudio(AudioNames.runFastpace)

    this.setInternalCrossfade(PlayerAnimationsNames.v2Skating, 10)

  }

  /**
   * Start animacie prestart
   * @param crossfadeTime - Cas, za ktory sa vykona crossfade
   */
  private startPrestartAnimation(crossfadeTime = playerAnimationConfig.defaultCrossfadeTime): void {

    player.animationsManager.crossfadeTo(
      PlayerAnimationsNames.prestart,
      crossfadeTime,
      true,
      false
    )

  }

  /**
   * Start animacie prichodu na strelnicu
   */
  private startShootingComeInPreStopAnimation(): void {

    this.setInternalCrossfade(PlayerAnimationsNames.shootingComeInPreStop, 15)

  }

  /**
   * Start animacie stop2
   */
  private startStop2Animation(): void {

    this.setInternalCrossfade(PlayerAnimationsNames.stop2, 25)

  }

  /**
   * Start animacie stop1
   * @param crossfadeTime - Cas, za ktory sa vykona crossfade
   */
  private startStop1Animation(crossfadeTime = playerAnimationConfig.defaultCrossfadeTime): void {

    player.animationsManager.crossfadeTo(
      PlayerAnimationsNames.stop1,
      crossfadeTime,
      true,
      false
    )

  }

  /**
   * Zmena rychlosti animacii
   * @param speed - Nova rychlost animacii
   */
  private changeAnimationSpeed(speed: number) {

    player.animationsManager.setSpeed(speed)

  }

  /**
   * Reset rychlosti animacii
   */
  private resetAnimationSpeed() {

    player.animationsManager.resetSpeed()

  }

  /**
   * Spustenie animacie downhill pre rovny smer
   */
  private downhillForward(): void {

    if (this.animationRunning !== PlayerAnimationsNames.downhill) {

      this.startDownhillAnimation()
      this.animationRunning = PlayerAnimationsNames.downhill

    }

  }

  /**
   * Spustenie animacie downhill vlavo
   */
  private downhillLeft(): void {

    if (this.animationRunning !== PlayerAnimationsNames.downhillLeft) {

      this.startDownhillLeftAnimation()
      this.animationRunning = PlayerAnimationsNames.downhillLeft

    }

  }

  /**
   * Spustenie animacie downhill vpravo
   */
  private downhillRight(): void {

    if (this.animationRunning !== PlayerAnimationsNames.downhillRight) {

      this.startDownhillRightAnimation()
      this.animationRunning = PlayerAnimationsNames.downhillRight

    }

  }

  /**
   * Riesenie veci pre downhill animacie
   */
  private downhillSituation(): void {

    if (player.isCrouching) {

      if (this.animationRunning === PlayerAnimationsNames.sprint) this.resetAnimationSpeed()
      // console.log('Starting Downhill Animation')

      audioHelper.playMovementAudio(AudioNames.skiingGlide)

      if (inputsManager.moveDirectionLeft ||
                store.getters['MovementState/getPositionX'] < 0) {

        this.downhillLeft()

      } else if (
        inputsManager.moveDirectionRight ||
                store.getters['MovementState/getPositionX'] > 0
      ) {

        this.downhillRight()

      } else {

        this.downhillForward()

      }

    }

  }

  /**
   * Riesenie veci pre sprint
   */
  private sprintSituation(): void {

    if (player.isSprinting && this.animationRunning !== PlayerAnimationsNames.sprint) {

      if (
        this.animationRunning === PlayerAnimationsNames.downhill ||
        this.animationRunning === PlayerAnimationsNames.downhillLeft ||
        this.animationRunning === PlayerAnimationsNames.downhillRight
      ) {

        this.resetAnimationSpeed()

      }
      // console.log('Starting Sprint Animation')
      if (player.isUphillEnabled()) {

        this.changeAnimationSpeed(playerAnimationConfig.animationSpeedForSprint)
        this.startV1Animation(true)

      } else {

        this.startSprintAnimation()

      }
      this.animationRunning = PlayerAnimationsNames.sprint

    }

  }

  /**
   * Riesenie veci pre klasicky beh
   * @returns True, ak ide o neutralnu situaciu
   */
  private neutralSituation(): boolean {

    if (!player.isCrouching && !player.isSprinting && !player.isSkating) {

      this.setRunningAnimationFromDownhillSituation()
      return true

    } else {

      return false

    }

  }

  /**
   * Nastavenie animacii pre downhill
   */
  private setRunningAnimationFromDownhillSituation(): void {

    if (player.isUphillEnabled()) {

      if (this.animationRunning !== PlayerAnimationsNames.v1Skating) {

        // console.log('Starting V1 Animation')
        this.resetAnimationSpeed()
        this.startV1Animation()
        this.animationRunning = PlayerAnimationsNames.v1Skating

      }

    } else {

      if (this.animationRunning !== PlayerAnimationsNames.v2Skating) {

        // console.log('Starting V2 Animation')
        this.resetAnimationSpeed()
        this.startV2Animation()
        this.animationRunning = PlayerAnimationsNames.v2Skating

      }

    }

  }

  /**
   * Riesenie veci pre specialne situacie
   * @returns True, ak ide o specialnu situaciu
   */
  private specialSituation(): boolean {

    const isGameStart = this.isGameStartState()
    const isShooting = this.shootingIntroOutro()
    const isStarting = this.isStartingState()
    const isSkating = this.isSkatingState()
    const isEnd = this.isEndState()

    return isShooting || isGameStart || isStarting || isSkating || isEnd

  }

  /**
   * Riesenie veci pre game start stav
   * @returns True, ak ide o dany stav
   */
  private isGameStartState(): boolean {

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.preStart) {

      return true

    }

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.start) {

      const discipline = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager

      if (
        discipline.getCameraInPostIntroState() &&
        this.animationRunning !== PlayerAnimationsNames.prestart
      ) {

        this.startPrestartAnimation()
        this.animationRunning = PlayerAnimationsNames.prestart

      }
      return true

    } else {

      return false

    }
    /*
     * return (disciplinePhasesManager.getActualPhase() === DisciplinePhases.preStart ||
     * disciplinePhasesManager.getActualPhase() === DisciplinePhases.start)
     */

  }

  /**
   * Riesenie veci pre intro & outro shootingu
   * @returns True, ak ide o dany stav
   */
  private shootingIntroOutro(): boolean {

    const phase = disciplinePhasesManager.getActualPhase()
    if (phase === DisciplinePhases.shootingIntro) {

      const phaseInfo = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.shootingIntro) as ShootingIntroPhase
      const progress = phaseInfo.getStartTime() / phaseInfo.getMoveDuration()

      // console.log(progress)
      if (progress < 0.8) {

        if (this.animationRunning !== PlayerAnimationsNames.shootingComeInPreStop) {

          this.resetAnimationSpeed()
          this.startShootingComeInPreStopAnimation()
          this.animationRunning = PlayerAnimationsNames.shootingComeInPreStop

        }

      } else {

        if (this.animationRunning !== PlayerAnimationsNames.stop2) {

          this.resetAnimationSpeed()
          this.startStop2Animation()
          this.animationRunning = PlayerAnimationsNames.stop2

        }

      }

      return true

    }
    if (phase === DisciplinePhases.shootingOutro) {

      const phaseInfo = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.shootingOutro) as ShootingOutroPhase
      const progress = phaseInfo.getStartTime() / phaseInfo.getMoveDuration()

      if (progress < 0.8) {

        if (this.animationRunning !== PlayerAnimationsNames.v3Skating) {

          this.changeAnimationSpeed(playerAnimationConfig.animationSpeedForOutro)
          this.setInternalCrossfade(PlayerAnimationsNames.v3Skating, 3)
          this.animationRunning = PlayerAnimationsNames.v3Skating

        }

      } else {

        if (this.animationRunning !== PlayerAnimationsNames.v2Skating) {

          this.setInternalCrossfade(PlayerAnimationsNames.v2Skating, 10)
          this.animationRunning = PlayerAnimationsNames.v2Skating

        }

      }

      return true

    }

    return false

  }

  /**
   * Riesenie veci pre starting stav
   * @returns True, ak ideo o dany stav
   */
  private isStartingState(): boolean {

    if (!this.startingStateActive && player.isStarting) {

      this.startingStateActive = true

      player.animationsManager.addAnimationCallback(
        PlayerAnimationsNames.start,
        CallbackAnimationTypes.end,
        () => {

          /*
           * console.log('START ANIMATION -- end')
           * this.startSkatingAnimation()
           */
          player.isStarting = false
          player.isSkating = true
          this.startingStateActive = false

          player.animationsManager.removeAnimationCallback(
            PlayerAnimationsNames.start,
            CallbackAnimationTypes.end
          )

        }
      )

      this.changeAnimationSpeed(playerAnimationConfig.animationSpeedForSprint)
      this.startAnimation()
      audioHelper.playMovementAudio(AudioNames.runFastpace)

    }

    return player.isStarting

  }

  /**
   * Riesenie veci pre skating stav
   * @returns True, ak ideo o dany stav
   */
  private isSkatingState(): boolean {

    if (!this.skatingStateActive && player.isSkating) {

      this.skatingStateActive = true

      player.animationsManager.addAnimationCallback(
        PlayerAnimationsNames.v3Skating,
        CallbackAnimationTypes.loop,
        () => {

          this.actualSkatingLoop++
          if (this.actualSkatingLoop < playerAnimationConfig.skatingLoopsOnStart) return

          // console.log('SKATING ANIMATION -- end')
          player.isSkating = false
          this.skatingStateActive = false
          player.activeUpdatingMovementAnimations = true

          startPhaseStateManager.enableInputs()

          player.animationsManager.removeAnimationCallback(
            PlayerAnimationsNames.v3Skating,
            CallbackAnimationTypes.loop
          )

          this.resetAnimationSpeed()
          this.internalCrossfadesManager.enable(PlayerAnimationsNames.v3Skating)
          this.startV2Animation()
          this.animationRunning = PlayerAnimationsNames.v2Skating
          tutorialManager.nextSection()

        }
      )

      this.changeAnimationSpeed(playerAnimationConfig.animationSpeedForSkating)
      this.startV3SkatingAnimation()

    }

    return player.isSkating

  }

  /**
   * Riesenie veci pre end stav
   * @returns True, ak ide o dany stav
   */
  private isEndState(): boolean {

    if (!this.endStateActive && player.isEnd) {

      this.endStateActive = true

      const emotionAnimation = this.getEndEmotion()
      this.actualAnimation = emotionAnimation

      player.animationsManager.addAnimationCallback(
        emotionAnimation,
        CallbackAnimationTypes.end,
        () => {

          // this.endStateActive = false

          player.animationsManager.removeAnimationCallback(
            emotionAnimation,
            CallbackAnimationTypes.end
          )

          player.animationsManager.resetSpeed()
          this.startStop1Animation(0.2)

        }
      )

      this.resetAnimationSpeed()

      this.internalCrossfadesManager.disable(this.animationRunning)
      if (emotionAnimation === PlayerAnimationsNames.neutral) this.startNeutralAnimation()
      if (emotionAnimation === PlayerAnimationsNames.happy) this.startHappyAnimation()
      // musime este zresetovat poslednu animaciu
      player.animationsManager.setWeight(this.animationRunning, 0)

    }

    return player.isEnd

  }

  /**
   * Samotna logika
   */
  private animationLogic(): void {

    const isSpecialSituation = this.specialSituation()

    if (!isSpecialSituation) {

      const isNeutralState = this.neutralSituation()

      if (!isNeutralState) {

        this.sprintSituation()
        this.downhillSituation()

      }

    }

  }

  /**
   * Update metoda volana v move metode velocity manazera
   */
  public update(): void {

    this.animationLogic()

    // aplikujeme zmenu vah pre interne crossfady
    this.internalCrossfadesManager.update()

  }

  /**
   * Vratenie konecnej emocie
   * @returns Emocia
   */
  private getEndEmotion = (): PlayerAnimationsNames => {

    this.isEndEmotionSet = true

    const time = playersManager.players[0].resultsArr?.[
      corePhasesManager.disciplineActualAttempt - 1
    ].main

    if (
      playersManager.getPlayerActualPosition() <= 3 ||
      (time !== undefined && time <= playersManager.getPlayer().personalBest)
    ) {

      return PlayerAnimationsNames.happy

    }

    return PlayerAnimationsNames.neutral

  }

  /**
   * Resetovanie
   */
  public reset(): void {

    this.internalCrossfadesManager.reset()

  }

}

export const playerAnimationManager = new PlayerAnimationManager()
