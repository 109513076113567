<template>
  <div>
    <power-stick
      v-if="showJoystick()"
      :disabled="disabled"
      :only-horizontal="isHorizontal"
      :is-scaled="isShooting"
      @position-x="changeMovementX"
      @position-y="changeMovementY"
      @end-contact="endContact"
    />
    <movement-buttons
      v-if="showArrows()"
      :is-scaled="false"
      :disabled="disabled"
      :style="{
        position: 'absolute',
        bottom: '0%'
      }"
      @position="changeMovementX"
      @end-contact="endContact"
    />
  </div>
</template>

<script lang="ts">
import {
  MovementButtons,
  PowerStick
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { inputsManager } from '@powerplay/core-minigames'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'MovementControl',
  components: {
    PowerStick,
    MovementButtons
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isHorizontal: 'MovementState/getIsHorizontal',
      gameSettingsState: 'GameSettingsState/getGameSettingsState',
      forceJoystick: 'InputsState/getIsForceJoystick',
      isShooting: 'ActionButtonState/getIsShooting'
    })
  },
  methods: {
    endContact() {

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()
      this.$store.commit('MovementState/SET_POSITION_X', 0)
      this.$store.commit('MovementState/SET_POSITION_Y', 0)

    },
    changeMovementX(positionX: number) {

      if (this.disabled) return

      this.$store.commit('MovementState/SET_POSITION_X', positionX)

    },
    changeMovementY(positionY: number) {

      if (this.disabled) return

      this.$store.commit('MovementState/SET_POSITION_Y', positionY)

    },
    showArrows() {

      return !this.showJoystick()

    },
    showJoystick() {

      return this.forceJoystick

    }

  }
})
</script>
