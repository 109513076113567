import {
  CANNON,
  timeManager,
  audioManager,
  CustomEvents,
  corePhasesManager,
  playersManager,
  gsap,
  modes,
  game,
  TimesTypes,
  fpsManager
} from '@powerplay/core-minigames'
import {
  type DisciplinePhaseManager,
  AudioNames,
  DisciplinePhases
} from '../types'
import { StartPhaseManager } from './StartPhase/StartPhase'
import { FinishPhaseManager } from './FinishPhase/FinishPhase'
import { player } from '../entities/player'
import { gameConfig } from '../config'
import { RunningPhase } from './RunningPhase/RunningPhase'
import { ShootingIntroPhase } from './ShootingIntroPhase/ShootingIntroPhase'
import { ShootingOutroPhase } from './ShootingOutro/ShootingOutro'
import store from '@/store'
import { ShootingPhaseManager } from './ShootingPhase/ShootingPhase'
import { endManager } from '../EndManager'
import { shootingTargetsManager } from '../shootingTargetsManager'
import { shootingDirectionManager } from '../shootingDirectionManager'
import { trainingTasks } from '../modes/training'

/**
 * Trieda pre spravu faz
 */
export class DisciplinePhasesManager {

  /** aktualna faza */
  public actualPhase = 0

  /** pole vytvorenych faze managerov */
  private phaseManagers: DisciplinePhaseManager[] = []

  /** distance between start and end */
  private distanceToEnd = 0

  /** tween na nastartovanie fazoveho managera */
  private startDisciplineTween!: gsap.core.Tween

  /** hlasitost pre divakov */
  public audienceSoundVolume = { value: 1 }

  /** ci bola hra ukoncena predcasne */
  public prematureEnded = false

  /**
   * Vytvorenie a nastavenie veci
   */
  public create(): void {

    this.createAllPhases()
    shootingTargetsManager.init()
    shootingDirectionManager.init()

  }

  /**
   * Vytvorenie menegerov faz
   */
  public createAllPhases(): void {

    this.phaseManagers[DisciplinePhases.start] = new StartPhaseManager(() => {

      player.launchStartAnimation()
      this.startDisciplinePhase(DisciplinePhases.running)
      timeManager.setActive(TimesTypes.game, true)
      timeManager.changeTimeSpeed(gameConfig.timeSpeedRunning)

    })

    this.phaseManagers[DisciplinePhases.running] = new RunningPhase(() => {

      this.startDisciplinePhase(DisciplinePhases.finish)
      timeManager.setActive(TimesTypes.game, false)

    })

    this.phaseManagers[DisciplinePhases.shootingIntro] = new ShootingIntroPhase(() => {

      console.log('dispatch end of shooting intro phase')
      this.startDisciplinePhase(DisciplinePhases.shooting)

    })

    this.phaseManagers[DisciplinePhases.shooting] = new ShootingPhaseManager(() => {

      if (modes.isTrainingMode()) {

        store.commit('TrainingState/SET_IS_FINISHED_SHOOTING', true)
        game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)

      } else {

        console.log('koniec')
        // zatial iba docasne
        player.physicsBody.type = CANNON.BODY_TYPES.STATIC
        this.startDisciplinePhase(DisciplinePhases.shootingOutro)

      }

    })

    this.phaseManagers[DisciplinePhases.shootingOutro] = new ShootingOutroPhase(() => {

      console.log('dispatch end of shooting outro phase')
      this.actualPhase = DisciplinePhases.running

    })

    this.phaseManagers[DisciplinePhases.finish] = new FinishPhaseManager(() => {

      this.actualPhase++
      console.log('dispatch end')
      if (DisciplinePhases[this.actualPhase]) {

        console.log('dispatch end')
        window.dispatchEvent(new CustomEvent(CustomEvents.finishDisciplinePhase))

        store.commit('WaitingState/SET_STATE', {
          isWaiting: true
        })

      }

    })

    // TODO: toto este budeme musiet nejako vymysliet, kam s tym
    player.setCollisionEndCallback(() => {

      if (this.actualPhase === DisciplinePhases.running) {

        /*
         * console.log('bol pad...')
         * const finish = this.phaseManagers[DisciplinePhases.finish] as FinishPhaseManager
         */

        // this.phaseManagers[DisciplinePhases.running].finishPhase()

      }

    })

  }

  /**
   * Zistenie, ci jedna z faza je aktualna faza
   * @param phase - Pole faz na skontrolovanie
   * @returns True, ak je jedna z faz aktualna
   */
  public oneOfPhaseIsActual(phases: DisciplinePhases[]): boolean {

    return phases.includes(this.actualPhase)

  }

  public getActualPhase(): DisciplinePhases {

    return this.actualPhase

  }

  /**
   * Vratenie konkretneho fazoveho menezera
   * @param phase - Faza
   * @returns Fazovy menezer
   */
  public getDisciplinePhaseManager(phase: DisciplinePhases): DisciplinePhaseManager {

    return this.phaseManagers[phase]

  }

  /**
   * Spustenie fazy
   * @param phase - Cislo fazy
   */
  public startDisciplinePhase(phase: DisciplinePhases): void {

    this.actualPhase = phase
    this.phaseManagers[phase].startPhase()

  }

  /**
   * Update aktualnej fazy kazdy frame
   */
  public update(): void {

    this.updateAudienceSound()

    this.phaseManagers[this.actualPhase]?.update()

  }

  /**
   * zmen hlasitost divakov podla vzdialenosti
   */
  private updateAudienceSound(): void {

    // audioManager.changeAudioVolume(AudioNames.audienceNoise, this.audienceSoundVolume.value)

  }

  /**
   * resetovanie hry
   */
  private resetAttempt(shouldPlayerReset: boolean): void {

    if (shouldPlayerReset) player.reset()

    this.actualPhase = 0
    this.distanceToEnd = 0

  }

  /**
   * rekurzivne ukoncime vsetky fazy
   */
  public disciplinePrematureEnd = async (): Promise<void> => {

    if (modes.isTrainingMode()) {

      const shootPhase = this.phaseManagers[DisciplinePhases.shooting] as ShootingPhaseManager
      shootPhase.killShootingTraining()

    }

    this.actualPhase = DisciplinePhases.end

    audioManager.stopAllAudio()
    audioManager.play(AudioNames.audienceNoise, undefined, undefined, 1)

    if (player.physicsBody) player.physicsBody.type = CANNON.BODY_TYPES.STATIC
    corePhasesManager.disciplineActualAttempt = corePhasesManager.disciplineAttemptsCount
    playersManager.setStandings(1)
    console.log('STANDINGS', playersManager.getStandings())

    fpsManager.pauseCounting()

    let isFinished = false
    if (store.getters['TrainingState/getTrainingState'].isFinishedShooting) {

      isFinished = true

    }

    // pri treningu musime dokoncit udaje
    trainingTasks.saveLastTasksValues()

    // posleme udaje
    endManager.sendLogEnd()
    endManager.sendSaveResult()

    // reset states
    await store.dispatch('clearStateAll')

    store.commit('WaitingState/SET_STATE', {
      isWaiting: true
    })
    if (!isFinished || corePhasesManager.firstInstructions) {

      store.commit('TrainingResultsState/SET_TRAIN_AGAIN_DISABLED', true)

    }
    // stopneme vsetky animacne callbacky
    if (player.animationsManager) player.animationsManager.removeCallbacksFromAllAnimations()

  }

  /**
   * Nastartovanie disciplinoveho fazoveho managera
   */
  public setStartPhase = (): void => {

    // musime tu dat mensi delay, lebo mozeme skipovat este nejake fazy predtym
    this.startDisciplineTween = gsap.to({}, {
      duration: modes.isTutorial() ? 0 : 0.2,
      onComplete: () => {

        let phase = DisciplinePhases.start
        if (modes.isTrainingMode()) phase = DisciplinePhases.shooting

        this.startDisciplinePhase(phase)

      }
    })

  }

  /**
   * Reinstancovanie manazerov
   */
  public reset(): void {

    this.createAllPhases()
    this.resetAttempt(false)
    shootingTargetsManager.init()
    shootingDirectionManager.init()
    this.prematureEnded = false

  }

}

export const disciplinePhasesManager = new DisciplinePhasesManager()
