<template>
  <bullets-targets-box
    v-if="visible"
    :bullets="bullets"
    :targets="targets"
    :targets-type="targetsType"
  />
</template>

<script lang="ts">
// External Package codes
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'

/*
 * Internal Package codes
 * import {} from '@powerplay/core-minigames'
 */
import { BulletsTargetsBox } from '@powerplay/core-minigames-ui'

export default defineComponent({
  name: 'StructureComponent',
  components: {
    BulletsTargetsBox
  },
  computed: {
    ...mapGetters({
      visible: 'BulletsTargetsBoxState/getDisplayBulletsTargets',
      bullets: 'BulletsTargetsBoxState/getBullets',
      targets: 'BulletsTargetsBoxState/getTargets',
      targetsType: 'BulletsTargetsBoxState/getTargetsType'
    })
  }
})
</script>

<style lang="less" scoped>
</style>
