import {
  playersManager,
  type SplitTimeData,
  timeManager,
  type PlayerInfo,
  minigameConfig,
  modes,
  audioManager,
  gsap
} from '@powerplay/core-minigames'
import store from '@/store'
import {
  AudioGroups,
  AudioNames
} from '../types'
import { audioGameConfig } from '../config/audioGameConfig'
import { triggerEntityConfig } from '../config'

/**
 * Trieda pre spravu medzicasov
 */
export class SplitTimeManager {

  /** Indexy branok, kde budu medzicasy */
  private splitInfo: number[] = []

  /** Aktualny index medzicasu */
  private actualSplitIndex = 0

  /** Pocet medzicasov */
  private splitCount = 0

  /** aktualne najlepsi medzicas */
  private actualBestTime = 0

  /** data aktualneho medzicasu */
  private actualSplitTimeData: PlayerInfo[] = []

  /** ci sa maju v dalsom frame prepocitat data */
  public updateAlloved = false

  /** tween ktorym prepiname zobrazenie casu */
  private showPlayerTimeTween?: gsap.core.Tween

  /**
   * update
   */
  public update(): void {

    if (!this.updateAlloved) return

    this.updateSplitTimeData(timeManager.getGameTimeWithPenaltyInSeconds(undefined, undefined, 1))

  }

  /**
   * pocet medzicasov
   * @param value - splitCount
   * @returns this
   */
  public setSplitCount(): this {

    this.splitCount = triggerEntityConfig.preSplitTriggers.length
    return this

  }

  /**
   * zoberie data z hracov a aktivuje aktualny split
   */
  public setActualSplitTimeData(): void {

    const isFinish = this.actualSplitIndex >= this.splitCount

    this.actualSplitTimeData = playersManager.getSplitTimesOfPlayers(
      this.actualSplitIndex,
      isFinish
    )

    this.startActualSplit()
    this.playPreSplitComment()

    if (isFinish) audioManager.play(AudioNames.audienceHype)

  }

  /**
   * zahrame audio pred split timeom
   */
  private playPreSplitComment(): void {

    if (
      audioGameConfig.splitTimesNoComment.includes(this.actualSplitIndex) ||
            this.actualSplitIndex === this.splitCount - 1
    ) return

    audioManager.stopAudioByGroup(AudioGroups.commentators)
    audioManager.play(AudioNames.commentBeforeSplitTime)

  }

  /**
   * update vsetkych potrebnych dat pre ui
   * @param actualPlayerTime - Aktualny hracov cas
   * @param showPlayerPosition - Ukazanie hracovej pozicie
   * @param compareToPlayersTime - Porovnat s hracovym casom
   */
  public updateSplitTimeData(actualPlayerTime: number, showPlayerPosition = false, compareToPlayersTime = false): void {

    const result: SplitTimeData[] = []

    this.actualBestTime = compareToPlayersTime ?
      this.splitInfo[this.actualSplitIndex - 1] :
      this.actualSplitTimeData[0]?.finalResult ?? minigameConfig.dnfValue

    const playerPosition = this.getPlayerPosition(actualPlayerTime)
    const isPlayerFirst = this.actualBestTime >= actualPlayerTime

    const filtered = this.getFilteredData()

    // naplnime viditelne pozicie:
    const positionsToAdd: number[] = []
    if (playerPosition < 3) {

      // top pozicie

      positionsToAdd.push(...[0, 1, 2])

    } else if (playerPosition > (filtered.length - 2)) {

      // posledne pozicie

      positionsToAdd.push(...[0, playerPosition - 2, playerPosition - 1, playerPosition])

    } else {

      // ostatne pozicie

      positionsToAdd.push(...[0, playerPosition - 1, playerPosition, playerPosition + 1])

    }

    result.push(...this.getFormattedData(
      filtered,
      positionsToAdd,
      playerPosition,
      actualPlayerTime,
      showPlayerPosition
    ))

    // ked nemame ziadne data okrem momentalneho hraca
    if (result.length === 1) {

      result[0].time = timeManager.getGameTimeWithPenaltyInFormat(1)
      result[0].color = 'yellow'
      result[0].timeDiff = timeManager.getGameTimeWithPenaltyInFormat(1)

    }

    store.commit('SplitTimeState/SET_RERENDER_KEY', isPlayerFirst)
    store.commit('SplitTimeState/SET_SPLIT_TIME_DATA', result)

  }

  /**
   * Najdeme hracovu poziciu
   * @param actualPlayerTime - aktualny cas hraca
   * @returns - aktualna pozicia hraca
   */
  public getPlayerPosition(actualPlayerTime: number): number {

    let playerPosition = 0

    for (const [index, data] of this.actualSplitTimeData.entries()) {

      if ((data.finalResult ?? minigameConfig.dnfValue) > actualPlayerTime) {

        playerPosition = index
        break

      }

    }

    return playerPosition

  }

  /**
   * Prefiltrujeme data
   * @returns prefiltrovane pozicie
   */
  private getFilteredData(): PlayerInfo[] {

    // vyhodime undefined hodnoty
    const arrToFilter = [minigameConfig.dnfValue, minigameConfig.dnsValueAscending]
    const filtered = this.actualSplitTimeData
      .filter((playerInfo) => {

        return !arrToFilter.includes(playerInfo.finalResult ?? minigameConfig.dnfValue)

      })

    // aby sme brali vysledky z inych kvol osobitne
    filtered.forEach((playerInfo) => {

      playerInfo.playable = false

    })

    return filtered

  }

  /**
   * Ziskame pole formatovanych dat
   * @param players - pole dat hracov
   * @param positions - pole pozicii ktore chceme pridat
   * @param playerPos - pozicia hraca
   * @param actualPlayerTime - cas hraca
   * @param showPlayerPosition - ci ukazujeme poziciu hraca
   * @returns pole formatovanych dat pre split cas komponent
   */
  private getFormattedData(
    players: PlayerInfo[],
    positions: number[],
    playerPos: number,
    actualPlayerTime: number,
    showPlayerPosition: boolean
  ): SplitTimeData[] {

    const retVal: SplitTimeData[] = []

    let playerAdded = false

    positions.forEach((position) => {

      if (position === playerPos) {

        retVal.push(this.formatSplitTimeData(
          playersManager.getPlayer(),
          showPlayerPosition ? String(position + 1) : '',
          actualPlayerTime
        ))
        playerAdded = true

      }
      if (!players[position] || retVal.length === 4) return

      const positionCorrect = showPlayerPosition ? 1 : playerAdded ? 0 : 1
      retVal.push(this.formatSplitTimeData(
        players[position],
        String(position + positionCorrect + Number(playerAdded)),
        actualPlayerTime
      ))

    })

    if (!playerAdded) {

      retVal.push(this.formatSplitTimeData(
        playersManager.getPlayer(),
        showPlayerPosition ? String(playerPos + 1) : '',
        actualPlayerTime
      ))

    }

    return retVal

  }

  /**
   * formatovanie dat
   * @param data - Data o hracovi
   * @param position - Pozicia hraca
   * @param actualPlayerTime - Aktualny hracov cas
   */
  private formatSplitTimeData(
    data: PlayerInfo,
    position: string,
    actualPlayerTime: number
  ): SplitTimeData {

    let color = 'green'
    let actualTime = data.finalResult ?? minigameConfig.dnfValue

    if (data.playable) actualTime = actualPlayerTime

    const difference = actualTime - (this.actualBestTime ?? actualTime)
    const differencePrefix = this.getDifferencePrefix(difference)
    const differenceText = this.formatDifferenceTime(difference, differencePrefix)

    if (data.playable && difference > 0) color = 'red'

    const time = timeManager.getTimeInFormatFromSeconds(actualTime, 1)

    return {
      position,
      country: data.country,
      countryString: data.countryString ?? '',
      player: {
        name: data.name,
        isPlayer: data.playable ?? false
      },
      color,
      time: time,
      timeDiff: differenceText
    }

  }

  /**
   * Skontrolovanie aktualneho medzicasu a jeho zapisanie, ak presiel brankou daneho medzicasu
   */
  public startActualSplit(): void {

    this.updateAlloved = true
    store.commit('SplitTimeState/SET_SHOW_PLAYER_TIME', false)

  }

  /**
   * ukoncenie aktualneho split spravania
   */
  public endActualSplit(): void {

    if (modes.isTutorial()) return

    const actualPlayerTime = timeManager.getGameTimeWithPenaltyInSeconds(true, 10, 1)
    console.log(
      'Split time ',
      timeManager.getGameTimeWithPenaltyInSeconds(undefined, undefined, 1),
      `, with random: ${actualPlayerTime}`
    )

    this.updateAlloved = false
    const playerPosition = this.getPlayerPosition(actualPlayerTime) + 1
    this.addSplitInfo(actualPlayerTime)
    this.updateSplitTimeData(actualPlayerTime, true)

    this.playSplitCommentator(playerPosition)
    this.actualSplitIndex += 1

    if (this.showPlayerTimeTween) this.showPlayerTimeTween.kill()

    if (playerPosition !== 1) return

    this.showPlayerTimeTween = gsap.to({}, {
      duration: 3,
      onComplete: () => {

        this.updateSplitTimeData(actualPlayerTime, true, true)

        store.commit('SplitTimeState/SET_SHOW_PLAYER_TIME', true)

      }
    })

  }

  /**
   * zahrame komentatora pri splite
   * @param playerPosition - Pozicia hraca
   */
  private playSplitCommentator(playerPosition: number): void {

    console.log(`index? ${this.actualSplitIndex}`)

    if (this.actualSplitIndex === 3) {

      audioManager.stopAudioByGroup(AudioGroups.commentators)
      audioManager.play(AudioNames.commentBeforeFinish)
      return

    }

    // vynechavame niektore napr. pred strelbou, na konci a v tutoriali a treningu
    if (
      audioGameConfig.splitTimesNoComment.includes(this.actualSplitIndex)
    ) return

    let audio = AudioNames.commentSplitTimes4

    if (playerPosition === 1) {

      audio = AudioNames.commentSplitTimesLead

    } else if (playerPosition <= 3) {

      audio = AudioNames.commentSplitTimes23

    }

    audioManager.stopAudioByGroup(AudioGroups.commentators)
    audioManager.play(audio)

  }

  /**
   * pridaanie medzicasu pre logy
   * @param actualPlayerTime - Aktualny hracov cas
   */
  public addSplitInfo(actualPlayerTime: number): void {

    this.splitInfo[this.actualSplitIndex] = actualPlayerTime

  }

  /**
   * Zistenie prefixu pre diff
   * @param difference - Diff
   * @returns Prefix
   */
  private getDifferencePrefix(difference: number): string {

    let differencePrefix = ''
    if (difference > 0) differencePrefix = '+'
    if (difference < 0) differencePrefix = '-'
    return differencePrefix

  }

  /**
   * Naformatovanie diffu casu
   * @param difference - diff
   * @param differencePrefix - prefix pre diff
   * @returns Naformatovany diff time
   */
  private formatDifferenceTime(difference: number, differencePrefix: string): string {

    const timeInFormat = timeManager.getTimeInFormatFromSeconds(Math.abs(difference), 1)
    return `${differencePrefix}${timeInFormat}`

  }

  /**
   * Vratenie vsetkych checkpointov
   * @returns Pole checkpointov
   */
  public getAllSplitTimes(): number[] {

    return this.splitInfo

  }

}
