import {
  type TutorialObjective,
  TutorialMessageColors
} from '@powerplay/core-minigames'
import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface TutorialState {
  anne: {
    showAnne: boolean,
    isRight: boolean
  },
  mobile: boolean,
  tutorialMessage: {
    message: string,
    showMessage: boolean,
    color: TutorialMessageColors,
    yellowText: string,
    yellowTextSpecial: boolean,
    offset: boolean
  },
  objectives: TutorialObjective[],
  settings: boolean,
  buttons: {
    showDownhill: boolean,
    showDownhillReal: boolean,
    showSprint: boolean,
    showSprintReal: boolean,
    showMovementButtons: boolean,
    showJoystick: boolean
  },
  showButtonStart: boolean,
  showUnderMenu: boolean,
  showBarStart: boolean,
  showBarSpeed: boolean,
  showArrowAim: boolean
}

const initialState = () => ({
  anne: {
    showAnne: false,
    isRight: false
  },
  mobile: false,
  tutorialMessage: {
    message: '',
    showMessage: false,
    color: TutorialMessageColors.blank,
    yellowText: '',
    yellowTextSpecial: false,
    offset: false
  },
  settings: false,
  objectives: [],
  buttons: {
    showDownhill: false,
    showDownhillReal: false,
    showSprint: false,
    showSprintReal: false,
    showMovementButtons: false,
    showJoystick: false
  },
  showButtonStart: false,
  showUnderMenu: false,
  showBarStart: false,
  showBarSpeed: false,
  showArrowAim: false
})

const tutorialState: Module<TutorialState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getTutorialState: (moduleState: TutorialState) => moduleState,
    getTutorialObjectives: (moduleState: TutorialState) => moduleState.objectives,
    getTutorialButtons: (moduleState: TutorialState) => moduleState.buttons,
    getSettings: (moduleState: TutorialState) => moduleState.settings
  },

  mutations: {
    RESET: (moduleState: TutorialState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: TutorialState, newState: TutorialState) => {

      moduleState = newState

    },
    SET_ANNE: (
      moduleState: TutorialState,
      newValue: {
        showAnne: false,
        isRight: false
      }
    ) => {

      moduleState.anne = newValue

    },
    SET_MOBILE: (
      moduleState: TutorialState,
      newValue: boolean
    ) => {

      moduleState.mobile = newValue

    },
    SET_ANNE_IS_RIGHT: (moduleState: TutorialState, newValue: boolean) => {

      moduleState.anne.isRight = newValue

    },
    SET_SETTINGS: (moduleState: TutorialState, bool: boolean) => {

      moduleState.settings = bool

    },
    SET_MESSAGE: (
      moduleState: TutorialState,
      newState: {
        message: string,
        showMessage: boolean,
        color: TutorialMessageColors.blank,
        yellowText: string,
        yellowTextSpecial: boolean,
        offset: boolean,
      }
    ) => {

      moduleState.tutorialMessage = newState

    },
    SET_OBJECTIVES: (moduleState: TutorialState, objectives: TutorialObjective[]) => {

      moduleState.objectives = [...objectives]

    },
    SET_BUTTONS: (
      moduleState: TutorialState,
      buttons: {
        showDownhill: boolean,
        showDownhillReal: boolean,
        showSprint: boolean,
        showSprintReal: boolean,
        showMovementButtons: boolean,
        showJoystick: boolean
      }
    ) => {

      moduleState.buttons = buttons

    },
    SET_SHOW_BUTTON_START: (moduleState: TutorialState, showButtonStart: boolean) => {

      moduleState.showButtonStart = showButtonStart

    },
    SET_SHOW_UNDER_MENU: (moduleState: TutorialState, showUnderMenu: boolean) => {

      moduleState.showUnderMenu = showUnderMenu

    },
    SET_SHOW_BAR_START: (moduleState: TutorialState, showBarStart: boolean) => {

      moduleState.showBarStart = showBarStart

    },
    SET_SHOW_BAR_SPEED: (moduleState: TutorialState, showBarSpeed: boolean) => {

      moduleState.showBarSpeed = showBarSpeed

    },
    SET_SHOW_ARROW_AIM: (moduleState: TutorialState, showArrowAim: boolean) => {

      moduleState.showArrowAim = showArrowAim

    }

  }
}

export default tutorialState
