import { shootingPhaseConfig } from '@/app/config/shootingPhaseConfig'
import { ShootingTargetsTypes } from '@/app/types'
import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface BulletsTargetsBoxState {
  visible: boolean
  bullets: number
  targets: Array<boolean>
  showBlackScreen: boolean,
  targetsType: ShootingTargetsTypes
}

const initialState = () => ({
  visible: false,
  bullets: 0,
  targets: [false, false, false, false, false],
  showBlackScreen: false,
  targetsType: ShootingTargetsTypes.standing
})

const bulletsTargetsBoxState: Module<BulletsTargetsBoxState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getDisplayBulletsTargets: (moduleState: BulletsTargetsBoxState) => moduleState.visible,
    getBullets: (moduleState: BulletsTargetsBoxState) => moduleState.bullets,
    getTargets: (moduleState: BulletsTargetsBoxState) => moduleState.targets,
    getShowBlack: (moduleState: BulletsTargetsBoxState) => moduleState.showBlackScreen,
    getTargetsType: (moduleState: BulletsTargetsBoxState) => moduleState.targetsType
  },

  mutations: {
    RESET: (moduleState: BulletsTargetsBoxState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: BulletsTargetsBoxState, newState: BulletsTargetsBoxState) => {

      if (newState.visible !== undefined) moduleState.visible = newState.visible
      if (newState.bullets !== undefined) moduleState.bullets = newState.bullets
      if (newState.targets !== undefined) moduleState.targets = newState.targets
      if (newState.showBlackScreen !== undefined) {

        moduleState.showBlackScreen = newState.showBlackScreen

      }

    },
    SET_BULLETS: (moduleState: BulletsTargetsBoxState, bullets: number) => {

      moduleState.bullets = bullets

    },
    SET_TARGET_HIT: (moduleState: BulletsTargetsBoxState, hitTarget: number) => {

      moduleState.targets[hitTarget] = true

    },
    SET_IS_VISIBLE: (moduleState: BulletsTargetsBoxState, visible: boolean) => {

      moduleState.visible = visible

    },
    RESET_FOR_TRAINING: (moduleState: BulletsTargetsBoxState) => {

      moduleState.bullets = shootingPhaseConfig.attemptCount
      moduleState.targets = new Array(shootingPhaseConfig.attemptCount).fill(false)

    },
    SET_BLACK_SCREEN: (moduleState: BulletsTargetsBoxState, show: boolean) => {

      moduleState.showBlackScreen = show

    },
    SET_TARGETS_TYPE: (moduleState: BulletsTargetsBoxState, targetsType: ShootingTargetsTypes) => {

      moduleState.targetsType = targetsType

    }
  }
}

export default bulletsTargetsBoxState
